export enum ExecutionValueType {
  READY,
  INTERRUPTED,
  ACTIVE,
  STOPPED,
  FEED_HOLD,
  PROGRAM_COMPLETED,
  PROGRAM_STOPPED,
  PROGRAM_OPTIONAL_STOP,
  UNAVAILABLE,
}

export enum ControllerModeValueType {
  AUTOMATIC,
  MANUAL,
  MANUAL_DATA_INPUT,
  SEMI_AUTOMATIC,
  EDIT,
  UNAVAILABLE,
}
