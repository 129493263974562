import {
  del, get, postFormData, postWithoutResponse, putFormData
} from '../../utils/api';
import { CreateAssetModel } from './form-models/CreateAssetModel';
import { Asset, MachineStatisticsModel, ProductionMonitoringConfiguration } from './types';
import { IEditAssetFormProps } from './views/components';

const endpoint = '/api/assets';

export function loadAll(): Promise<Asset[]> {
  return get<Asset[]>(endpoint);
}

export async function create(asset: CreateAssetModel, image: Blob | undefined): Promise<Asset> {
  const formData = new FormData();
  if (image) {
    window.URL.createObjectURL(image);
    formData.append('picture', image);
  }

  Object.keys(asset).forEach((key) => {
    const value = (asset as any)[key];
    if (Array.isArray(value)) {
      value.forEach((p, index) => formData.append(`${key}[${index}]`, p));
    } else {
      formData.append(key, (asset as any)[key]);
    }
  });

  return postFormData<Asset>(endpoint, formData);
}

export async function update(
  assetId: number,
  assetProps: IEditAssetFormProps,
  image: Blob | undefined | null
): Promise<Asset> {
  const formData = new FormData();
  if (image) {
    formData.append('picture', image);
  }

  Object.keys(assetProps).forEach((key) => {
    const value = (assetProps as any)[key];
    if (Array.isArray(value)) {
      value.forEach((p, index) => formData.append(`${key}[${index}]`, p));
    } else {
      formData.append(key, (assetProps as any)[key]);
    }
  });

  const updatedAsset = await putFormData<Asset>(`${endpoint}/${assetId}`, formData);
  return updatedAsset;
}

export async function remove(assetId: number): Promise<void> {
  return del(`${endpoint}/${assetId.toString()}`);
}

export async function updateAssetImage(assetId: number, image: File): Promise<void> {
  const formData = new FormData();
  formData.append('picture', image);
  await postFormData(`${endpoint}/${assetId}/picture`, formData);
}

export async function loadProductionMonitoringConfiguration(
  assetId: number
): Promise<ProductionMonitoringConfiguration> {
  return get<ProductionMonitoringConfiguration>(
    `${endpoint}/${assetId}/production-monitoring-configuration`
  );
}

export async function updateProductionMonitoringConfiguration(
  assetId: number,
  configuration: ProductionMonitoringConfiguration
): Promise<void> {
  await postWithoutResponse(
    `${endpoint}/${assetId}/production-monitoring-configuration`,
    configuration
  );
}

export async function getMachineUtilizationStatistics(
  assetId: number
): Promise<MachineStatisticsModel> {
  return get<MachineStatisticsModel>(`api/MachineReporting/statistics/${assetId}`);
}
