import * as React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { updateGatewayDisplayName } from '../../actions';
import { Gateway } from '../..';
import { commonMessages } from '../../../../constants';

export interface IGatewayTableRowProps {
  gateway: Gateway;
}

const m = defineMessages({
  deviceDisplayNameInputPlaceholder: {
    id: 'ListAssetsPage.deviceDisplayNameInputPlaceholder',
    defaultMessage: 'e.g., where is this gateway installed?'
  },
  gatewayStatusConnected: {
    id: 'ListAssetsPage.gatewayStatusConnected',
    defaultMessage: 'Connected'
  },
  gatewayStatusDisconnected: {
    id: 'ListAssetsPage.gatewayStatusDisconnected',
    defaultMessage: 'Disconnected'
  },
  confirmNameChangeTitle: {
    id: 'ListAssetsPage.confirmNameChangeTitle',
    defaultMessage: "Change display name from ''{old}'' to ''{new}'' ?"
  }
});

export function GatewayTableRow(props: IGatewayTableRowProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [displayName, setDisplayName] = useState(props.gateway.displayName);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const onCancel = () => {
    setDisplayName(props.gateway.displayName);
    setIsConfirmDialogOpen(false);
  };

  const onConfirm = () => {
    if (props.gateway.displayName !== displayName) {
      dispatch(updateGatewayDisplayName(props.gateway.id, displayName));
    }

    setIsConfirmDialogOpen(false);
  };

  return (
    <TableRow key={props.gateway.id}>
      <TableCell>{props.gateway.deviceId}</TableCell>
      <TableCell>
        <div style={{ width: '320px' }}>
          <Dialog
            open={isConfirmDialogOpen}
            onClose={() => setIsConfirmDialogOpen(false)}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" />
            <DialogContent>
              <DialogContentText>
                {formatMessage(m.confirmNameChangeTitle, {
                  old: props.gateway.displayName,
                  new: displayName
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={onCancel}>
                {formatMessage(commonMessages.no)}
              </Button>
              <Button onClick={onConfirm}>{formatMessage(commonMessages.yes)}</Button>
            </DialogActions>
          </Dialog>
          <Input
            defaultValue={displayName ?? ''}
            placeholder={formatMessage(m.deviceDisplayNameInputPlaceholder)}
            onBlur={(data) => {
              setDisplayName(data.target.value);
              if (
                props.gateway.displayName == null
                || _.trim(props.gateway.displayName) === _.trim(data.target.value)
              ) {
                dispatch(updateGatewayDisplayName(props.gateway.id, data.target.value));
              } else if (data.target.value !== props.gateway.displayName) {
                setIsConfirmDialogOpen(true);
              }
            }}
          />
        </div>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          color={props.gateway.isConnected ? 'success.light' : 'error.light'}
        >
          {props.gateway.isConnected
            ? formatMessage(m.gatewayStatusConnected)
            : formatMessage(m.gatewayStatusDisconnected)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
