import * as React from 'react';
import style from './BigHeader.module.css';

export interface BigHeaderCellProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode;
}

const BigHeaderCell: React.FunctionComponent<BigHeaderCellProps> = (props: BigHeaderCellProps) => {
  const { children, ...divProps } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={style.bigHeaderCell} {...divProps}>
      {children}
    </div>
  );
};

export default BigHeaderCell;
