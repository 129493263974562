import {
  ProductionMonitoringActionTypes,
  LOAD_MACHINE_OVERVIEWS_SUCCESS,
  CONFIGURE_MACHINE_STATE_BEACON,
  CONFIGURE_MACHINE_STATE_BEACON_SUCCESS,
  CONFIGURE_MACHINE_STATE_BEACON_FAILURE,
  UNINSTALL_MACHINE_STATE_BEACON,
  UNINSTALL_MACHINE_STATE_BEACON_SUCCESS,
  UNINSTALL_MACHINE_STATE_BEACON_FAILURE,
  MachineOverview,
  LOAD_MACHINE_OVERVIEW_SUCCESS,
  LOAD_MACHINE_OVERVIEWS,
  LOAD_MACHINE_OVERVIEWS_FAILURE,
  OVERVIEW_PAGE_TOGGLE_UTILIZATION_CHART_STATUS,
  AssetProductionMonitoringStatus
} from './types';

export function loadAll(): ProductionMonitoringActionTypes {
  return {
    type: LOAD_MACHINE_OVERVIEWS
  };
}

export function loadAllSuccess(assets: MachineOverview[]): ProductionMonitoringActionTypes {
  return {
    type: LOAD_MACHINE_OVERVIEWS_SUCCESS,
    payload: assets
  };
}

export function loadAllFailure(error: unknown): ProductionMonitoringActionTypes {
  return {
    type: LOAD_MACHINE_OVERVIEWS_FAILURE,
    payload: { error }
  };
}

export function loadMachineOverviewSuccess(
  machineState: MachineOverview
): ProductionMonitoringActionTypes {
  return {
    type: LOAD_MACHINE_OVERVIEW_SUCCESS,
    payload: machineState
  };
}

export function configureMachineStateBeacon(payload: {
  assetId: number;
  beaconId: number;
}): ProductionMonitoringActionTypes {
  return {
    type: CONFIGURE_MACHINE_STATE_BEACON,
    payload
  };
}

export function configureMachineStateBeaconSuccess(payload: {
  assetId: number;
  beaconId: number;
}): ProductionMonitoringActionTypes {
  return {
    type: CONFIGURE_MACHINE_STATE_BEACON_SUCCESS,
    payload
  };
}

export function configureMachineStateBeaconFailure(error: Error): ProductionMonitoringActionTypes {
  return {
    type: CONFIGURE_MACHINE_STATE_BEACON_FAILURE,
    payload: { error }
  };
}

export function uninstallMachineStateBeacon(payload: {
  assetId: number;
  beaconId: number;
}): ProductionMonitoringActionTypes {
  return {
    type: UNINSTALL_MACHINE_STATE_BEACON,
    payload
  };
}

export function uninstallMachineStateBeaconSuccess(payload: {
  assetId: number;
}): ProductionMonitoringActionTypes {
  return {
    type: UNINSTALL_MACHINE_STATE_BEACON_SUCCESS,
    payload
  };
}

export function uninstallMachineStateBeaconFailure(error: Error): ProductionMonitoringActionTypes {
  return {
    type: UNINSTALL_MACHINE_STATE_BEACON_FAILURE,
    payload: { error }
  };
}

export function overviewPageToggleUtilizationChartStatus(
  status: AssetProductionMonitoringStatus
): ProductionMonitoringActionTypes {
  return {
    type: OVERVIEW_PAGE_TOGGLE_UTILIZATION_CHART_STATUS,
    payload: { status }
  };
}
