import * as React from 'react';
import { ReactElement } from 'react';
import style from './BigHeader.module.css';
import { BigHeaderCellProps } from './BigHeaderCell';

interface BigHeaderProps {
  fixed: boolean;
  children: ReactElement<BigHeaderCellProps> | Array<ReactElement<BigHeaderCellProps>>;
}

const BigHeader = (props: BigHeaderProps) => (
  <div className={`${style.bigHeader} ${props.fixed ? style.fixed : ''}`}>{props.children}</div>);

export default BigHeader;
