import { Reducer } from 'redux';
import {
  CHANGE_USER_ROLE_SUCCESS,
  CREATE_USER_SUCCESS,
  DEACTIVATE_USER_SUCCESS,
  LOAD_ROLES_SUCCESS,
  LOAD_USERS_SUCCESS,
  REACTIVATE_USER_SUCCESS,
  UsersManagementActionTypes,
  UsersManagementState
} from './types';

const initialState: UsersManagementState = {
  usersByEmail: {},
  roles: []
};

const usersManagementReducer: Reducer<UsersManagementState, UsersManagementActionTypes> = (
  state = initialState,
  action
): UsersManagementState => {
  switch (action.type) {
    case LOAD_USERS_SUCCESS: {
      const usersByEmail = Object.assign(
        {},
        ...action.payload.users.map((x) => ({ [x.email]: x }))
      );
      return {
        ...state,
        usersByEmail
      };
    }
    case LOAD_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload.roles
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        usersByEmail: {
          ...state.usersByEmail,
          [action.payload.user.email]: action.payload.user
        }
      };
    }
    case CHANGE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        usersByEmail: {
          ...state.usersByEmail,
          [action.payload.email]: {
            ...state.usersByEmail[action.payload.email],
            role: action.payload.role
          }
        }
      };
    }
    case DEACTIVATE_USER_SUCCESS: {
      return {
        ...state,
        usersByEmail: {
          ...state.usersByEmail,
          [action.payload.email]: {
            ...state.usersByEmail[action.payload.email],
            isDeactivated: true
          }
        }
      };
    }
    case REACTIVATE_USER_SUCCESS: {
      return {
        ...state,
        usersByEmail: {
          ...state.usersByEmail,
          [action.payload.email]: {
            ...state.usersByEmail[action.payload.email],
            isDeactivated: false
          }
        }
      };
    }
    default:
      return state;
  }
};

export { usersManagementReducer };
