import { MessageDescriptor } from 'react-intl';

export class ModelState {
  public errors: { [field: string]: MessageDescriptor[] } = {};

  public isValid = true;

  public static empty(): ModelState {
    return new ModelState();
  }

  public constructor(init?: Partial<ModelState>) {
    Object.assign(this, init);
  }

  public static async FromResponse(response: Response): Promise<ModelState> {
    const modelState = new ModelState();
    try {
      let json = await response.json();
      if (json.errors) {
        json = json.errors;
      }

      Object.keys(json)
        .filter((key) => Array.isArray(json[key]))
        .forEach((key) => json[key].forEach((error: string) => {
          modelState.addError(key, { defaultMessage: error });
        }));

      console.error(JSON.stringify(modelState));
    } catch {
      modelState.addError('', { defaultMessage: 'Unexpected server error' });
    }

    return modelState;
  }

  public addError(fieldId: string, error: MessageDescriptor): void {
    if (!this.errors[fieldId]) {
      this.errors[fieldId] = [error];
    } else {
      this.errors[fieldId].push(error);
    }

    this.isValid = false;
  }

  public clearFieldErrors(fieldId: string): void {
    delete this.errors[fieldId];
  }

  public isFieldInvalid(field: string): boolean {
    return Object.keys(this.errors).find((x) => x === field) != null;
  }

  public isEmpty(): boolean {
    return this.isValid === true && Object.keys(this.errors).length === 0;
  }

  public localizeErrorMessages(
    localizer: (messageDescriptor: MessageDescriptor) => string
  ): string[] {
    return Object.values(this.errors)
      .reduce((a, b) => a.concat(b))
      .map((x) => (x.id ? localizer(x) : x.defaultMessage?.toString() ?? ''));
  }

  public getAllErrorMessages(
    localizer: (messageDescriptor: MessageDescriptor) => string
  ): string[] {
    return Object.keys(this.errors).flatMap((fieldKey) => this.errors[fieldKey].map((error) => (error.id ? localizer(error) : error.defaultMessage?.toString() ?? 'An error occurred.')));
  }

  public getNonFieldSpecificErrorMessages(
    localizer: (messageDescriptor: MessageDescriptor) => string
  ): string[] {
    return this.errors['']
      ? this.errors[''].map((error) => (error.id ? localizer(error) : error.defaultMessage?.toString() ?? 'An error occurred.'))
      : [];
  }
}
