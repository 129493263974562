import {
  all, call, fork, put, takeLatest, takeEvery, select
} from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import {
  LOAD_DEVICES,
  INSTALL_GATEWAY,
  InstallGatewayAction,
  UNINSTALL_GATEWAY,
  UninstallGatewayAction,
  Gateway,
  UPDATE_GATEWAY_DISPLAY_NAME,
  UpdateGatewayDisplayNameAction
} from './types';
import { ApplicationState } from '../../store';
import { Awaited } from '../../utils';

function* handleLoadAll() {
  try {
    const result: Awaited<ReturnType<typeof api.loadAll>> = yield call(api.loadAll);
    yield put(actions.loadAllSuccess(result));
  } catch (e) {
    yield put(actions.loadAllFailure(e));
  }
}

function* handleInstallGateway(action: InstallGatewayAction) {
  try {
    const gateway = (yield select(
      (state: ApplicationState) => state.devices.gatewaysById[action.payload.gatewayId]
    )) as Gateway;
    if (gateway && gateway.fixedInstallationCoordinate) {
      yield call(api.uninstallGateway, action.payload.gatewayId);
    }

    const result: Awaited<ReturnType<typeof api.installGateway>> = yield call(
      api.installGateway,
      action.payload
    );
    yield put(actions.installGatewaySuccess(result));
  } catch (e) {
    yield put(actions.installGatewayFailure(e));
  }
}

function* handleUninstallGateway(action: UninstallGatewayAction) {
  try {
    const result: Awaited<ReturnType<typeof api.uninstallGateway>> = yield call(
      api.uninstallGateway,
      action.payload.deviceId
    );
    yield put(actions.uninstallGatewaySuccess(result));
  } catch (e) {
    yield put(actions.uninstallGatewayFailure(e));
  }
}

function* handleUpdateGatewayDisplayName(action: UpdateGatewayDisplayNameAction) {
  try {
    yield call(api.updateGatewayDisplayName, action.payload.gatewayId, action.payload.displayName);
    yield put(
      actions.updateGatewayDisplayNameSuccess(action.payload.gatewayId, action.payload.displayName)
    );
  } catch (e) {
    yield put(actions.updateGatewayDisplayNameFailure(action.payload.gatewayId, e));
  }
}

function* watchInstallGateway() {
  yield takeEvery(INSTALL_GATEWAY, handleInstallGateway);
}
function* watchUninstallGateway() {
  yield takeEvery(UNINSTALL_GATEWAY, handleUninstallGateway);
}
function* watchUpdateGatewayDisplayName() {
  yield takeLatest(UPDATE_GATEWAY_DISPLAY_NAME, handleUpdateGatewayDisplayName);
}
function* watchLoadAll() {
  yield takeLatest(LOAD_DEVICES, handleLoadAll);
}

function* devicesSagas() {
  yield all([
    fork(watchLoadAll),
    fork(watchInstallGateway),
    fork(watchUninstallGateway),
    fork(watchUpdateGatewayDisplayName)
  ]);
}

export { devicesSagas };
