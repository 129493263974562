import { defineMessages } from 'react-intl';

import {
  nameof, StringHelper, ModelState, TimeZones
} from '../../../utils';

export const ASSET_NAME_MAXIMUM_LENGTH = 50;

const m = defineMessages({
  nameRequired: { id: 'CreateAssetModel.nameRequired', defaultMessage: 'The name is required.' },
  nameMaximumLength: {
    id: 'CreateAssetModel.nameMaximumLength',
    defaultMessage: `Name must not exceed ${ASSET_NAME_MAXIMUM_LENGTH} characters.`
  },
  timeZoneRequired: {
    id: 'CreateAssetModel.timeZoneRequired',
    defaultMessage: 'The timezone is required.'
  },
  minimumProductionStopDurationInMinutesRange: {
    id: 'CreateAssetModel.minimumProductionStopDurationInMinutesRange',
    defaultMessage: 'The duration must be between 5 and 60.'
  }
});

export class CreateAssetModel {
  public name = '';

  public tags: string[] = [];

  public timeZoneId: string | undefined;

  public deletePicture = false;

  public minimumProductionStopDurationInMinutes = 10;

  constructor(init?: Partial<CreateAssetModel>) {
    Object.assign(this, init);
  }

  public validate(): ModelState {
    const modelState = new ModelState();

    this.validateNameInternal(modelState);
    this.valideTimeZoneInternal(modelState);

    if (TimeZones.find((p) => p.value === this.timeZoneId) === undefined) {
      modelState.isValid = false;
      modelState.addError(nameof<CreateAssetModel>('timeZoneId'), m.timeZoneRequired);
    }

    if (
      this.minimumProductionStopDurationInMinutes < 5
      || this.minimumProductionStopDurationInMinutes > 60
    ) {
      modelState.isValid = false;
      modelState.addError(
        nameof<CreateAssetModel>('minimumProductionStopDurationInMinutes'),
        m.minimumProductionStopDurationInMinutesRange
      );
    }

    return modelState;
  }

  private validateNameInternal(modelState: ModelState) {
    if (!StringHelper.hasValue(this.name)) {
      // eslint-disable-next-line no-param-reassign
      modelState.isValid = false;
      modelState.addError(nameof<CreateAssetModel>('name'), m.nameRequired);
    } else if (this.name.length > 50) {
      // eslint-disable-next-line no-param-reassign
      modelState.isValid = false;
      modelState.addError(nameof<CreateAssetModel>('name'), m.nameMaximumLength);
    }
  }

  private valideTimeZoneInternal(modelState: ModelState) {
    if (TimeZones.find((p) => p.value === this.timeZoneId) === undefined) {
      // eslint-disable-next-line no-param-reassign
      modelState.isValid = false;
      modelState.addError(nameof<CreateAssetModel>('timeZoneId'), m.timeZoneRequired);
    }
  }

  public validateName(): ModelState {
    const modelState = new ModelState();
    this.validateNameInternal(modelState);
    return modelState;
  }

  public validateTimeZone(): ModelState {
    const modelState = new ModelState();
    this.valideTimeZoneInternal(modelState);
    return modelState;
  }
}
