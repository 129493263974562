import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  cancel: { id: 'commonMessages.cancel', defaultMessage: 'Cancel' },
  confirmDeleteTitle: { id: 'commonMessages.confirmDeleteTitle', defaultMessage: 'Confirm Delete' },
  confirmDeleteDescription: {
    id: 'commonMessages.confirmDeleteTitle',
    defaultMessage: 'Are you sure you want to delete this item?'
  },
  create: { id: 'commonMessages.create', defaultMessage: 'Create' },
  update: { id: 'commonMessages.update', defaultMessage: 'Update' },
  edit: { id: 'commonMessages.edit', defaultMessage: 'Edit' },
  delete: { id: 'commonMessages.delete', defaultMessage: 'Delete' },
  back: { id: 'commonMessages.back', defaultMessage: 'Back' },
  save: { id: 'commonMessages.save', defaultMessage: 'Save' },
  done: { id: 'commonMessages.done', defaultMessage: 'Done' },
  next: { id: 'commonMessages.next', defaultMessage: 'Next' },
  yes: { id: 'commonMessages.yes', defaultMessage: 'Yes' },
  no: { id: 'commonMessages.no', defaultMessage: 'No' },
  dataFetchErrorFromServer: {
    id: 'commonMessages.dataFetchErrorFromServer',
    defaultMessage: 'Data could not be fetched from the server.'
  },
  formFieldRequired: {
    id: 'commonMessages.formFieldRequired',
    defaultMessage: 'This field is required.'
  },
  formFieldRangeValue: {
    id: 'commonMessages.formFieldRequired',
    defaultMessage: 'The value must be between {min} and {max}.'
  },
  timeUnitMinutes: { id: 'commonMessages.timeUnitMinutes', defaultMessage: 'minutes' }
});
