import { LoadingButton } from '@mui/lab';
import queryString from 'query-string';
import {
  Alert, Box, Button, Container, Stack, TextField, Typography
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  defineMessages, WrappedComponentProps, injectIntl, useIntl
} from 'react-intl';
import { useParams, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { AppLogo } from '../../../components/logos';
import { commonMessages } from '../../../constants/commonMessages';
import { ApplicationState } from '../../../store';
import { nameof, ModelState } from '../../../utils';
import useApiErrorHandler, { ErrorHandlingStrategy } from '../../../utils/ApiErrorHandler';
import * as Api from '../api';

interface ResetPasswordForm {
  userId: string;
  token: string;
  newPassword: string;
  confirmNewPassword: string;
}

const m = defineMessages({
  title: { id: 'ResetPassword.title', defaultMessage: 'Choose a new password' },
  passwordUpdated: {
    id: 'ResetPassword.passwordUpdated',
    defaultMessage: 'Password was successfully updated!'
  },

  updatePassword: { id: 'ResetPassword.updatePassword', defaultMessage: 'Update password' },
  passwordPlaceholder: { id: 'RegisterPage.passwordPlaceholder', defaultMessage: 'Enter password' },
  confirmPasswordPlaceholder: {
    id: 'RegisterPage.confirmPasswordPlaceholder',
    defaultMessage: 'Confirm password'
  },
  pageFooter: {
    id: 'ResetPasswordPage.pageFooter',
    defaultMessage:
      'ARIV provides simple solutions to organizations facing the Industry 4.0 transformation.'
  }
});

const ResetPasswordPage = () => {
  const [isPasswordRequestComplete, setIsPasswordRequestComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const errorHandler = useApiErrorHandler();
  const {
    handleSubmit, control, getValues, formState, reset
  } = useForm<ResetPasswordForm>({
    mode: 'onBlur'
  });

  const location = useLocation();

  const queryStringParams = queryString.parse(location.search);

  const { formatMessage } = useIntl();

  const resetPassword = async (form: ResetPasswordForm) => {
    try {
      setIsLoading(true);

      await Api.resetPassword(form.userId, form.token, form.newPassword);
      setIsPasswordRequestComplete(true);
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1
      }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Stack direction="row" spacing={20}>
              <AppLogo />

              <Box sx={{ padding: '16px' }}>
                {isPasswordRequestComplete && (
                  <>
                    <Typography variant="h4">{formatMessage(m.title)}</Typography>
                    <Typography variant="body1">{formatMessage(m.passwordUpdated)}</Typography>
                    <NavLink to="/login">Go to login</NavLink>
                  </>
                )}
                {!isPasswordRequestComplete && (
                  <>
                    <Typography variant="h4">{formatMessage(m.title)}</Typography>
                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Stack>
                        <Box visibility="hidden">
                          <Controller
                            name="userId"
                            control={control}
                            defaultValue={queryStringParams.userId?.toString() ?? undefined}
                            render={({ field }) => (
                              <TextField
                                autoComplete="off"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="token"
                            control={control}
                            defaultValue={queryStringParams.token?.toString() ?? undefined}
                            render={({ field, fieldState }) => (
                              <TextField
                                autoComplete="off"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                              />
                            )}
                          />
                        </Box>
                        <Controller
                          name="newPassword"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: formatMessage(commonMessages.formFieldRequired)
                            }
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              autoComplete="new-password"
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              type="password"
                              label={formatMessage(m.passwordPlaceholder)}
                              error={fieldState.error != null}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />

                        <Controller
                          name="confirmNewPassword"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: formatMessage(commonMessages.formFieldRequired)
                            },
                            validate: {
                              matchesPassword: () => getValues('newPassword') === getValues('confirmNewPassword')
                            }
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              autoComplete="new-password"
                              type="password"
                              label={formatMessage(m.confirmPasswordPlaceholder)}
                              error={fieldState.error != null}
                              helperText={
                                fieldState.error?.type === 'matchesPassword'
                                  ? 'Passwords must match'
                                  : fieldState.error?.message
                              }
                            />
                          )}
                        />
                      </Stack>

                      <LoadingButton
                        variant="contained"
                        type="submit"
                        name="submitButton"
                        loading={isLoading}
                        disabled={isLoading}
                        sx={{ marginBottom: '8px' }}
                        onClick={handleSubmit(resetPassword)}
                      >
                        {formatMessage(m.updatePassword)}
                      </LoadingButton>
                    </Box>
                  </>
                )}
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
      <div
        className="bg-primary"
        style={{
          minHeight: '250px',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: '16px',
          padding: '8px'
        }}
      >
        <h3 className="color-white" style={{ textAlign: 'center', margin: '0 auto' }}>
          {formatMessage(m.pageFooter)}
        </h3>
      </div>
    </Box>
  );
};

export { ResetPasswordPage };
