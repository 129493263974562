import * as React from 'react';
import { Header, Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useResponsiveQueries } from 'utils';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BarChartTrendUp } from '../../../../assets/images/BarChartTrendUp.svg';
import { MarketingTextWithIcon } from '.';

const m = defineMessages({
  title: {
    id: 'CreateFirstAssetCallToAction.title',
    defaultMessage:
      'With Journey production monitoring you see the performance of all of your machines at a glance!'
  },
  subTitle: {
    id: 'CreateFirstAssetCallToAction.subTitle',
    defaultMessage:
      'Equip your machines with our sensors and start capturing your production data. Make the right decisions faster based on reliable and accurate data to improve productivity.'
  },
  productionOverview: {
    id: 'CreateFirstAssetCallToAction.productionOverview',
    defaultMessage: 'Production overview'
  },
  machineSurveillance: {
    id: 'CreateFirstAssetCallToAction.machineSurveillance',
    defaultMessage: 'Machine surveillance'
  },
  performanceAnalysis: {
    id: 'CreateFirstAssetCallToAction.performanceAnalysis',
    defaultMessage: 'Performance analysis'
  },
  createFirstMachine: {
    id: 'CreateFirstAssetCallToAction.createFirstMachine',
    defaultMessage: 'Add my first machine now!'
  }
});

export function CreateFirstAssetCallToAction() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { isMobile, isDesktop, isTablet } = useResponsiveQueries();

  return (
    <div style={{ maxWidth: '900px', margin: 'auto' }}>
      <Header as="h1" textAlign="center">
        {formatMessage(m.title)}
      </Header>
      {!isMobile && (
        <Header as="h3" textAlign="center">
          {formatMessage(m.subTitle)}
        </Header>
      )}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '60px'
        }}
      >
        <div
          style={{
            flex: '1 0 50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {isDesktop && (
            <>
              <MarketingTextWithIcon
                size="huge"
                icon="setting"
                text={formatMessage(m.productionOverview)}
              />
              <MarketingTextWithIcon
                size="huge"
                icon="search"
                text={formatMessage(m.machineSurveillance)}
              />
              <MarketingTextWithIcon
                size="huge"
                icon="line graph"
                text={formatMessage(m.performanceAnalysis)}
              />
            </>
          )}
          {isTablet && (
            <>
              <MarketingTextWithIcon
                size="big"
                icon="setting"
                text={formatMessage(m.productionOverview)}
              />
              <MarketingTextWithIcon
                size="big"
                icon="search"
                text={formatMessage(m.machineSurveillance)}
              />
              <MarketingTextWithIcon
                size="big"
                icon="line graph"
                text={formatMessage(m.performanceAnalysis)}
              />
            </>
          )}
          {isMobile && (
            <>
              <MarketingTextWithIcon
                size="large"
                icon="setting"
                text={formatMessage(m.productionOverview)}
              />
              <MarketingTextWithIcon
                size="large"
                icon="search"
                text={formatMessage(m.machineSurveillance)}
              />
              <MarketingTextWithIcon
                size="large"
                icon="line graph"
                text={formatMessage(m.performanceAnalysis)}
              />
            </>
          )}
        </div>
        <div
          style={{
            flex: '1 0 50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {isDesktop && (
            <>
              <BarChartTrendUp width="420px" />
            </>
          )}
          {isTablet && (
            <>
              <BarChartTrendUp width="350px" />
            </>
          )}
          {isMobile && (
            <>
              <BarChartTrendUp width="250" height="auto" />
            </>
          )}
        </div>
      </div>
      <div style={{
        display: 'flex', width: '100%', justifyContent: 'center', marginTop: '30px'
      }}
      >
        <Button primary size="huge" onClick={() => navigate('/assets/create')}>
          {formatMessage(m.createFirstMachine)}
        </Button>
      </div>
    </div>
  );
}
