import React from 'react';
import {
  IconButton, ListItemIcon, ListItemText, Menu, MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useIntl, defineMessages } from 'react-intl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const m = defineMessages({
  edit: {
    id: 'ManchineMenu.edit',
    defaultMessage: 'Edit'
  }
});

export interface MachineMenuProps {
  assetId: number;
}

export default function ManchineMenu(props: MachineMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={(e) => handleClick(e)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={() => navigate(`/assets/edit/${props.assetId}`)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{formatMessage(m.edit)}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
