import {
  Asset,
  AssetsActionTypes,
  CREATE_ASSET,
  CREATE_ASSET_FAILURE,
  CREATE_ASSET_SUCCESS,
  DELETE_ASSET,
  DELETE_ASSET_FAILURE,
  DELETE_ASSET_SUCCESS,
  LOAD_ASSETS,
  LOAD_ASSETS_FAILURE,
  LOAD_ASSETS_SUCCESS,
  UPDATE_ASSET,
  UPDATE_ASSET_FAILURE,
  UPDATE_ASSET_SUCCESS
} from './types';

export function loadAll(): AssetsActionTypes {
  return {
    type: LOAD_ASSETS
  };
}

export function loadAllSuccess(assets: Asset[]): AssetsActionTypes {
  return {
    type: LOAD_ASSETS_SUCCESS,
    payload: assets
  };
}

export function loadAllFailure(error: unknown): AssetsActionTypes {
  return {
    type: LOAD_ASSETS_FAILURE,
    payload: { error }
  };
}

export function createSuccess(asset: Asset): AssetsActionTypes {
  return {
    type: CREATE_ASSET_SUCCESS,
    payload: asset
  };
}

export function updateSuccess(asset: Asset): AssetsActionTypes {
  return {
    type: UPDATE_ASSET_SUCCESS,
    payload: asset
  };
}

export function remove(assetId: number): AssetsActionTypes {
  return {
    type: DELETE_ASSET,
    payload: {
      assetId
    }
  };
}

export function removeSuccess(assetId: number): AssetsActionTypes {
  return {
    type: DELETE_ASSET_SUCCESS,
    payload: {
      assetId
    }
  };
}

export function removeFailure(error: unknown): AssetsActionTypes {
  return {
    type: DELETE_ASSET_FAILURE,
    payload: { error }
  };
}
