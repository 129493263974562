import { useMediaQuery } from 'react-responsive';

export const useResponsiveQueries = (): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isPortrait: boolean;
  isLandscape: boolean;
} => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width:991px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 991px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

  return {
    isMobile, isTablet, isDesktop, isPortrait, isLandscape
  };
};
