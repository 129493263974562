import { defineMessages, MessageDescriptor } from 'react-intl';

export const commonMessages = defineMessages({
  statusNotAvailable: { id: 'productionMonitoring.statusNotAvailable', defaultMessage: 'Unknown' },
  statusStopped: { id: 'productionMonitoring.statusStopped', defaultMessage: 'Stopped' },
  statusIdle: { id: 'productionMonitoring.statusIdle', defaultMessage: 'Idle' },
  statusRunning: { id: 'productionMonitoring.statusRunning', defaultMessage: 'Running' },
  statusOffline: { id: 'productionMonitoring.statusOffline', defaultMessage: 'Offline' },
  machineStateProviderAccelerometer: {
    id: 'productionMonitoring.machineStateProviderAccelerometer',
    defaultMessage: 'Accelerometer'
  },
  machineStateProviderMTConnect: {
    id: 'productionMonitoring.machineStateProviderMTConnect',
    defaultMessage: 'MTConnect'
  },
  machineStateProviderNotConfigured: {
    id: 'productionMonitoring.machineStateProviderNotConfigured',
    defaultMessage: 'Not configured'
  }
});
