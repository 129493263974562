import * as React from 'react';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import * as api from '../../../api';
import { MachineStateInterval } from '../../../types';
import { MachineStatusLabel } from '..';
import style from './UtilizationHeatMapDetailsPopupContent.module.css';
import { getStatusColor, getStatusTextMessageId } from '../../..';

const m = defineMessages({
  statusColumnTitle: {
    id: 'UtilizationHeatMapDetailsPopupContent.statusColumnTitle',
    defaultMessage: 'Status'
  },
  timeColumnTitle: {
    id: 'UtilizationHeatMapDetailsPopupContent.timeColumnTitle',
    defaultMessage: 'Time'
  },
  durationColumnTitle: {
    id: 'UtilizationHeatMapDetailsPopupContent.durationColumnTitle',
    defaultMessage: 'Duration'
  },
  loadingError: {
    id: 'UtilizationHeatMapDetailsPopupContent.loadingError',
    defaultMessage: 'An error occurred while loading data from the server.'
  }
});

export interface IUtilizationHeatMapDetailsPopupContentProps {
  assetId: number;
  startTime: Date;
  endTime: Date;
}

export function UtilizationHeatMapDetailsPopupContent(
  props: IUtilizationHeatMapDetailsPopupContentProps
) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [machineStateIntervals, setMachineStateIntervals] = useState<MachineStateInterval[]>([]);
  const { formatMessage } = useIntl();

  const formatDuration = (interval: MachineStateInterval): string => {
    const duration = moment.duration(
      moment(interval.endTime).diff(moment(interval.startTime), 'seconds'),
      'seconds'
    );
    if (duration.asHours() >= 1) {
      return moment.utc(duration.asMilliseconds()).format('H[h]');
    }
    return moment.utc(duration.asMilliseconds()).format('m[m]ss[s]');
  };

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      api
        .loadMachineStateIntervals(props.assetId, props.startTime, props.endTime)
        .then((data) => {
          setHasLoadingError(false);
          setMachineStateIntervals(data);
        })
        .catch((error) => {
          setHasLoadingError(true);
        })
        .finally(() => setIsLoading(false));
    }

    loadData();
  }, [props.assetId, props.startTime, props.endTime]);

  return (
    <>
      <Typography variant="h4">{moment(props.startTime).format('MMMM DD YYYY')}</Typography>
      <Typography variant="h5">
        {`${moment(props.startTime).format('h A')} - ${moment(
          props.endTime
        ).format('h A')}`}
      </Typography>
      {isLoading && (
        <div
          style={{
            width: '100%',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box display={isLoading ? 'block' : 'none'}>
            <CircularProgress />
          </Box>
        </div>
      )}
      {!isLoading && !hasLoadingError && (
        <div className={style.statusTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{formatMessage(m.statusColumnTitle)}</TableCell>
                <TableCell>{formatMessage(m.timeColumnTitle)}</TableCell>
                <TableCell>{formatMessage(m.durationColumnTitle)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {machineStateIntervals.map((interval) => (
                <TableRow key={interval.startTime}>
                  <TableCell>
                    <Typography
                      variant="body1"
                      sx={{ color: getStatusColor(interval.machineStatus) }}
                    >
                      {formatMessage(getStatusTextMessageId(interval.machineStatus, undefined))}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {`${moment(interval.startTime).format(
                        'HH:mm:ss'
                      )}-${moment(interval.endTime).format('HH:mm:ss')}`}
                    </Typography>
                  </TableCell>
                  <TableCell>{formatDuration(interval)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {!isLoading && hasLoadingError && (
        <Typography variant="body1">{formatMessage(m.loadingError)}</Typography>
      )}
    </>
  );
}
