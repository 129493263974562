import {
  DevicesActionTypes,
  LOAD_DEVICES,
  LOAD_DEVICES_FAILURE,
  LOAD_DEVICES_SUCCESS,
  INSTALL_GATEWAY,
  InstallGatewayFailureAction,
  InstallGatewaySuccessAction,
  INSTALL_GATEWAY_SUCCESS,
  InstallGatewayAction,
  INSTALL_GATEWAY_FAILURE,
  UNINSTALL_GATEWAY,
  UNINSTALL_GATEWAY_SUCCESS,
  UNINSTALL_GATEWAY_FAILURE,
  UninstallGatewaySuccessAction,
  UninstallGatewayFailureAction,
  UninstallGatewayAction,
  Gateway,
  Beacon,
  UPDATE_GATEWAY_DISPLAY_NAME,
  UpdateGatewayDisplayNameAction,
  UPDATE_GATEWAY_DISPLAY_NAME_FAILURE,
  UpdateGatewayDisplayNameSuccessAction,
  UpdateGatewayDisplayNameFailureAction,
  UPDATE_GATEWAY_DISPLAY_NAME_SUCCESS,
  GATEWAY_CONNECTION_STATE_CHANGED,
  GatewayConnectionStateChangedAction,
  BEACONS_UPDATED,
  BeaconsUpdatedAction
} from './types';
import { InstallGatewayModel } from './form-models';

export function loadAll(): DevicesActionTypes {
  return {
    type: LOAD_DEVICES
  };
}

export function loadAllSuccess(payload: {
  gateways: Gateway[];
  beacons: Beacon[];
}): DevicesActionTypes {
  return {
    type: LOAD_DEVICES_SUCCESS,
    payload
  };
}

export function loadAllFailure(error: unknown): DevicesActionTypes {
  return {
    type: LOAD_DEVICES_FAILURE,
    payload: { error }
  };
}

export function installGateway(installDeviceModel: InstallGatewayModel): InstallGatewayAction {
  return {
    type: INSTALL_GATEWAY,
    payload: installDeviceModel
  };
}

export function installGatewaySuccess(gateway: Gateway): InstallGatewaySuccessAction {
  return {
    type: INSTALL_GATEWAY_SUCCESS,
    payload: { gateway }
  };
}

export function installGatewayFailure(error: unknown): InstallGatewayFailureAction {
  return {
    type: INSTALL_GATEWAY_FAILURE,
    payload: { error }
  };
}

export function uninstallGateway(deviceId: number): UninstallGatewayAction {
  return {
    type: UNINSTALL_GATEWAY,
    payload: { deviceId }
  };
}

export function uninstallGatewaySuccess(gateway: Gateway): UninstallGatewaySuccessAction {
  return {
    type: UNINSTALL_GATEWAY_SUCCESS,
    payload: { gateway }
  };
}

export function uninstallGatewayFailure(error: unknown): UninstallGatewayFailureAction {
  return {
    type: UNINSTALL_GATEWAY_FAILURE,
    payload: { error }
  };
}

export function updateGatewayDisplayName(
  gatewayId: number,
  displayName: string
): UpdateGatewayDisplayNameAction {
  return {
    type: UPDATE_GATEWAY_DISPLAY_NAME,
    payload: { gatewayId, displayName }
  };
}

export function updateGatewayDisplayNameSuccess(
  gatewayId: number,
  displayName: string
): UpdateGatewayDisplayNameSuccessAction {
  return {
    type: UPDATE_GATEWAY_DISPLAY_NAME_SUCCESS,
    payload: { gatewayId, displayName }
  };
}

export function updateGatewayDisplayNameFailure(
  gatewayId: number,
  error: unknown
): UpdateGatewayDisplayNameFailureAction {
  return {
    type: UPDATE_GATEWAY_DISPLAY_NAME_FAILURE,
    payload: { gatewayId, error }
  };
}

export function changeGatewayConnectionState(
  gatewayId: number,
  isConnected: boolean
): GatewayConnectionStateChangedAction {
  return {
    type: GATEWAY_CONNECTION_STATE_CHANGED,
    payload: { gatewayId, isConnected }
  };
}

export function beaconsUpdated(beacons: Partial<Beacon>[]): BeaconsUpdatedAction {
  return {
    type: BEACONS_UPDATED,
    payload: { beacons }
  };
}
