export const localTimeFormat = 'YYYY-MM-DDTHH:mm:ss';

export class ApiError extends Error {
  public readonly statusCode: number;

  constructor(statusCode: number, message?: string | undefined) {
    super(message);
    this.statusCode = statusCode;
  }
}
