import queryString from 'query-string';
import * as React from 'react';
import {
  Alert, Backdrop, Button, CircularProgress, Typography
} from '@mui/material';

import { defineMessages, WrappedComponentProps, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { AppLogo } from '../../../components/logos';
import { ApplicationState } from '../../../store';
import * as AuthenticationActions from '../actions';
import { ModelState } from '../../../utils';

const m = defineMessages({
  confirmingEmail: {
    id: 'ConfirmEmailPage.confirmingEmail',
    defaultMessage: 'Confirming email...'
  },
  emailConfirmedTitle: {
    id: 'ConfirmEmailPage.emailConfirmedTitle',
    defaultMessage: 'Your email is now confirmed'
  },
  emailConfirmedMessage: {
    id: 'ConfirmEmailPage.emailConfirmedTitle',
    defaultMessage: 'You may now login.'
  },
  login: { id: 'ConfirmEmailPage.login', defaultMessage: 'Login' },
  invalidParameters: {
    id: 'ConfirmEmailPage.invalidParameters',
    defaultMessage: 'Expected parameters were not present in the url.'
  },
  unexpectedError: {
    id: 'ConfirmEmailPage.unexpectedError',
    defaultMessage:
      'An unexpected error occurred while confirming your email. Please try again later or contact us if problem persists.'
  },
  pageFooter: {
    id: 'LoginPage.pageFooter',
    defaultMessage:
      'ARIV provides simple solutions to organizations facing the Industry 4.0 transformation.'
  }
});

const ConfirmEmailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryStringParams = queryString.parse(location.search);
  const { formatMessage } = useIntl();

  const isConfirmingEmail = useSelector<ApplicationState, boolean>(
    (state) => state.authentication.isConfirmingEmail
  );
  const confirmEmailErrors = useSelector<ApplicationState, ModelState | undefined>(
    (state) => state.authentication.authenticationErrors
  );
  const isEmailConfirmed = useSelector<ApplicationState, boolean>(
    (state) => state.authentication.isEmailConfirmed
  );

  React.useEffect(() => {
    if (
      queryStringParams.userId
      && queryStringParams.token
      && !Array.isArray(queryStringParams.userId)
      && !Array.isArray(queryStringParams.token)
    ) {
      dispatch(
        AuthenticationActions.confirmEmail(
          decodeURIComponent(queryStringParams.userId),
          decodeURIComponent(queryStringParams.token)
        )
      );
    } else {
      navigate('/error');
    }
  }, [navigate, queryStringParams.userId, queryStringParams.token, dispatch]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignContent: 'center',
            flexGrow: 1,
            maxWidth: '1200px',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexWrap: 'wrap'
          }}
        >
          <div style={{ margin: '0 auto' }}>
            <AppLogo />
          </div>
          <div style={{ margin: '0 auto' }}>
            <div
              style={{
                maxWidth: '400px',
                width: '80vw',
                margin: '16px',
                paddingTop: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around'
              }}
            >
              <Backdrop open={isConfirmingEmail}>
                <CircularProgress />
              </Backdrop>

              {!isConfirmingEmail && isEmailConfirmed && (
                <>
                  <Typography variant="h5">{formatMessage(m.emailConfirmedTitle)}</Typography>
                  <div style={{ marginTop: '50px', width: '200px' }}>
                    <Button variant="contained" onClick={() => navigate('/login')}>
                      {formatMessage(m.login)}
                    </Button>
                  </div>
                </>
              )}
              {!isConfirmingEmail && confirmEmailErrors && (
                <Alert severity="error">
                  {confirmEmailErrors
                    .localizeErrorMessages(formatMessage)
                    .reduce((a, b) => `${a}\n${b}`)}
                </Alert>
              )}
              {!isConfirmingEmail && !isEmailConfirmed && !confirmEmailErrors && (
                <Alert severity="error">{formatMessage(m.unexpectedError)}</Alert>
              )}
            </div>
          </div>
        </div>
        <div
          className="bg-primary"
          style={{
            minHeight: '250px',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            marginTop: '16px',
            padding: '8px'
          }}
        >
          <h3 className="color-white" style={{ textAlign: 'center', margin: '0 auto' }}>
            {formatMessage(m.pageFooter)}
          </h3>
        </div>
      </div>
    </>
  );
};

export { ConfirmEmailPage };
