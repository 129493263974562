import * as d3 from 'd3';
import React, { useEffect, useState } from 'react';

export interface LegendProps {
  color: d3.ScaleSequential<string> | string;
  title: string;
  tickSize: number;
  width: number;
  height: number;
  marginTop: number;
  marginRight: number;
  marginBottom: number;
  marginLeft: number;
  ticks: number;
  tickFormat: (domainValue: number | { valueOf(): number }, index: number) => string;
}

ChartLegend.defaultProps = {
  tickSize: 6,
  width: 400,
  height: 50,
  marginTop: 18,
  marginRight: 0,
  marginBottom: 22,
  marginLeft: 0,
  ticks: 11
} as LegendProps;

export function ChartLegend(props: LegendProps) {
  useEffect(() => {
    d3.select('#legend-container').selectAll('svg').remove();

    const svg = d3
      .select('#legend-container')
      .append('svg')
      .attr('viewBox', `0 0 ${props.width} ${props.height}`)
      .style('overflow', 'visible')
      .style('display', 'block');

    const tickAdjust = (g: d3.Selection<SVGGElement, unknown, HTMLElement, any>) => g.selectAll('.tick line').attr('y1', props.marginTop + props.marginBottom - props.height);

    const xScale = d3
      .scaleLinear<number>()
      .range([props.marginLeft, props.width - props.marginRight])
      .domain([0, 10]);

    svg
      .append('image')
      .attr('x', props.marginLeft)
      .attr('y', props.marginTop)
      .attr('width', props.width - props.marginLeft - props.marginRight)
      .attr('height', props.height - props.marginTop - props.marginBottom)
      .attr('preserveAspectRatio', 'none')
      .attr(
        'xlink:href',

        ramp(
          (t) => (typeof props.color === 'string'
            ? (props.color as string)
            : props.color.interpolator()(t)),
          props.width - props.marginLeft - props.marginRight
        ).toDataURL()
      );

    svg
      .append('g')
      .attr('transform', `translate(0,${props.height - props.marginBottom})`)
      .classed('x', true)
      .classed('axis', true)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(props.ticks)
          .tickFormat(props.tickFormat)
          .tickSize(props.tickSize)
          .tickValues(d3.range(0, 11))
      )
      .call(tickAdjust)
      .call((g) => g.select('.domain').remove())
      .call((g) => g
        .append('text')
        .attr('x', props.marginLeft)
        .attr('y', props.marginTop + props.marginBottom - props.height - 6)
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'start')
        .attr('font-size', '12px')
        .attr('font-weight', 'bold')
        .text(props.title));
  }, []);

  return <div id="legend-container" />;
}

function ramp(color: (t: number) => string, n = 256) {
  const canvas = document.createElement('canvas');
  canvas.setAttribute('width', n.toString());
  canvas.setAttribute('height', '1');
  const context = canvas.getContext('2d')!;
  for (let i = 0; i < n; i += 1) {
    context.fillStyle = color(i / (n - 1));
    context.fillRect(i, 0, 1, 1);
  }
  return canvas;
}
