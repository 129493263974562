import {
  all, call, fork, put, race, take, takeLatest, delay, takeEvery, select
} from 'redux-saga/effects';
import * as SignalR from '@microsoft/signalr';
import { eventChannel } from 'redux-saga';
import * as actions from './actions';
import * as api from './api';
import * as deviceActions from '../devices/actions';

import { GET_SESSION_SUCCESS, LOGIN_SUCCESS, LOGOUT } from '../authentication';

import {
  LOAD_MACHINE_OVERVIEWS,
  MachineOverview,
  LoadMachineOverviewsAction,
  SignalREvents,
  SIGNALR_CONNECTION_CLOSED,
  SIGNALR_MACHINE_STATE_CHANGED,
  SIGNALR_MACHINE_UTILIZATION_UPDATED,
  SIGNALR_EVENT_GATEWAY_CONNECTION_STATE_CHANGED,
  SIGNALR_BEACONS_BATTERY_UPDATED,
  SIGNALR_CONNECTION_RECONNECTED
} from './types';
import { ApplicationState } from '../../store';

const connection = new SignalR.HubConnectionBuilder()
  .withUrl('/machineStateHub')
  .configureLogging(SignalR.LogLevel.Information)
  .build();

const channel = eventChannel((emitter) => {
  connection.onclose(() => {
    // Send the event to our saga
    emitter({ event: SIGNALR_CONNECTION_CLOSED, payload: {} });
  });

  connection.on(SIGNALR_MACHINE_STATE_CHANGED, (data) => {
    emitter({ event: SIGNALR_MACHINE_STATE_CHANGED, payload: data });
  });

  connection.on(SIGNALR_MACHINE_UTILIZATION_UPDATED, (data) => {
    emitter({ event: SIGNALR_MACHINE_UTILIZATION_UPDATED, payload: data });
  });

  connection.on(SIGNALR_EVENT_GATEWAY_CONNECTION_STATE_CHANGED, (data) => {
    emitter({ event: SIGNALR_EVENT_GATEWAY_CONNECTION_STATE_CHANGED, payload: data });
  });

  connection.on(SIGNALR_BEACONS_BATTERY_UPDATED, (data) => {
    emitter({ event: SIGNALR_BEACONS_BATTERY_UPDATED, payload: data });
  });

  connection.on(SIGNALR_BEACONS_BATTERY_UPDATED, (data) => {
    emitter({ event: SIGNALR_BEACONS_BATTERY_UPDATED, payload: data });
  });

  // Return an unsubscribe method
  return () => {
    // Perform any cleanup you need here
  };
});

function* handleSignalRMessages(data: SignalREvents) {
  console.log(`SignalR event received: ${data.event}`);

  if (data.event === SIGNALR_CONNECTION_CLOSED) {
    yield put({ type: SIGNALR_CONNECTION_CLOSED });
  } else if (data.event === SIGNALR_MACHINE_STATE_CHANGED) {
    yield put(actions.loadMachineOverviewSuccess(data.payload));
  } else if (data.event === SIGNALR_MACHINE_UTILIZATION_UPDATED) {
    yield put(actions.loadMachineOverviewSuccess(data.payload));
  } else if (data.event === SIGNALR_EVENT_GATEWAY_CONNECTION_STATE_CHANGED) {
    yield put(deviceActions.changeGatewayConnectionState(data.payload.gatewayId, data.payload.isConnected));
  } else if (data.event === SIGNALR_BEACONS_BATTERY_UPDATED) {
    yield put(deviceActions.beaconsUpdated(data.payload.beaconsBatteryUpdates));
  }
}

function* loadMachineOverviews(_action: LoadMachineOverviewsAction) {
  try {
    const result = (yield call(api.loadAllMachineStates)) as MachineOverview[];
    yield put(actions.loadAllSuccess(result));
  } catch (e) {
    yield put(actions.loadAllFailure(e));
  }
}

function* watchLoadAll() {
  yield takeLatest(LOAD_MACHINE_OVERVIEWS, loadMachineOverviews);
}

function* handleMachineStateHubConnection() {
  console.log('Starting SignalR');
  while (true) {
    try {
      const isAuthenticated = (yield select(
        (state: ApplicationState): boolean => state.authentication.isAuthenticated
      )) as boolean;

      if (!isAuthenticated) {
        yield take(GET_SESSION_SUCCESS);
      }

      const connectionState = connection.state;
      if (connectionState === SignalR.HubConnectionState.Disconnected) {
        yield connection.start();
      }

      if (connection.state === SignalR.HubConnectionState.Connected) {
        yield put({ type: SIGNALR_CONNECTION_RECONNECTED });
        console.log('SignalR started');

        const { logout }: { connectionClosed: any; logout: any } = yield race({
          connectionClosed: take(SIGNALR_CONNECTION_CLOSED),
          logout: take(LOGOUT)
        });
        console.log('SignalR stopped');

        if (logout) {
          yield connection.stop();
          yield put({ type: SIGNALR_CONNECTION_CLOSED });
        }
      }
    } catch (error) {
      console.error(error);
    }

    yield delay(5000); // wait 5 seconds then retry connection
  }
}

function* productionMonitoringSagas() {
  yield all([fork(handleMachineStateHubConnection), takeEvery(channel, handleSignalRMessages), fork(watchLoadAll)]);
}

export { productionMonitoringSagas };
