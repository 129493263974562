import {
  del,
  get,
  post,
  postWithoutResponse,
  put,
  putWithoutResponse
} from '../../../../../utils/api';
import { WorkShiftFormData } from './Components/WorkShiftForm';
import { WorkShift } from './types';

const workShiftsEndpoint = '/api/workshifts';

export async function loadAllWorkShifts(assetId: number): Promise<WorkShift[]> {
  return get<WorkShift[]>(`${workShiftsEndpoint}/${assetId}`);
}

export async function creatWorkShift(assetId: number, workShift: WorkShiftFormData): Promise<void> {
  return postWithoutResponse(`${workShiftsEndpoint}`, { ...workShift, assetId });
}

export async function updateWorkShift(
  assetId: number,
  workShiftId: number,
  workShift: WorkShiftFormData
): Promise<void> {
  return putWithoutResponse(`${workShiftsEndpoint}`, {
    ...workShift,
    assetId,
    workShiftId,
    startTime: workShift.startTime,
    endTime: workShift.endTime
  });
}

export async function deleteWorkShift(workShiftId: number): Promise<void> {
  return del(`${workShiftsEndpoint}/${workShiftId}`);
}
