import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import BigHeader from '../../../../components/layout/BigHeader/BigHeader';
import BigHeaderCell from '../../../../components/layout/BigHeader/BigHeaderCell';
import { Asset } from '../../../assets';
import { MachineOverview, AssetProductionMonitoringStatus } from '../../types';
import { ApplicationState } from '../../../../store';
import { calculateUtilization } from '../../helper-functions';
import { MachineStatusLabel } from '.';

const m = defineMessages({
  machineInfo: {
    id: 'ViewMachineOverviewPageHeader.machineInfo',
    defaultMessage: 'Machine Name'
  },
  status: {
    id: 'ViewMachineOverviewPageHeader.status',
    defaultMessage: 'Status'
  },
  noImageSet: {
    id: 'ViewMachineOverviewPageHeader.noImageSet',
    defaultMessage: 'Edit machine to set a picture'
  },
  runningTime: {
    id: 'ViewMachineOverviewPageHeader.runningTime',
    defaultMessage: "Today's Run Time"
  },
  overview: {
    id: 'ViewMachineOverviewPageHeader.overview',
    defaultMessage: 'Overview'
  }
});

export interface IViewMachineOverviewPageHeaderProps {
  asset: Asset;
}

export function ViewMachineOverviewPageHeader(props: IViewMachineOverviewPageHeaderProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width:991px)'
  });
  const { formatMessage } = useIntl();
  const machineOverview: MachineOverview = useSelector<ApplicationState, MachineOverview>(
    (state) => state.machineStates.overviewByAssetId[props.asset.id]
  );

  return (
    <>
      {!isMobile && (
        <BigHeader fixed={false}>
          <BigHeaderCell>
            <div
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: isTablet ? '150px' : '200px',
                  width: isTablet ? '200px' : '320px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {props.asset.imageFileName ? (
                  <img
                    alt=""
                    src={`/api/files/picture/${props.asset.imageFileName}`}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%'
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                    }}
                  >
                    <NavLink to={`/assets/edit/${props.asset.id}`}>
                      {formatMessage(m.noImageSet)}
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </BigHeaderCell>
          <BigHeaderCell>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                padding: '40px 0',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="h5" textAlign="center">
                {formatMessage(m.machineInfo)}
              </Typography>
              <Typography variant="h4">{props.asset.name}</Typography>
            </div>
          </BigHeaderCell>
          <BigHeaderCell>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                padding: '40px 0',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="h5" textAlign="center">
                {formatMessage(m.runningTime)}
              </Typography>
              <Typography variant="h4" textAlign="center">
                {machineOverview
                  ? `${moment
                    .utc(
                      moment
                        .duration(machineOverview.totalRunningSeconds, 'seconds')
                        .asMilliseconds()
                    )
                    .format('HH:mm:ss')} (${calculateUtilization(machineOverview).toFixed(0)}%)`
                  : '--'}
              </Typography>
            </div>
          </BigHeaderCell>
          <BigHeaderCell>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                padding: '40px 0',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="h5">{formatMessage(m.status)}</Typography>
              <MachineStatusLabel
                machineStatus={
                  machineOverview
                    ? machineOverview.machineStatus
                    : AssetProductionMonitoringStatus.unknown
                }
                unknownStatusReason={machineOverview.unknownStatusReason}
              />
            </div>
          </BigHeaderCell>
        </BigHeader>
      )}

      {isMobile && (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h5">{props.asset.name}</Typography>
            <div
              style={{
                display: 'flex',
                height: '100px',
                width: '120px',
                marginLeft: '10px'
              }}
            >
              {props.asset.imageFileName ? (
                <img
                  src={`/api/files/picture/${props.asset.imageFileName}`}
                  alt=""
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <NavLink to={`/assets/edit/${props.asset.id}`}>
                    {formatMessage(m.noImageSet)}
                  </NavLink>
                </div>
              )}
            </div>

            <Typography variant="h5">{formatMessage(m.runningTime)}</Typography>
            <Typography variant="h4">
              {machineOverview
                ? `${moment
                  .utc(
                    moment
                      .duration(machineOverview.totalRunningSeconds, 'seconds')
                      .asMilliseconds()
                  )
                  .format('HH:mm:ss')} (${calculateUtilization(machineOverview).toFixed(0)}%)`
                : '--'}
            </Typography>
            <Typography variant="h5">{formatMessage(m.status)}</Typography>
            <MachineStatusLabel
              machineStatus={
                machineOverview
                  ? machineOverview.machineStatus
                  : AssetProductionMonitoringStatus.unknown
              }
              unknownStatusReason={machineOverview.unknownStatusReason}
            />
          </div>
        </>
      )}
    </>
  );
}
