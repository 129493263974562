import {
  Avatar, Divider, Menu, MenuItem
} from '@mui/material';
import * as React from 'react';
import {
  defineMessages, WrappedComponentProps, injectIntl, useIntl
} from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ApplicationState } from '../../../../store';
import * as AuthenticationActions from '../../../authentication/actions';

const m = defineMessages({
  logOut: { id: 'AccountControl.logOut', defaultMessage: 'Log out' }
});

interface DispatchProps {
  authenticationActions: typeof AuthenticationActions;
}

interface StoreProps {
  authenticatedUserEmail: string | undefined;
}

export type AccountControlProps = WrappedComponentProps & DispatchProps & StoreProps;

const AccountControl = (props: AccountControlProps) => {
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const dropdownHeader = (
    <div>
      {props.authenticatedUserEmail}
      {' '}
    </div>
  );

  const open = Boolean(anchorEl);

  const handleClick = (target: Element) => {
    setAnchorEl(target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Avatar
        onClick={(event) => handleClick(event.currentTarget)}
        sx={{ cursor: 'pointer', bgcolor: 'primary.dark' }}
      >
        {props.authenticatedUserEmail?.charAt(0)?.toUpperCase()}
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar />
          {' '}
          {props.authenticatedUserEmail}
        </MenuItem>
        <Divider />
        <MenuItem onClick={props.authenticationActions.logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {formatMessage(m.logOut)}
        </MenuItem>
      </Menu>
    </>
  );
};

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  authenticatedUserEmail: state.authentication.authenticatedUserEmail
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  authenticationActions: bindActionCreators(AuthenticationActions, dispatch)
});

const intlComponent = injectIntl(AccountControl);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as AccountControl };
