import {
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryLabel,
  VictoryBar,
  VictoryContainer
} from 'victory';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { formatDuration, nameof } from 'utils';

import { useIntl, defineMessages } from 'react-intl';
import {
  Backdrop, CircularProgress, Typography, useTheme
} from '@mui/material';
import { commonMessages, UtilizationData, UtilizationGranularity } from '../../..';
import * as api from '../../../api';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const m = defineMessages({
  noDataAvailabeForSelectedPeriod: {
    id: 'UtilizationChart.noDataAvailabeForSelectedPeriod',
    defaultMessage: 'There is no data available for the selected period of time.'
  }
});

export const chartOptions: ChartOptions<'bar'> = {
  plugins: {
    title: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (context) => `${context.dataset.label} ${formatDuration(
          context.dataset.data[context.dataIndex] as number * 3600
        )}`
      }
    }
  },
  responsive: true,
  aspectRatio: 35 / 9,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true,
      max: 24
    }
  }
};

export interface UtilizationChartProps {
  assetId: number;
  startDate: Date;
  endDate: Date;
  showUnavailableTime: boolean;
  showOffTime: boolean;
  showRunningTime: boolean;
  showIdleTime: boolean;
  minWidth: number;
}

export function UtilizationChart(props: UtilizationChartProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [utilizationChartData, setUtilizationChartData] = useState<UtilizationData[] | undefined>(
    undefined
  );
  const { formatMessage } = useIntl();
  const theme = useTheme();

  useEffect(() => {
    async function loadUtilization() {
      setIsLoading(true);
      setHasLoadingError(false);
      await api
        .loadMachineUtilization(
          props.assetId,
          props.startDate,
          props.endDate,
          UtilizationGranularity.Daily
        )
        .then((utilization) => setUtilizationChartData(utilization))
        .catch(() => setHasLoadingError(true))
        .finally(() => setIsLoading(false));
    }

    loadUtilization();
  }, [props.assetId, props.startDate, props.endDate]);

  return (
    <>
      {!isLoading && !hasLoadingError && utilizationChartData && utilizationChartData.length > 0 && (
        <div
          style={{
            display: 'flex',
            minWidth: props.minWidth,
            maxHeight: '500px',
            maxWidth: '1000px'
          }}
        >
          <Bar
            options={chartOptions}
            data={{
              labels: utilizationChartData.map((p) => moment(p.date).format('LL')),
              datasets: [
                {
                  hidden: !props.showRunningTime,
                  label: formatMessage(commonMessages.statusRunning),
                  data: utilizationChartData.map((p) => p.totalRunningSeconds / 3600.0),
                  backgroundColor: theme.palette.success.light,
                  maxBarThickness: 80
                },
                {
                  hidden: !props.showIdleTime,
                  label: formatMessage(commonMessages.statusIdle),
                  data: utilizationChartData.map((p) => p.totalIdleSeconds / 3600.0),
                  backgroundColor: theme.palette.warning.light,
                  maxBarThickness: 80
                },
                {
                  hidden: !props.showOffTime,
                  label: formatMessage(commonMessages.statusStopped),
                  data: utilizationChartData.map((p) => p.totalOffSeconds / 3600.0),
                  backgroundColor: theme.palette.error.light,
                  maxBarThickness: 80
                },
                {
                  hidden: !props.showUnavailableTime,
                  label: formatMessage(commonMessages.statusNotAvailable),
                  data: utilizationChartData.map((p) => p.totalUnknownSeconds / 3600.0),
                  backgroundColor: theme.palette.text.disabled,
                  maxBarThickness: 80
                }
              ]
            }}
          />
        </div>
      )}

      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>

      {!isLoading
        && !hasLoadingError
        && utilizationChartData
        && utilizationChartData.length === 0 && (
          <Typography variant="body1">
            {formatMessage(m.noDataAvailabeForSelectedPeriod)}
          </Typography>
      )}

      {!isLoading && hasLoadingError && (
        <Typography variant="body1">{formatMessage(m.noDataAvailabeForSelectedPeriod)}</Typography>
      )}
    </>
  );
}

const formatLabels = (data: any) => {
  // eslint-disable-next-line no-underscore-dangle
  const percentage = Math.round((data.datum._y * 10000) / 24) / 100;
  return percentage > 0 ? `${percentage}%` : '';
};
