import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  product: { id: 'AppLogo.product', defaultMessage: 'Journey' },
  company: { id: 'AppLogo.company', defaultMessage: 'by ARIV' }
});

// eslint-disable-next-line react/prefer-stateless-function
const AppLogo = () => {
  const { formatMessage } = useIntl();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
        alignItems: 'stretch',
        margin: '16px 32px'
      }}
    >
      <div
        style={{
          fontFamily: 'Open Sans',
          fontSize: '60px',
          fontWeight: 500,
          lineHeight: '1em',
          letterSpacing: '5px',
          textTransform: 'lowercase',
          color: 'var(--color-secondary)'
        }}
      >
        {formatMessage(m.product)}
      </div>
      <div
        style={{
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: 800,
          letterSpacing: '1px',
          margin: '1.5em 0.5em 0 0',
          color: 'var(--color-secondary)'
        }}
      >
        {formatMessage(m.company)}
      </div>
    </div>
  );
};

export { AppLogo };
