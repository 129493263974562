import { ChartData, ScatterDataPoint } from 'chart.js';
import React from 'react';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import {
  IconButton, Stack, Tooltip, Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export interface TrendDetailsProps {
  title: React.ReactNode;
  value: React.ReactNode;
  trendHelperText: string | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  data:
    | null
    | ChartData<'line', (number | ScatterDataPoint | null)[], unknown>
    | ((
        canvas: HTMLCanvasElement
      ) => ChartData<'line', (number | ScatterDataPoint | null)[], unknown>);
  change: number | null;
  isNegativeKpi: boolean;
}

export function TrendIndicator({
  title,
  value,
  change,
  trendHelperText,
  isNegativeKpi
}: TrendDetailsProps) {
  const TrendingIcon = change && ((change < 0 && !isNegativeKpi) || (change > 0 && isNegativeKpi))
    ? TrendingDownIcon
    : TrendingUpIcon;
  const color = change && ((change < 0 && !isNegativeKpi) || (change > 0 && isNegativeKpi))
    ? 'error'
    : 'primary';
  return (
    <Stack direction="column">
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="h4">{value ?? '--'}</Typography>
      <Typography sx={{ visibility: change == null ? 'hidden' : 'block' }}>
        <TrendingIcon sx={{ verticalAlign: 'middle' }} color={color} />
        {' '}
        {`${change && change < 0 ? '' : '+'}${change}%`}
        {trendHelperText && (
          <Tooltip
            title={trendHelperText}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
      {/* <Line
        data={data}
        width="10"
        height="5"
        color={color}
        options={{
          plugins: {
            legend: { display: false },
          },
          scales: {
            y: { display: false, grid: { drawBorder: false } },
            x: { display: false, grid: { drawBorder: false } },
          },
        }}
      /> */}
    </Stack>
  );
}
