import React, { useCallback } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { ModelState } from './ModelState';
import { BadRequestError } from './BadRequestError';
import { ApiError } from './types';

const m = defineMessages({
  genericServerErrorTitle: {
    id: 'LoginScreen.genericServerErrorTitle',
    defaultMessage: 'Unexpected server error'
  },
  genericServerErrorMessage: {
    id: 'LoginScreen.genericServerErrorMessage',
    defaultMessage:
      'An unexpected error occurred. Please try again or contact us if the problem persists.'
  },
  forbidden: {
    id: 'LoginScreen.forbidden',
    defaultMessage: 'Your current role does not allow you to perform this action.'
  }
});

export enum ErrorHandlingStrategy {
  AlertNonFieldSpecificErrorsOnly = 0,
  AlertAllErrors = 1,
}

const toastOptions: ToastOptions = { position: 'top-center', autoClose: 5000 };

function useApiErrorHandler(): (
  error: unknown,
  errorHandlingStrategy?: ErrorHandlingStrategy
) => ModelState | undefined {
  const { formatMessage } = useIntl();

  const showGenericServerError = useCallback(() => {
    toast.error(formatMessage(m.genericServerErrorMessage), toastOptions);
  }, [formatMessage]);

  const errorsToToastMessage = (errors: string[]): ToastContent => {
    if (errors.length === 0) {
      return undefined;
    }

    // eslint-disable-next-line react/no-children-prop
    const listItemElements = errors.map((error) => React.createElement('li', { children: error }));

    // eslint-disable-next-line react/no-children-prop
    return React.createElement('ul', { children: listItemElements });
  };

  const handleBadRequestError = useCallback(
    (error: BadRequestError, errorHandlingStrategy: ErrorHandlingStrategy) => {
      const errors = errorHandlingStrategy === ErrorHandlingStrategy.AlertAllErrors
        ? error.modelState.getAllErrorMessages(formatMessage)
        : error.modelState.getNonFieldSpecificErrorMessages(formatMessage);
      if (errors) {
        toast.error(errorsToToastMessage(errors), toastOptions);
      } else {
        showGenericServerError();
      }

      return error.modelState;
    },
    [formatMessage, showGenericServerError]
  );

  const handleApiError = useCallback(
    (error: ApiError) => {
      if (error.statusCode === 403) {
        toast.error(formatMessage(m.forbidden), toastOptions);
      } else {
        showGenericServerError();
      }
    },
    [formatMessage, showGenericServerError]
  );

  const handleError = useCallback(
    (
      error: unknown,
      errorHandlingStrategy: ErrorHandlingStrategy = ErrorHandlingStrategy.AlertAllErrors
    ): ModelState | undefined => {
      if (error instanceof ApiError) {
        handleApiError(error);
      } else if (error instanceof BadRequestError) {
        return handleBadRequestError(error, errorHandlingStrategy);
      } else {
        showGenericServerError();
      }
      return undefined;
    },
    [handleApiError, handleBadRequestError, showGenericServerError]
  );

  return handleError;
}

export default useApiErrorHandler;
