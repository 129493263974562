import { get, post, postWithoutResponse } from '../../utils/api';
import { Beacon, Gateway } from './types';
import { InstallGatewayModel } from './form-models';

const endpoint = '/api/devices';

export async function loadAll(): Promise<{ gateways: Gateway[]; beacons: Beacon[] }> {
  return get<{ gateways: Gateway[]; beacons: Beacon[] }>(endpoint);
}

export async function installGateway(installDeviceModel: InstallGatewayModel): Promise<Gateway> {
  return post<Gateway>(`${endpoint}/${installDeviceModel.gatewayId}/install`, installDeviceModel);
}

export async function uninstallGateway(gatewayId: number): Promise<Gateway> {
  return post<Gateway>(`${endpoint}/${gatewayId}/uninstall`, {});
}

export async function updateGatewayDisplayName(
  gatewayId: number,
  displayName: string
): Promise<void> {
  postWithoutResponse(`${endpoint}/${gatewayId}/updateDisplayName`, {
    displayName
  });
}
