/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  Skeleton,
  TextField,
  Stack,
  Select,
  MenuItem,
  Container,
  DialogContentText,
  FormControl,
  InputLabel
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Asset } from '../../..';
import { resizeImage, TimeZones } from '../../../../../utils';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import * as AssetActions from '../../../actions';
import * as AssetApi from '../../../api';
import { commonMessages } from '../../../../../constants';

export const ASSET_NAME_MAXIMUM_LENGTH = 50;

const m = defineMessages({
  title: { id: 'EditAssetForm.title', defaultMessage: 'Create asset' },
  fieldName: { id: 'EditAssetForm.fieldName', defaultMessage: 'Name' },
  fieldNamePlaceholder: {
    id: 'EditAssetForm.fieldNamePlaceholder',
    defaultMessage: 'Enter asset name'
  },
  fieldDescription: { id: 'EditAssetForm.fieldDescription', defaultMessage: 'Description' },
  fieldDescriptionPlaceholder: {
    id: 'EditAssetForm.fieldDescriptionPlaceholder',
    defaultMessage: 'Enter asset description'
  },
  fieldTags: { id: 'EditAssetForm.fieldTags', defaultMessage: 'Tags (optional)' },
  fieldTagsPlaceholder: {
    id: 'EditAssetForm.fieldTagsPlaceholder',
    defaultMessage: 'Assign custom tags'
  },
  fieldTimeZone: { id: 'EditAssetForm.fieldTimeZone', defaultMessage: 'TimeZone' },
  fieldTimeZonePlaceholder: {
    id: 'EditAssetForm.fieldTimeZonePlaceholder',
    defaultMessage: 'Select TimeZone'
  },
  uploadImage: { id: 'EditAssetForm.uploadImage', defaultMessage: 'Set picture' },
  removeImage: { id: 'EditAssetForm.removeImage', defaultMessage: 'Remove' },
  productionMonitoring: {
    id: 'EditAssetForm.productionMonitoring',
    defaultMessage: 'Production Monitoring'
  },
  confirmDeleteTitle: { id: 'EditAssetForm.confirmDeleteTitle', defaultMessage: 'Confirm delete' },
  confirmDeleteDescription: {
    id: 'EditAssetForm.confirmDeleteDescription',
    defaultMessage: 'Do you really want to delete this asset?'
  },
  basicInformationTabTitle: {
    id: 'EditAssetForm.basicInformationTabTitle',
    defaultMessage: 'Basic information'
  },
  productionMonitoringTabTitle: {
    id: 'EditAssetForm.productionMonitoringTabTitle',
    defaultMessage: 'Production monitoring'
  },
  nameRequired: { id: 'CreateAssetModel.nameRequired', defaultMessage: 'The name is required.' },
  nameMaximumLength: {
    id: 'CreateAssetModel.nameMaximumLength',
    defaultMessage: `Name must not exceed ${ASSET_NAME_MAXIMUM_LENGTH} characters.`
  },
  timeZoneRequired: {
    id: 'CreateAssetModel.timeZoneRequired',
    defaultMessage: 'The timezone is required.'
  }
});

export interface IEditAssetFormProps {
  name: string;
  timeZoneId: string;
}

export function EditAssetForm(props: { asset: Asset }) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | undefined>();
  const [assetImage, setAssetImage] = useState<File | undefined | null>();
  const { control, handleSubmit } = useForm<IEditAssetFormProps>({
    mode: 'onBlur'
  });

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiErrorHandler = useApiErrorHandler();

  const onImageUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files) {
      setAssetImage(event.target.files[0]);
      setImagePreview(URL.createObjectURL(event.target.files[0]));
    }
  }, []);

  const onImageRemoved = useCallback(() => {
    setAssetImage(undefined);
    setImagePreview(undefined);
    setAssetImage(null);
  }, []);

  const updateAsset = useCallback(
    async (formData: IEditAssetFormProps) => {
      setIsSaving(true);

      let image: Blob | undefined | null = assetImage;

      if (assetImage) {
        image = await resizeImage({ file: assetImage, maxSize: 600 });
      }

      await AssetApi.update(props.asset.id, formData, image)
        .then((asset) => dispatch(AssetActions.updateSuccess(asset)))
        .then(() => navigate(-1))
        .catch((e) => apiErrorHandler(e))
        .finally(() => setIsSaving(false));
    },
    [apiErrorHandler, assetImage, dispatch, navigate, props.asset.id]
  );

  const deleteAsset = useCallback(async () => {
    setIsSaving(true);
    await AssetApi.remove(props.asset.id)
      .then(() => dispatch(AssetActions.removeSuccess(props.asset.id)))
      .then(() => navigate(-1))
      .catch((e) => apiErrorHandler(e))
      .finally(() => setIsSaving(false));
  }, [props.asset.id, dispatch, navigate, apiErrorHandler]);

  return (
    <Container maxWidth="md">
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          '& .MuiTextField-root': { m: 1, width: '25ch' }
        }}
        noValidate
        autoComplete="off"
      >
        <Controller
          name="name"
          control={control}
          defaultValue={props.asset.name}
          rules={{
            required: {
              value: true,
              message: formatMessage(m.nameRequired)
            },
            maxLength: {
              value: ASSET_NAME_MAXIMUM_LENGTH,
              message: formatMessage(m.nameMaximumLength)
            }
          }}
          render={({ field, fieldState, formState }) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              id={field.name}
              label={formatMessage(m.fieldName)}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              placeholder={formatMessage(m.fieldNamePlaceholder)}
            />
          )}
        />
        <Box
          sx={{
            maxWidth: '250px',
            maxHeight: '250px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {props.asset.imageFileName || imagePreview ? (
            <img
              src={`${imagePreview || `/api/files/picture/${props.asset.imageFileName}`}`}
              alt=""
              loading="lazy"
              width="250px"
              height="250px"
              style={{ objectFit: 'contain' }}
            />
          ) : (
            <Skeleton variant="rectangular" width={210} height={118} />
          )}

          <Box sx={{ marginBottom: '10px' }}>
            {(props.asset.imageFileName || imagePreview) && (
              <Button
                variant="text"
                sx={{ color: 'error.light', marginRight: '10px' }}
                onClick={onImageRemoved}
              >
                {formatMessage(m.removeImage)}
              </Button>
            )}

            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={onImageUpload}
              />
              <Button className="btn-choose" variant="outlined" component="span">
                {/* <FileUploadIcon name="upload" /> */}
                {formatMessage(m.uploadImage)}
              </Button>
            </label>
          </Box>
        </Box>

        <Controller
          name="timeZoneId"
          defaultValue={props.asset.timeZoneId}
          rules={{
            required: {
              value: true,
              message: formatMessage(m.timeZoneRequired)
            }
          }}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="timezone-select">{formatMessage(m.fieldTimeZone)}</InputLabel>
              <Select
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                labelId="timezone-select"
                error={fieldState.invalid}
                placeholder={formatMessage(m.fieldTimeZonePlaceholder)}
                sx={{ width: '350px' }}
                onChange={(_event) => field.onChange(_event)}
              >
                {TimeZones.map((t) => (
                  <MenuItem key={t.value} value={t.value}>{t.text}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Box sx={{ marginTop: '30px ', display: 'flex', alignSelf: 'center' }}>
          <Button color="error" type="button" onClick={() => setIsConfirmDialogOpen(true)}>
            {formatMessage(commonMessages.delete)}
          </Button>
          <Dialog
            open={isConfirmDialogOpen}
            onClose={() => setIsConfirmDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{formatMessage(m.confirmDeleteTitle)}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {formatMessage(m.confirmDeleteDescription)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsConfirmDialogOpen(false)}>
                {formatMessage(commonMessages.cancel)}
              </Button>
              <Button
                onClick={() => {
                  setIsConfirmDialogOpen(false);
                  deleteAsset();
                }}
                autoFocus
              >
                {formatMessage(commonMessages.yes)}
              </Button>
            </DialogActions>
          </Dialog>
          <Button variant="outlined" type="button" onClick={() => navigate(-1)} disabled={isSaving}>
            {formatMessage(commonMessages.cancel)}
          </Button>
          <LoadingButton
            variant="contained"
            loading={isSaving}
            disabled={isSaving}
            onClick={handleSubmit(updateAsset)}
          >
            {formatMessage(commonMessages.save)}
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}
