import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import {
  Menu, SemanticICONS, Icon, Accordion
} from 'semantic-ui-react';

interface NavigationMenuSectionOwnProps {
  title: string;
  icon: SemanticICONS | undefined;
}

interface NavigationMenuSectionState {
  isActive: boolean;
}

export type NavigationMenuSectionProps = NavigationMenuSectionOwnProps & WrappedComponentProps;

const m = defineMessages({});

class NavigationMenuSection extends React.Component<
  NavigationMenuSectionProps,
  NavigationMenuSectionState
> {
  public constructor(props: NavigationMenuSectionProps) {
    super(props);

    this.state = {
      isActive: true
    };
  }

  public render() {
    const { formatMessage } = this.props.intl;

    return (
      <Menu.Item>
        <Accordion.Title
          active={this.state.isActive}
          onClick={() => this.setState((current) => ({ isActive: !current.isActive }))}
        >
          {this.props.icon && <Icon name={this.props.icon} />}
          {this.props.title}
          <Icon name="dropdown" />
        </Accordion.Title>

        <Accordion.Content active={this.state.isActive}>
          <Menu.Menu>{this.props.children}</Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
    );
  }
}

const connectedComponent = injectIntl(NavigationMenuSection);
export { connectedComponent as NavigationMenuSection };
