import moment from 'moment';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { ApplicationState } from '../../store';
import { UtilizationData, UnknownMachineStateReason } from './types';

const m = defineMessages({
  unknownStatusReasonInsufficientAccelerometerData: {
    id: 'ProductionMonitoring.unknownStatusReasonInsufficientAccelerometerData',
    defaultMessage:
      'The connection quality with the gateway is insufficient to accurately monitor the machine state. There might be temporary sources of interference.'
  },
  unknownStatusReasonMachineStateOutOfDate: {
    id: 'ProductionMonitoring.unknownStatusReasonMachineStateOutOfDate',
    defaultMessage:
      'Data is not being received from the beacon. This could indicate a communication issue with the gateway.'
  },
  unknownStatusReasonModuleRestarted: {
    id: 'ProductionMonitoring.unknownStatusReasonModuleRestarted',
    defaultMessage: 'Gateway has just restarted. Connection status should update within 2 minutes.'
  }
});

export const getTodayTotalTimesByAssetId = (
  state: ApplicationState,
  assetId: number
): UtilizationData | undefined => getUtilizationByDate(state, assetId, moment().toDate());

export const getUtilizationChartData = (
  state: ApplicationState,
  assetId: number,
  date: Date
): {
  offData: { x: Date; y: number }[];
  idleData: { x: Date; y: number }[];
  runningData: { x: Date; y: number }[];
  unknownData: { x: Date; y: number }[];
} => {
  const dates = new Array(7)
    .fill(undefined)
    .map((val, index) => moment(date).subtract(index, 'days'))
    .reverse();

  const offData = dates.map((d) => {
    const utilization = getUtilizationByDate(state, assetId, d.toDate());
    return { x: d.toDate(), y: utilization ? utilization.totalOffSeconds / 60 / 60 : 0 };
  });

  const idleData = dates.map((d) => {
    const utilization = getUtilizationByDate(state, assetId, d.toDate());
    return { x: d.toDate(), y: utilization ? utilization.totalIdleSeconds / 60 / 60 : 0 };
  });

  const runningData = dates.map((d) => {
    const utilization = getUtilizationByDate(state, assetId, d.toDate());
    return { x: d.toDate(), y: utilization ? utilization.totalRunningSeconds / 60 / 60 : 0 };
  });

  const unknownData = dates.map((d) => {
    const utilization = getUtilizationByDate(state, assetId, d.toDate());
    return { x: d.toDate(), y: utilization ? utilization.totalUnknownSeconds / 60 / 60 : 0 };
  });

  return {
    offData,
    idleData,
    runningData,
    unknownData
  };
};

export const getUtilizationByDate = (
  state: ApplicationState,
  assetId: number,
  date: Date
): UtilizationData | undefined => {
  const assetUtilization = state.machineStates.utilizationByAssetId[assetId];
  if (assetUtilization === undefined) {
    return undefined;
  }

  return assetUtilization[moment(date).format('YYYY-MM-DD')];
};

export const getUnknownStatusReasonDescription = (
  unknownStatusReason: UnknownMachineStateReason
): MessageDescriptor => {
  switch (unknownStatusReason) {
    case UnknownMachineStateReason.InsufficientAccelerometerData:
      return m.unknownStatusReasonInsufficientAccelerometerData;
    case UnknownMachineStateReason.ModuleRestarted:
      return m.unknownStatusReasonModuleRestarted;
    default:
      return m.unknownStatusReasonMachineStateOutOfDate;
  }
};
