/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent, useState, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  Message,
  Input,
  Card,
  Progress,
  Header,
  Dropdown,
  Icon
} from 'semantic-ui-react';

import { commonMessages } from '../../../constants';
import { ApplicationState } from '../../../store';
import {
  nameof, ModelState, TimeZones, resizeImage
} from '../../../utils';
import { DefaultPageLayout } from '../../layout/views';
import * as AssetActions from '../actions';
import { CreateAssetModel } from '../form-models/CreateAssetModel';
import ImageLoader from '../../../components/common/ImageLoader';
import { getDefaultTimezoneId } from '../selectors';
import * as AssetApi from '../api';
import { BadRequestError } from '../../../utils/BadRequestError';

const m = defineMessages({
  title: { id: 'CreateAssetPage.title', defaultMessage: 'Create asset' },
  fieldNameTitle: { id: 'CreateAssetPage.fieldName', defaultMessage: 'Create a machine name' },
  fieldNameSubtitle: {
    id: 'CreateAssetPage.fieldNameSubTitle',
    defaultMessage:
      'This name will be used to identify the machine on all screens. It is recommended to use the name employees usually use to refer to the machine. No worries, it can be changed later. The name has to be unique, so think about adding a number if needed.'
  },
  fieldNamePlaceholder: {
    id: 'CreateAssetPage.fieldNamePlaceholder',
    defaultMessage: 'Enter asset name'
  },
  fieldTimeZoneTitle: {
    id: 'CreateAssetPage.fieldTimeZoneTitle',
    defaultMessage: 'Select machine time zone'
  },
  fieldTimeZoneSubtitle: {
    id: 'CreateAssetPage.fieldTimeZoneSubtitle',
    defaultMessage: 'Time zone information is required for a better reporting experience.'
  },
  fieldTimeZonePlaceholder: {
    id: 'CreateAssetPage.fieldTimeZonePlaceholder',
    defaultMessage: 'Select machine time zone'
  },
  fieldTagsTitle: { id: 'CreateAssetPage.fieldTagsTitle', defaultMessage: 'Tags (optional)' },
  fieldTagsSubtitle: {
    id: 'CreateAssetPage.fieldTagsSubtitle',
    defaultMessage:
      'Adding tags to machines helps to compare and analyze certain machines with each other. A tag can for example be the type of machine, the department in which the machine stands or the product the machine is used for. Later on existing tags can be changed, deleted and new tags can be added.'
  },
  fieldTagsPlaceholder: {
    id: 'CreateAssetPage.fieldTagsPlaceholder',
    defaultMessage: 'Assign custom tags'
  },
  fieldPictureTitle: {
    id: 'CreateAssetPage.fieldPictureTitle',
    defaultMessage: 'Take a picture of the machine (optional)'
  },
  fieldPictureSubtitle: {
    id: 'CreateAssetPage.fieldPictureSubtitle',
    defaultMessage:
      'Taking or uploading a picture of the machine helps users to identify it when using journey. The picture should clearly identify the machine. You can skip this step and upload a picture later as well.'
  },
  uploadImage: { id: 'CreateAssetPage.uploadImage', defaultMessage: 'Upload' },
  replaceImage: { id: 'CreateAssetPage.replaceImage', defaultMessage: 'Replace' },
  removeImage: { id: 'CreateAssetPage.removeImage', defaultMessage: 'Remove' },
  productionMonitoring: {
    id: 'CreateAssetPage.productionMonitoring',
    defaultMessage: 'Production Monitoring'
  },
  fieldMinimumProductionStopDurationTitle: {
    id: 'CreateAssetPage.fieldMinimumProductionStopDurationTitle',
    defaultMessage: 'Duration of production interruptions'
  },
  fieldMinimumProductionStopDurationSubtitle: {
    id: 'CreateAssetPage.fieldMinimumProductionStopDurationSubtitle',
    defaultMessage:
      'Each company manages their production process differently. Therefore, the duration of unplanned production interruptions is different from company to company and even from machine to machine. In the following please define the duration after which the machine user will receive a prompt to specify the reason for the production interruption through the Journey - Industry 4.0 platform app at the machine.'
  }
});

enum CreationStep {
  SetName,
  SetTimezone,
  SetPicture,
  SetTag,
  SetMinimumProductionStopDuration,
}

const CreateAssetPage: FunctionComponent = () => {
  const [creationStep, setCreationStep] = useState<CreationStep>(CreationStep.SetName);
  const [name, setName] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [modelState, setModelState] = useState(new ModelState());
  const [imagePreview, setImagePreview] = useState<string | undefined>();
  const [assetImage, setAssetImage] = useState<File | undefined>();
  const defaultTimeZoneId = useSelector<ApplicationState, string | undefined>((state) => getDefaultTimezoneId(state));
  const [timeZoneId, setTimeZoneId] = useState<string | undefined>(defaultTimeZoneId);
  const [
    minimumProductionStopDurationInMinutes,
    setMinimumProductionStopDurationInMinutes
  ] = useState<number>(10);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onImageUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files) {
      setAssetImage(event.target.files[0]);
      setImagePreview(URL.createObjectURL(event.target.files[0]));
    }
  }, []);

  const goToStep = useCallback(
    (step: CreationStep) => {
      if (creationStep === CreationStep.SetName) {
        const model = new CreateAssetModel({ name });
        const validationResult = model.validateName();
        setModelState(validationResult);

        if (!validationResult.isFieldInvalid(nameof<CreateAssetModel>('name'))) {
          setCreationStep(step);
        }
      } else if (creationStep === CreationStep.SetTimezone) {
        const model = new CreateAssetModel({ timeZoneId });
        const validationResult = model.validateTimeZone();
        setModelState(validationResult);

        if (!validationResult.isFieldInvalid(nameof<CreateAssetModel>('timeZoneId'))) {
          setCreationStep(step);
        }
      } else {
        setCreationStep(step);
      }
    },
    [name, timeZoneId, creationStep]
  );

  const createAsset = useCallback(async () => {
    const model = new CreateAssetModel({
      name,
      timeZoneId,
      tags,
      minimumProductionStopDurationInMinutes
    });
    const validationResult = model.validate();
    setModelState(validationResult);

    if (validationResult.isValid) {
      setIsSaving(true);

      let image: Blob | undefined = assetImage;

      if (assetImage) {
        image = await resizeImage({ file: assetImage, maxSize: 600 });
      }

      await AssetApi.create(model, image)
        .then((asset) => {
          dispatch(AssetActions.createSuccess(asset));
          navigate('/assets');
        })
        .catch((e) => (e instanceof BadRequestError ? setModelState(e.modelState) : null))
        .finally(() => setIsSaving(false));
    }
  }, [
    name,
    timeZoneId,
    tags,
    minimumProductionStopDurationInMinutes,
    assetImage,
    dispatch,
    navigate
  ]);

  return (
    <>
      <DefaultPageLayout>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            maxWidth: '1400px',
            margin: 'auto',
            width: '100%'
          }}
        >
          {/* Set machine name */}
          {creationStep === CreationStep.SetName && (
            <>
              <div style={{ width: '100%' }}>
                <Progress percent={20} color="blue" />
              </div>
              <div style={{ maxWidth: '1000px' }}>
                <Header as="h1">{formatMessage(m.fieldNameTitle)}</Header>
                <Header as="h3">{formatMessage(m.fieldNameSubtitle)}</Header>
                <div style={{ maxWidth: '900px', width: '100%', margin: 'auto' }}>
                  <Form error={modelState.isFieldInvalid(nameof<CreateAssetModel>('name'))}>
                    <Form.Field
                      required
                      width="16"
                      id={nameof<CreateAssetModel>('name')}
                      inline
                      control={Input}
                      name={nameof<CreateAssetModel>('name')}
                      error={modelState.isFieldInvalid(nameof<CreateAssetModel>('name'))}
                      placeholder={formatMessage(m.fieldNamePlaceholder)}
                      value={name}
                      onChange={(_event: any, data: any) => setName(data.value)}
                    />
                    {!modelState.isValid && (
                      <Message
                        error
                        list={modelState
                          .localizeErrorMessages(formatMessage)
                          .map((value, index) => ({ key: index, content: value }))}
                      />
                    )}
                  </Form>
                </div>
              </div>
              <div style={{ marginTop: '100px', display: 'flex' }}>
                <div style={{ marginRight: '40px' }}>
                  <Button
                    basic
                    type="button"
                    onClick={() => navigate('/assets')}
                    disabled={isSaving}
                    content={formatMessage(commonMessages.cancel)}
                  />
                </div>
                <Button
                  primary
                  loading={isSaving}
                  disabled={isSaving}
                  content={formatMessage(commonMessages.next)}
                  onClick={() => goToStep(CreationStep.SetTimezone)}
                />
              </div>
            </>
          )}

          {/* Set time zone */}
          {creationStep === CreationStep.SetTimezone && (
            <>
              <div style={{ width: '100%' }}>
                <Progress percent={40} color="blue" />
              </div>
              <div style={{ maxWidth: '1000px', width: '100%' }}>
                <Header as="h1">{formatMessage(m.fieldTimeZoneTitle)}</Header>
                <Header as="h3">{formatMessage(m.fieldTimeZoneSubtitle)}</Header>
                <div style={{ maxWidth: '900px', width: '100%', margin: 'auto' }}>
                  <Form error={modelState.isFieldInvalid(nameof<CreateAssetModel>('timeZoneId'))}>
                    <Form.Field
                      required
                      error={modelState.isFieldInvalid(nameof<CreateAssetModel>('timeZoneId'))}
                      control={Dropdown}
                      width={16}
                      placeholder={formatMessage(m.fieldTimeZonePlaceholder)}
                      fluid
                      defaultValue={timeZoneId}
                      selection
                      options={TimeZones}
                      onChange={(_event: any, data: any) => setTimeZoneId(data.value as string)}
                    />
                    {!modelState.isValid && (
                      <Message
                        error
                        list={modelState
                          .localizeErrorMessages(formatMessage)
                          .map((value, index) => ({ key: index, content: value }))}
                      />
                    )}
                  </Form>
                </div>
              </div>
              <div style={{ marginTop: '100px', display: 'flex' }}>
                <div style={{ marginRight: '40px' }}>
                  <Button
                    basic
                    type="button"
                    onClick={() => setCreationStep(CreationStep.SetName)}
                    disabled={isSaving}
                    content={formatMessage(commonMessages.back)}
                  />
                </div>
                <Button
                  primary
                  loading={isSaving}
                  disabled={isSaving}
                  content={formatMessage(commonMessages.next)}
                  onClick={() => goToStep(CreationStep.SetPicture)}
                />
              </div>
            </>
          )}

          {/* Set picture */}
          {creationStep === CreationStep.SetPicture && (
            <>
              <div style={{ width: '100%' }}>
                <Progress percent={60} color="blue" />
              </div>
              <div style={{ maxWidth: '1000px', width: '100%' }}>
                <Header as="h1">{formatMessage(m.fieldPictureTitle)}</Header>
                <Header as="h3">{formatMessage(m.fieldPictureSubtitle)}</Header>
                <div style={{ maxWidth: '900px', width: '100%', margin: 'auto' }}>
                  <Form error={!modelState.isValid}>
                    {imagePreview ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '320px',
                            height: '200px',
                            margin: '20px auto'
                          }}
                        >
                          <ImageLoader url={imagePreview} />
                        </div>
                        <div style={{ marginTop: '16px', textAlign: 'center' }}>
                          <a
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                              marginRight: '10px'
                            }}
                            onClick={() => setImagePreview(undefined)}
                          >
                            {formatMessage(m.removeImage)}
                          </a>
                          <Button
                            basic
                            as="label"
                            htmlFor="file"
                            type="button"
                            icon="upload"
                            content={formatMessage(m.replaceImage)}
                          />
                          <Input
                            type="file"
                            id="file"
                            style={{ display: 'none' }}
                            onChange={onImageUpload}
                          />
                        </div>
                      </>
                    ) : (
                      <Card centered>
                        <Card.Content>
                          <div
                            style={{
                              height: '180px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Button as="label" htmlFor="file" type="button" icon>
                              <Icon name="upload" />
                              {formatMessage(m.uploadImage)}
                            </Button>

                            <Input
                              type="file"
                              id="file"
                              style={{ display: 'none' }}
                              onChange={onImageUpload}
                            />
                          </div>
                        </Card.Content>
                      </Card>
                    )}
                  </Form>
                </div>
                {!modelState.isValid && (
                  <Message
                    error
                    list={modelState.localizeErrorMessages(formatMessage).map((value, index) => ({ key: index, content: value }))}
                  />
                )}
              </div>
              <div style={{ marginTop: '40px', display: 'flex' }}>
                <div style={{ marginRight: '40px' }}>
                  <Button
                    basic
                    type="button"
                    onClick={() => setCreationStep(CreationStep.SetTimezone)}
                    disabled={isSaving}
                    content={formatMessage(commonMessages.back)}
                  />
                </div>

                <Button
                  primary
                  loading={isSaving}
                  disabled={isSaving}
                  content={formatMessage(commonMessages.next)}
                  onClick={() => goToStep(CreationStep.SetMinimumProductionStopDuration)}
                />
              </div>
            </>
          )}

          {/* Set minimum production stop duration */}
          {creationStep === CreationStep.SetMinimumProductionStopDuration && (
            <>
              <div style={{ width: '100%' }}>
                <Progress percent={80} color="blue" />
              </div>
              <div style={{ maxWidth: '1000px' }}>
                <Header as="h1">{formatMessage(m.fieldMinimumProductionStopDurationTitle)}</Header>
                <Header as="h3">
                  {formatMessage(m.fieldMinimumProductionStopDurationSubtitle)}
                </Header>
                <div style={{ maxWidth: '900px', width: '100%', margin: 'auto' }}>
                  <Form
                    error={modelState.isFieldInvalid(
                      nameof<CreateAssetModel>('minimumProductionStopDurationInMinutes')
                    )}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: '400px',
                        margin: 'auto'
                      }}
                    >
                      <Form.Field
                        required
                        id={nameof<CreateAssetModel>('minimumProductionStopDurationInMinutes')}
                        inline
                        type="number"
                        min="5"
                        max="60"
                        defa
                        control={Input}
                        name={nameof<CreateAssetModel>('minimumProductionStopDurationInMinutes')}
                        error={modelState.isFieldInvalid(
                          nameof<CreateAssetModel>('minimumProductionStopDurationInMinutes')
                        )}
                        value={minimumProductionStopDurationInMinutes}
                        onChange={(_event: any, data: any) => setMinimumProductionStopDurationInMinutes(data.value)}
                      />
                      <h4 style={{ margin: 'auto 0 auto 5px' }}>
                        {formatMessage(commonMessages.timeUnitMinutes)}
                      </h4>
                    </div>
                    {!modelState.isValid && (
                      <Message
                        error
                        list={modelState
                          .localizeErrorMessages(formatMessage)
                          .map((value, index) => ({ key: index, content: value }))}
                      />
                    )}
                  </Form>
                </div>
              </div>
              <div style={{ marginTop: '100px', display: 'flex' }}>
                <div style={{ marginRight: '40px' }}>
                  <Button
                    basic
                    type="button"
                    onClick={() => setCreationStep(CreationStep.SetPicture)}
                    disabled={isSaving}
                    content={formatMessage(commonMessages.back)}
                  />
                </div>
                <Button
                  primary
                  loading={isSaving}
                  disabled={isSaving}
                  content={formatMessage(commonMessages.next)}
                  onClick={createAsset}
                />
              </div>
            </>
          )}

          {/* Set tags */}
          {/* Tags hidden until other features that use them are implemented */}

          {/* {creationStep === CreationStep.SetTag && <React.Fragment>
                        <div style={{ width: '100%' }}><Progress percent={80} color="blue"/></div>
                        <div style={{ maxWidth: '1000px', width: '100%'}}>
                            <Header as="h1">{formatMessage(m.fieldTagsTitle)}</Header>
                            <Header as="h3">{formatMessage(m.fieldTagsSubtitle)}</Header>
                            <div style={{ maxWidth: '900px', width: '100%', margin: 'auto'}}>
                                <AssetTagsSelector onChange={(values) => setTags(values) } />
                                {!modelState.isValid && <Message error={true} list={modelState.localizeErrorMessages(formatMessage).map((value, index) => { return { key: index, content: value  }; })} />}
                            </div>
                        </div>
                        <div style={{ marginTop: '100px', display: 'flex' }}>
                            <div style={{ marginRight: '40px' }}>
                                <Button basic type='button' onClick={() => goToStep(CreationStep.SetPicture)} disabled={isSaving} content={formatMessage(commonMessages.back)} />
                            </div>
                            <Button primary loading={isSaving} disabled={isSaving} content={formatMessage(commonMessages.create)}  onClick={createAsset} />
                        </div>
                    </React.Fragment>} */}
        </div>
      </DefaultPageLayout>
    </>
  );
};

export { CreateAssetPage };
