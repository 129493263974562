import _ from 'lodash';
import { Reducer } from 'redux';

import moment from 'moment';
import {
  ProductionMonitoringActionTypes,
  LOAD_MACHINE_OVERVIEWS_SUCCESS,
  ProductionMonitoringState,
  CONFIGURE_MACHINE_STATE_BEACON_SUCCESS,
  LOAD_MACHINE_OVERVIEW_SUCCESS,
  UtilizationData,
  OVERVIEW_PAGE_TOGGLE_UTILIZATION_CHART_STATUS,
  AssetProductionMonitoringStatus
} from './types';

const initialState: ProductionMonitoringState = {
  overviewByAssetId: [],
  utilizationByAssetId: [],
  utilizationReportSettings: {
    isRunningTimeToggledOn: true,
    isIdleTimeToggledOn: false,
    isOffTimeToggledOn: false,
    isUnavailableTimeToggledOn: false
  }
};

const machineStateReducer: Reducer<ProductionMonitoringState, ProductionMonitoringActionTypes> = (
  state = initialState,
  action
): ProductionMonitoringState => {
  switch (action.type) {
    case LOAD_MACHINE_OVERVIEWS_SUCCESS: {
      const machineStates = Object.assign({}, ...action.payload.map((x) => ({ [x.assetId]: x })));
      const utilizations = Object.assign(
        {},
        ...action.payload.map<typeof state.utilizationByAssetId>((x) => ({
          [x.assetId]: {
            [moment().format('YYYY-MM-DD')]: {
              ...x,
              date: moment().format('YYYY-MM-DD')
            }
          }
        }))
      );
      return {
        ...state,
        overviewByAssetId: _.merge({}, state.overviewByAssetId, machineStates),
        utilizationByAssetId: _.merge({}, state.utilizationByAssetId, utilizations)
      };
    }

    case LOAD_MACHINE_OVERVIEW_SUCCESS: {
      const utilization: UtilizationData = {
        date: moment().format('YYYY-MM-DD'),
        totalIdleSeconds: action.payload.totalIdleSeconds,
        totalOffSeconds: action.payload.totalOffSeconds,
        totalRunningSeconds: action.payload.totalRunningSeconds,
        totalUnknownSeconds: action.payload.totalUnknownSeconds
      };

      return {
        ...state,
        overviewByAssetId: {
          ...state.overviewByAssetId,
          [action.payload.assetId]: _.merge(
            {},
            state.overviewByAssetId[action.payload.assetId],
            action.payload
          )
        },
        utilizationByAssetId: {
          ...state.utilizationByAssetId,
          [action.payload.assetId]: _.merge(
            {},
            state.utilizationByAssetId[action.payload.assetId],
            { [moment().format('YYYY-MM-DD')]: utilization }
          )
        }
      };
    }

    case OVERVIEW_PAGE_TOGGLE_UTILIZATION_CHART_STATUS: {
      return {
        ...state,
        utilizationReportSettings: {
          ...state.utilizationReportSettings,
          isIdleTimeToggledOn:
            action.payload.status === AssetProductionMonitoringStatus.idle
              ? !state.utilizationReportSettings.isIdleTimeToggledOn
              : state.utilizationReportSettings.isIdleTimeToggledOn,
          isOffTimeToggledOn:
            action.payload.status === AssetProductionMonitoringStatus.off
              ? !state.utilizationReportSettings.isOffTimeToggledOn
              : state.utilizationReportSettings.isOffTimeToggledOn,
          isRunningTimeToggledOn:
            action.payload.status === AssetProductionMonitoringStatus.running
              ? !state.utilizationReportSettings.isRunningTimeToggledOn
              : state.utilizationReportSettings.isRunningTimeToggledOn,
          isUnavailableTimeToggledOn:
            action.payload.status === AssetProductionMonitoringStatus.unknown
              ? !state.utilizationReportSettings.isUnavailableTimeToggledOn
              : state.utilizationReportSettings.isUnavailableTimeToggledOn
        }
      };
    }

    case CONFIGURE_MACHINE_STATE_BEACON_SUCCESS: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
};

export { machineStateReducer };
