export class StringHelper {
  public static hasValue(value: string | undefined): boolean {
    // Validate that there's at least one character of non whitespace
    return value != null && /\S/.test(value);
  }

  public static removeDiacritics(value: string | undefined): string | undefined {
    return value ? value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : value;
  }
}
