import * as React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { TableCell, TableRow, Typography } from '@mui/material';
import { Beacon, Gateway } from '../..';
import { getAssetById, Asset } from '../../../assets';
import { ApplicationState } from '../../../../store';
import {
  getGatewayById,
  SemanticBatteryIcons,
  getBatteryStatusIconName,
  getBatteryStatusColor
} from '../../selectors';
import {
  MachineOverview,
  AssetProductionMonitoringStatus,
  getUnknownStatusReasonDescription
} from '../../../production-monitoring';

export interface IBeaconTableRowProps {
  beacon: Beacon;
}

const m = defineMessages({
  installationStatusInstalled: {
    id: 'BeaconInstallationTableRow.installationStatusInstalled',
    defaultMessage: 'Installed'
  },
  installationStatusNotInstalled: {
    id: 'BeaconInstallationTableRow.installationStatusNotInstalled',
    defaultMessage: '--'
  },
  noAssociatedGateway: {
    id: 'BeaconInstallationTableRow.noLinkedGateway',
    defaultMessage: '(not linked)'
  },
  batteryUnavailable: { id: 'BeaconInstallationTableRow.batteryUnavailable', defaultMessage: '--' },
  connectionStatusGood: {
    id: 'BeaconInstallationTableRow.batteryUnavailable',
    defaultMessage: 'Good'
  }
});

export function BeaconTableRow(props: IBeaconTableRowProps) {
  const { formatMessage } = useIntl();
  const installedOnMachine = useSelector<ApplicationState, Asset | undefined>((state) => (props.beacon.installedOnAssetId === undefined
    ? undefined
    : getAssetById(state, props.beacon.installedOnAssetId)));
  const installedOnMachineState = useSelector<ApplicationState, MachineOverview | undefined>(
    (state) => (props.beacon.installedOnAssetId === undefined
      ? undefined
      : state.machineStates.overviewByAssetId[props.beacon.installedOnAssetId])
  );
  const associatedGateway = useSelector<ApplicationState, Gateway | undefined>((state) => (props.beacon.associatedGatewayId === undefined
    ? undefined
    : getGatewayById(state, props.beacon.associatedGatewayId)));
  const batteryIcon = useSelector<ApplicationState, SemanticBatteryIcons | undefined>((state) => (props.beacon.batteryPercentage
    ? getBatteryStatusIconName(state, props.beacon.batteryPercentage)
    : undefined));
  const batteryIconColor = useSelector<ApplicationState>((state) => (props.beacon.batteryPercentage
    ? getBatteryStatusColor(state, props.beacon.batteryPercentage)
    : undefined));

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <TableRow key={props.beacon.id}>
      <TableCell>
        {installedOnMachine === undefined
          ? formatMessage(m.installationStatusNotInstalled)
          : installedOnMachine.name}
      </TableCell>
      <TableCell>{props.beacon.uniqueId}</TableCell>

      <TableCell>
        {/* eslint-disable-next-line no-nested-ternary */}
        {installedOnMachineState
          ? installedOnMachineState.unknownStatusReason != null
            ? formatMessage(
              getUnknownStatusReasonDescription(installedOnMachineState.unknownStatusReason)
            )
            : formatMessage(m.connectionStatusGood)
          : formatMessage(m.installationStatusNotInstalled)}
      </TableCell>

      <TableCell>
        {/* eslint-disable-next-line no-nested-ternary */}
        {associatedGateway
          ? associatedGateway.displayName
            ? associatedGateway.displayName
            : associatedGateway.deviceId
          : formatMessage(m.noAssociatedGateway)}
      </TableCell>

      <TableCell>
        {batteryIcon ? (
          <Typography variant="body1">
            {`${props.beacon.batteryPercentage}%`}
            {' '}
          </Typography>
        ) : (
          formatMessage(m.batteryUnavailable)
        )}
      </TableCell>
    </TableRow>
  );
}
