import { get, postWithoutResponse } from '../../utils/api';

const endpoint = '/api/authentication';

export async function login(email: string, password: string): Promise<void> {
  return postWithoutResponse(`${endpoint}/login`, { email, password });
}

export async function logout(): Promise<void> {
  return postWithoutResponse(`${endpoint}/logout`, {});
}

export async function register(email: string, password: string): Promise<void> {
  return postWithoutResponse(`${endpoint}/register`, { email, password });
}

export async function confirmEmail(uid: string, token: string): Promise<void> {
  return postWithoutResponse(`${endpoint}/confirmEmail`, { uid, token });
}

export async function getSession(): Promise<SessionResponse> {
  return get<SessionResponse>(`${endpoint}/session`);
}

export async function requestPasswordReset(email: string): Promise<void> {
  return postWithoutResponse(`${endpoint}/requestPasswordReset`, { email });
}

export async function resetPassword(
  userId: string,
  token: string,
  newPassword: string
): Promise<void> {
  return postWithoutResponse(`${endpoint}/resetPassword`, { userId, token, newPassword });
}

export interface SessionResponse {
  email: string;
  tenantName: string;
}

export interface LoginResponse {
  email: string;
  tenantName: string;
}

export interface RegisterResponse {
  email: string;
}
