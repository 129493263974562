import 'moment/locale/fr';

export type SupportedLocales = 'en' | 'fr';
export const allLocales: SupportedLocales[] = ['fr', 'en'];
export const defaultLocale: SupportedLocales = 'en';

// tslint:disable-next-line:interface-name
export interface I18nMessages {
  [locale: string]: {
    [id: string]: string;
  };
}

export const i18nMessages: I18nMessages = {
  // No need to put the english locale here, because it is used as the default language.
  // As such, react-intl will simply output the value of the "defaultMessage" property
  // when referencing the english locale.
  // eslint-disable-next-line global-require
  fr: require('./assets/messages/locales/fr.json')
};
