import * as React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { ApplicationUser } from '../../types';
import * as Api from '../../api';
import useApiErrorHandler from '../../../../utils/ApiErrorHandler';
import { ApplicationState } from '../../../../store';
import * as UsersManagementActions from '../../actions';

export interface ApplicationUserTableRowProps {
  user: ApplicationUser;
}

const m = defineMessages({
  deactiveUser: { id: 'ApplicationUserTableRow.deactiveUser', defaultMessage: 'Deactivate' },
  activeUser: { id: 'ApplicationUserTableRow.activeUser', defaultMessage: 'Reactivate' }
});

export function ApplicationUserTableRow(props: ApplicationUserTableRowProps): JSX.Element {
  const { user } = props;
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleError = useApiErrorHandler();
  const currentUserEmail = useSelector<ApplicationState, string>(
    (state) => state.authentication.authenticatedUserEmail ?? ''
  );
  const roles = useSelector<ApplicationState, string[]>((state) => state.usersManagement.roles);
  const { control } = useForm<ApplicationUser>({
    mode: 'onBlur'
  });

  const updateUserRole = useCallback(
    async (event: SelectChangeEvent<string>) => {
      try {
        setIsLoading(true);
        await Api.updateUserRole(user.email, event.target.value as string);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [user.email, handleError]
  );

  const deactivateUser = useCallback(async () => {
    try {
      setIsLoading(true);
      await Api.deactivateUser(user.email);
      dispatch(UsersManagementActions.deactivateUserSuccess(user.email));
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [user.email, dispatch, handleError]);

  const reactivateUser = useCallback(async () => {
    try {
      setIsLoading(true);
      await Api.reactivateUser(user.email);
      dispatch(UsersManagementActions.reactivateUserSuccess(user.email));
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [user.email, dispatch, handleError]);

  const isUserCurrentUser = currentUserEmail.toLowerCase() === user.email.toLowerCase();

  return (
    <TableRow key={user.email}>
      <TableCell>
        <Typography variant="body1">{user.email}</Typography>
      </TableCell>
      <TableCell>
        {isUserCurrentUser ? (
          user.role
        ) : (
          <Controller
            name="role"
            control={control}
            defaultValue={user.role}
            render={(field) => (
              <Select
                defaultValue={field.field.value}
                disabled={isLoading}
                onChange={updateUserRole}
              >
                {roles.map((r) => (
                  <MenuItem value={r}>{r}</MenuItem>
                ))}
              </Select>
            )}
          />
        )}
      </TableCell>
      <TableCell>
        {!user.isDeactivated && !isUserCurrentUser && (
          <div style={{ width: 130 }}>
            <Button disabled={isLoading} onClick={deactivateUser}>
              {formatMessage(m.deactiveUser)}
            </Button>
          </div>
        )}
        {user.isDeactivated && !isUserCurrentUser && (
          <div style={{ width: 130 }}>
            <Button disabled={isLoading} onClick={reactivateUser}>
              {formatMessage(m.activeUser)}
            </Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}
