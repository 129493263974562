import { LOCATION_CHANGE } from 'react-router-redux';
import { Reducer } from 'redux';

import { RouterActionTypes } from '../../routes';
import { ApiError } from '../../utils/types';
import { BadRequestError } from '../../utils/BadRequestError';
import {
  AuthenticationActionTypes,
  AuthenticationState,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_FAILURE,
  CONFIRM_EMAIL_SUCCESS,
  GET_SESSION_FAILURE,
  GET_SESSION_SUCCESS,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  REGISTER,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  RESET_REGISTRATION_STATE,
  LOGOUT,
  GET_SESSION
} from './types';

const initialState: AuthenticationState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isRegistering: false,
  isRegistrationComplete: false,
  isConfirmingEmail: false,
  isEmailConfirmed: false,
  isAppSuccessfullyLoaded: false,
  isAppLoading: true,
  authenticatedUserEmail: undefined,
  authenticatedUserTenant: undefined,
  authenticationErrors: undefined
};

const authenticationReducer: Reducer<
  AuthenticationState,
  AuthenticationActionTypes | RouterActionTypes
> = (state = initialState, action) => {
  if (action.type !== LOGIN_FAILURE && action.type !== GET_SESSION_FAILURE) {
    if (
      action.payload
      && ((action.payload as any).error instanceof ApiError || action.payload instanceof ApiError)
    ) {
      const error = ((action.payload as any).error || action.payload) as ApiError;
      if (error.statusCode === 401) {
        window.location.pathname = '/login';
      }
    }
  }

  switch (action.type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        authenticationErrors: undefined
      };
    }

    case LOGIN: {
      return {
        ...state,
        isAuthenticating: true,
        authenticationErrors: undefined
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        authenticatedUserEmail: action.payload.email
      };
    }

    case LOGIN_FAILURE: {
      if (action.payload.error instanceof BadRequestError) {
        return {
          ...state,
          isAuthenticating: false,
          authenticationErrors: action.payload.error.modelState
        };
      }
      return {
        ...state,
        isAuthenticating: false
      };
    }

    case LOGOUT: {
      return {
        ...state,
        isAuthenticated: false
      };
    }

    case RESET_REGISTRATION_STATE: {
      return {
        ...state,
        isRegistering: false,
        isRegistrationComplete: false
      };
    }

    case REGISTER: {
      return {
        ...state,
        isRegistering: true,
        registrationErrors: []
      };
    }

    case REGISTER_SUCCESS: {
      return {
        ...state,
        isRegistering: false,
        isRegistrationComplete: true
      };
    }

    case REGISTER_FAILURE: {
      if (action.payload.error instanceof BadRequestError) {
        return {
          ...state,
          isRegistering: false,
          authenticationErrors: action.payload.error.modelState
        };
      }

      return {
        ...state,
        isRegistering: false
      };
    }

    case CONFIRM_EMAIL: {
      return {
        ...state,
        isConfirmingEmail: true,
        authenticationErrors: undefined
      };
    }

    case CONFIRM_EMAIL_SUCCESS: {
      return {
        ...state,
        isConfirmingEmail: false,
        isEmailConfirmed: true
      };
    }

    case CONFIRM_EMAIL_FAILURE: {
      if (action.payload.error instanceof BadRequestError) {
        return {
          ...state,
          isConfirmingEmail: false,
          isEmailConfirmed: false,
          authenticationErrors: action.payload.error.modelState
        };
      }

      return {
        ...state,
        isConfirmingEmail: false,
        isEmailConfirmed: false,
        authenticationErrors: undefined
      };
    }

    case GET_SESSION: {
      return {
        ...state,
        isAppLoading: true,
        isAppSuccessfullyLoaded: false,
        isAuthenticated: true
      };
    }

    case GET_SESSION_SUCCESS: {
      return {
        ...state,
        authenticatedUserEmail: action.payload.user,
        authenticatedUserTenant: action.payload.tenant,
        isAuthenticated: true,
        isAppLoading: false,
        isAppSuccessfullyLoaded: true
      };
    }

    case GET_SESSION_FAILURE: {
      return {
        ...state,
        authenticatedUserEmail: undefined,
        authenticatedUserTenant: undefined,
        isAuthenticated: false,
        isAppLoading: false,
        isAppSuccessfullyLoaded: false
      };
    }

    default:
      return state;
  }
};

export { authenticationReducer };
