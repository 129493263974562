import {
  ApplicationUser,
  CREATE_USER_SUCCESS,
  DEACTIVATE_USER_SUCCESS,
  LOAD_ROLES_SUCCESS,
  LOAD_USERS_SUCCESS,
  REACTIVATE_USER_SUCCESS,
  UsersManagementActionTypes
} from './types';

export function loadUsersSuccess(users: ApplicationUser[]): UsersManagementActionTypes {
  return {
    type: LOAD_USERS_SUCCESS,
    payload: { users }
  };
}

export function loadRolesSuccess(roles: string[]): UsersManagementActionTypes {
  return {
    type: LOAD_ROLES_SUCCESS,
    payload: { roles }
  };
}

export function createUserSuccess(user: ApplicationUser): UsersManagementActionTypes {
  return {
    type: CREATE_USER_SUCCESS,
    payload: { user }
  };
}

export function deactivateUserSuccess(email: string): UsersManagementActionTypes {
  return {
    type: DEACTIVATE_USER_SUCCESS,
    payload: { email }
  };
}

export function reactivateUserSuccess(email: string): UsersManagementActionTypes {
  return {
    type: REACTIVATE_USER_SUCCESS,
    payload: { email }
  };
}
