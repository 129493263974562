import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Asset } from '..';
import { ApplicationState } from '../../../store';
import useApiErrorHandler from '../../../utils/ApiErrorHandler';
import { DefaultPageLayout } from '../../layout/views';
import * as Api from '../api';
import { ProductionMonitoringConfiguration } from '../types';
import { EditAssetForm } from './components';
import ProductionMonitoringConfigurationForm from './components/EditProductionMonitoringConfigurationForm/ProductionMonitoringConfigurationForm';

const m = defineMessages({
  title: { id: 'EditAssetPage.title', defaultMessage: 'Create asset' },
  basicInformationTabTitle: {
    id: 'EditAssetPage.basicInformationTabTitle',
    defaultMessage: 'Basic information'
  },
  productionMonitoringTabTitle: {
    id: 'EditAssetPage.productionMonitoringTabTitle',
    defaultMessage: 'Production monitoring'
  }
});

const EditAssetPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { assetId, activeTab } = useParams();
  const handleError = useApiErrorHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [
    productionMonitoringConfiguration,
    setProductionMonitoringConfiguration
  ] = useState<ProductionMonitoringConfiguration>({
    minimumProductionStopDurationInMinutes: 10,
    machineStateProvider: undefined,
    accelerometerConfigurationModel: undefined,
    mtConnectProviderConfiguration: undefined
  });

  const asset = useSelector<ApplicationState, Asset>((state) => state.assets.byId[Number(assetId)]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const config = await Api.loadProductionMonitoringConfiguration(asset.id);
        setProductionMonitoringConfiguration(config);
      } catch (e) {
        handleError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [asset.id, handleError]);

  if (assetId && !asset) {
    navigate('/not-found');
  }

  return (
    <>
      <DefaultPageLayout>
        <h1>{asset.name}</h1>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={activeTab ?? 'basic-information'}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(event, value) => navigate(`/assets/edit/${asset.id}/${value}`, { replace: true })}
                aria-label="asset tabs"
              >
                <Tab label={formatMessage(m.basicInformationTabTitle)} value="basic-information" />
                <Tab
                  label={formatMessage(m.productionMonitoringTabTitle)}
                  value="production-monitoring"
                />
              </TabList>
            </Box>
            <TabPanel value="basic-information">
              <EditAssetForm asset={asset} />
            </TabPanel>
            <TabPanel value="production-monitoring">
              <ProductionMonitoringConfigurationForm
                asset={asset}
                productionMonitoringConfiguration={productionMonitoringConfiguration}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </DefaultPageLayout>
    </>
  );
};

export { EditAssetPage };
