import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import {
  Backdrop, Box, CircularProgress, Modal, Typography
} from '@mui/material';
import * as api from '../../../api';
import { UtilizationData } from '../../..';
import { UtilizationGranularity } from '../../../types';
import { ChartLegend } from './Legend';
import { UtilizationHeatMapDetailsPopupContent } from './UtilizationHeatMapDetailsPopupContent';
import { commonMessages } from '../../../../../constants';

const m = defineMessages({
  legendTitle: { id: 'UtilizationHeatMap.legendTitle', defaultMessage: 'Utilization' },
  noDataAvailabeForSelectedPeriod: {
    id: 'UtilizationHeatMap.noDataAvailabeForSelectedPeriod',
    defaultMessage: 'There is no data available for the selected period of time.'
  }
});

export interface UtilizationHeatMapProps {
  assetId: number;
  startDate: Date;
  endDate: Date;
  minWidth: number;
}

interface DimensionsState {
  barHeight: number;
  barWidth: number;
  margin: { top: number; right: number; bottom: number; left: number };
  plotWidth: number;
  plotHeight: number;
  width: number;
  height: number;
  xScale: d3.ScaleBand<number>;
  yScale: d3.ScaleTime<number, number>;
}

interface UtilizationModalParameters {
  isModalOpen: boolean;
  startTime: Date;
  endTime: Date;
}

// eslint-disable-next-line no-nested-ternary
const formatHour = (domainValue: number) => (domainValue === 0 ? '12 AM' : domainValue === 12 ? '12 PM' : (domainValue % 12).toString());

const formatDay = (domainValue: Date) => moment(domainValue).startOf('day').format('MMM-DD');

export function UtilizationHeatMap(props: UtilizationHeatMapProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [utilizationData, setUtilizationData] = useState<UtilizationData[]>([]);
  const [dimensions, setDimensions] = useState<DimensionsState>();
  const [modalParams, setModalParams] = useState<UtilizationModalParameters>({
    isModalOpen: false,
    startTime: new Date(),
    endTime: new Date()
  });

  const { formatMessage } = useIntl();

  useEffect(() => {
    async function loadUtilization() {
      setIsLoading(true);
      setHasLoadingError(false);
      await api
        .loadMachineUtilization(
          props.assetId,
          props.startDate,
          props.endDate,
          UtilizationGranularity.Hourly
        )
        .then((utilization) => {
          setUtilizationData(utilization);

          // calculate heatmap dimensions and axis scales
          const defaultBarHeight = 20;
          const defaultBarWidth = 40;
          const margin = {
            top: 40, right: 0, bottom: 0, left: 50
          };
          const plotHeight = defaultBarHeight
            * (moment(props.endDate)
              .startOf('day')
              .diff(moment(props.startDate).startOf('day'), 'days')
              + 1);
          const plotWidth = defaultBarWidth * 24;
          const height = plotHeight + margin.top + margin.bottom;
          const width = plotWidth + margin.left + margin.right;

          const xScale = d3
            .scaleBand<number>()
            .range([0, defaultBarWidth * 24])
            .domain(d3.range(0, 24) as number[]);

          const yScale = d3
            .scaleTime()
            .range([plotHeight, 0])
            .domain([
              moment(props.startDate).startOf('days').add('hours', -12).toDate(),
              moment(props.endDate).startOf('day').add('hours', 12).toDate()
            ]);

          setDimensions({
            barHeight: defaultBarHeight,
            barWidth: defaultBarWidth,
            margin: {
              top: margin.top,
              right: margin.right,
              bottom: margin.bottom,
              left: margin.left
            },
            plotWidth,
            plotHeight,
            width,
            height,
            xScale,
            yScale
          });
        })
        .catch(() => setHasLoadingError(true))
        .finally(() => setIsLoading(false));
    }

    loadUtilization();
  }, [props.assetId, props.startDate, props.endDate]);

  const getUtilizationFillColor = (d: UtilizationData): string | undefined => {
    if (
      d.totalUnknownSeconds > 0
      && d.totalIdleSeconds === 0
      && d.totalRunningSeconds === 0
      && d.totalOffSeconds === 0
    ) {
      return 'lightGrey';
    }

    return d3.scaleSequential(d3.interpolateRdBu)(
      d.totalRunningSeconds
        / (d.totalIdleSeconds + d.totalOffSeconds + d.totalRunningSeconds + d.totalUnknownSeconds)
    );
  };

  return (
    <>
      {!isLoading && !hasLoadingError && dimensions && utilizationData.length > 0 && (
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <div style={{ minWidth: props.minWidth, marginBottom: '10px' }}>
            <svg viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}>
              {/* x-axis */}
              <g
                className="x axis"
                transform={`translate(${dimensions.margin.left + dimensions.barWidth / 2}, ${
                  dimensions.margin.top
                })`}
                fontSize="12"
                textAnchor="middle"
              >
                {dimensions.xScale.domain().map((value, index) => (
                  <g
                    key={value}
                    className="tick"
                    transform={`translate(${dimensions.xScale(value)},0)`}
                  >
                    <line stroke="currentColor" y2="-6" />
                    <text fill="currentColor" y="-9" dy="0em">
                      {formatHour(value)}
                    </text>
                  </g>
                ))}
              </g>
              {/* y axis */}
              <g
                className="y axis"
                transform={`translate(${dimensions.margin.left}, ${dimensions.margin.top})`}
                fontSize="12"
                fontFamily="roboto"
                textAnchor="end"
              >
                {dimensions.yScale.ticks(d3.timeDay).map((value, index) => (
                  <g
                    key={value.toISOString()}
                    className="tick"
                    transform={`translate(0, ${dimensions.yScale(value)})`}
                  >
                    <line stroke="currentColor" x2="-6" />
                    <text fill="currentColor" x="-9" dy="0.32em">
                      {formatDay(value)}
                    </text>
                  </g>
                ))}
              </g>
              {/* heatmap cells */}
              <g
                className="plot"
                transform={`translate(${dimensions.margin.left},${dimensions.margin.top})`}
              >
                <g>
                  {utilizationData.map((d) => (
                    <rect
                      onClick={() => setModalParams({
                        isModalOpen: true,
                        startTime: moment(d.date).toDate(),
                        endTime: moment(d.date).add('hour', 1).toDate()
                      })}
                      key={d.date}
                      x={dimensions.xScale(moment(d.date).hour())}
                      y={dimensions.yScale(moment(d.date).startOf('day'))}
                      className={`x_${moment(d.date).hour()} y_${moment(d.date)
                        .startOf('day')
                        .format('YYYY-MM-DD')}`}
                      width={dimensions.barWidth - 2}
                      height={dimensions.barHeight - 1}
                      transform={`translate(${3},${-(dimensions.barHeight - 2) / 2})`}
                      opacity="1"
                      fill={getUtilizationFillColor(d)}
                      style={{ cursor: 'pointer' }}
                    />
                  ))}
                </g>
              </g>
            </svg>
          </div>
          <Modal
            open={modalParams.isModalOpen}
            onClose={() => setModalParams({ isModalOpen: false, startTime: new Date(), endTime: new Date() })}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
              }}
            >
              <UtilizationHeatMapDetailsPopupContent
                key={modalParams.startTime.toISOString()}
                assetId={props.assetId}
                startTime={modalParams.startTime}
                endTime={modalParams.endTime}
              />
            </Box>
          </Modal>
          <div style={{ minWidth: '280px', width: '40vw' }}>
            <ChartLegend
              key="dataLegend"
              color={d3.scaleSequential(d3.interpolateRdBu)}
              title={formatMessage(m.legendTitle)}
              marginLeft={10}
              tickFormat={(domainValue, index) => `${((domainValue as number) * 10).toFixed(0)}%`}
            />
          </div>
        </div>
      )}

      {!isLoading && !hasLoadingError && utilizationData.length === 0 && (
        <Typography variant="body1">{formatMessage(m.noDataAvailabeForSelectedPeriod)}</Typography>
      )}

      {isLoading && <CircularProgress />}

      {!isLoading && hasLoadingError && (
        <Typography variant="body1">
          {formatMessage(commonMessages.dataFetchErrorFromServer)}
        </Typography>
      )}
    </>
  );
}
