import { createTheme } from '@mui/material';

export const journeyLightTheme = createTheme({
  palette: {
    primary: {
      light: '#3dc6f9',
      main: '#22a6ee',
      dark: '#172da6',
      contrastText: '#FFF'
    }
  }
});
