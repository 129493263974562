import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Box, Button, Container, InputAdornment, Popper, TextField
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { commonMessages } from '../../../../../constants';
import { Asset, ProductionMonitoringConfiguration } from '../../..';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import * as Api from '../../../api';
import { ApplicationState } from '../../../../../store';
import {
  Beacon, Gateway, getBeaconByAssetId, getGatewayById
} from '../../../../devices';
import { LinkButton } from '../../../../../components';
import {
  getMachineStateProviderMessageId,
  MachineStateProvider
} from '../../../../production-monitoring';
import { WorkShiftsCalendar } from '../../../../production-monitoring/views/Components/WorkShiftsCalendar';

export type ProductionMonitoringConfigurationFormProps = {
  asset: Asset;
  productionMonitoringConfiguration: ProductionMonitoringConfiguration;
};

const m = defineMessages({
  minimumProductionStopDurationInMinutesFieldName: {
    id: 'ProductionMonitoringConfigurationForm.minimumProductionStopDurationInMinutesFieldName',
    defaultMessage: 'Min. duration of production stops'
  },
  minimumProductionStopDurationInMinutesFieldTooltip: {
    id: 'ProductionMonitoringConfigurationForm.minimumProductionStopDurationInMinutesFieldTooltip',
    defaultMessage:
      'Minimum idle time until a machine operator is prompted to specify a reason for the production stop through the Journey - Industry 4.0 platform Android application.'
  },
  machineStateProvider: {
    id: 'ProductionMonitoringConfigurationForm.machineStateProvider',
    defaultMessage: 'Data provider'
  },
  associatedBeaconFieldName: {
    id: 'ProductionMonitoringConfigurationForm.associatedBeaconFieldName',
    defaultMessage: 'Associated beacon'
  },
  gatewayFieldName: {
    id: 'ProductionMonitoringConfigurationForm.gatewayFieldName',
    defaultMessage: 'Gateway'
  },
  beaconAlreadySetContactUs: {
    id: 'ProductionMonitoringConfigurationForm.beaconAlreadySetContactUs',
    defaultMessage: 'To change the hardware installation, please <b>contact us</b>.'
  },
  dataProviderNotConfigured: {
    id: 'ProductionMonitoringConfigurationForm.dataProviderNotConfigured',
    defaultMessage:
      'No data provider has been configured for this machine yet. Please <b>contact us</b> to finish the set up.'
  },
  configurationUpdated: {
    id: 'ProductionMonitoringConfigurationForm.configurationUpdated',
    defaultMessage: 'Configuration updated!'
  },
  contactUs: {
    id: 'ProductionMonitoringConfigurationForm.contactUs',
    defaultMessage: 'Contact us'
  }
});

const ProductionMonitoringConfigurationForm = (
  props: ProductionMonitoringConfigurationFormProps
) => {
  const { formatMessage } = useIntl();
  const handleError = useApiErrorHandler();

  const beacon = useSelector<ApplicationState, Beacon | undefined>((state) => getBeaconByAssetId(state, props.asset.id));

  const mtConnectGateway = useSelector<ApplicationState, Gateway | undefined>((state) => getGatewayById(
    state,
    props.productionMonitoringConfiguration.mtConnectProviderConfiguration?.gatewayId
  ));
  const {
    handleSubmit, control, formState, reset
  } = useForm<ProductionMonitoringConfiguration>({
    mode: 'onBlur'
  });

  const updateProductionMonitoringConfiguration = async (
    data: ProductionMonitoringConfiguration
  ) => {
    try {
      await Api.updateProductionMonitoringConfiguration(props.asset.id, data);
      toast.success(formatMessage(m.configurationUpdated), {
        position: 'top-center',
        autoClose: 3000,
        bodyStyle: { textAlign: 'center' }
      });
    } catch (error) {
      handleError(error);
      reset();
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        '& .MuiTextField-root': { m: 1, width: '25ch' }
      }}
      noValidate
      autoComplete="off"
    >
      {/* <Controller
        name="minimumProductionStopDurationInMinutes"
        control={control}
        rules={{
          required: { value: true, message: formatMessage(commonMessages.formFieldRequired) },
          min: {
            value: 5,
            message: formatMessage(commonMessages.formFieldRangeValue, { min: 5, max: 60 })
          },
          max: {
            value: 60,
            message: formatMessage(commonMessages.formFieldRangeValue, { min: 5, max: 60 })
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            type="number"
            label={formatMessage(m.minimumProductionStopDurationInMinutesFieldName)}
            helperText={
              fieldState.error?.message
              ?? formatMessage(m.minimumProductionStopDurationInMinutesFieldTooltip)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formatMessage(commonMessages.timeUnitMinutes)}
                </InputAdornment>
              )
            }}
            error={fieldState.invalid}
            defaultValue={
              props.productionMonitoringConfiguration.minimumProductionStopDurationInMinutes
            }
          />
        )}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label={formatMessage(m.machineStateProvider)}
          defaultValue={formatMessage(
            getMachineStateProviderMessageId(
              props.productionMonitoringConfiguration.machineStateProvider
            )
          )}
          disabled
          helperText={
            props.productionMonitoringConfiguration.machineStateProvider != null
              ? undefined
              : formatMessage(m.dataProviderNotConfigured, {
                b: (text) => (
                  <LinkButton onClick={() => (window as any).Chatra('openChat', true)}>
                    {text}
                  </LinkButton>
                )
              })
          }
        />
        {props.productionMonitoringConfiguration.machineStateProvider
          === MachineStateProvider.Accelerometer && (
          <TextField
            label={formatMessage(m.associatedBeaconFieldName)}
            defaultValue={beacon?.uniqueId}
            disabled
          />
        )}
        {props.productionMonitoringConfiguration.machineStateProvider
          === MachineStateProvider.MTConnect && (
          <TextField
            label={formatMessage(m.gatewayFieldName)}
            defaultValue={mtConnectGateway?.deviceId}
            disabled
          />
        )}
      </Box>
      <Box sx={{ marginTop: '30px ', display: 'flex' }}>
        <LoadingButton
          variant="contained"
          type="button"
          loading={formState.isSubmitting}
          disabled={formState.isSubmitting}
          onClick={() => handleSubmit(updateProductionMonitoringConfiguration)}
        >
          {formatMessage(commonMessages.save)}
        </LoadingButton>
      </Box> */}
      <WorkShiftsCalendar assetId={props.asset.id} />
    </Box>
  );
};

export default ProductionMonitoringConfigurationForm;
