import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ApplicationState } from '../../../store';
import { Asset } from '../../assets';
import { ProductionStopsReport, ViewMachineOverviewPageHeader } from './Components';
import { DefaultPageLayout } from '../../layout/views';
import { UtilizationReport } from './Components/UtilizationReport';

const m = defineMessages({
  utilizationReportTabTitle: {
    id: 'ViewMachineOverviewPage.utilizationReportTabTitle',
    defaultMessage: 'Utilization'
  },
  productionStopsReportTabTitle: {
    id: 'ViewMachineOverviewPage.utilizationReportTabTitle',
    defaultMessage: 'Production Stops'
  }
});

export function ViewMachineOverviewPage() {
  const { assetId, activeTab, date } = useParams();
  const asset = useSelector<ApplicationState, Asset>((p) => p.assets.byId[Number(assetId)]);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (assetId && !asset) {
      navigate('/not-found');
    }
    if (date && !moment(date).isValid()) {
      navigate(`/machines/view/${assetId}/${activeTab}/${moment().toISOString()}`, {
        replace: true
      });
    }
  }, [activeTab, asset, assetId, date, navigate]);

  return (
    <>
      <DefaultPageLayout>
        <ViewMachineOverviewPageHeader asset={asset} />
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={activeTab ?? 'tab-utilization'}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(e, newValue) => navigate(`/machines/view/${assetId}/${newValue}/${date}`)}
                aria-label="basic tabs example"
              >
                <Tab label={formatMessage(m.utilizationReportTabTitle)} value="tab-utilization" />
                <Tab
                  label={formatMessage(m.productionStopsReportTabTitle)}
                  value="tab-production-stops"
                />
              </TabList>
            </Box>
            <TabPanel value="tab-utilization">
              <UtilizationReport
                assetId={Number(assetId)}
                date={date}
              />
            </TabPanel>
            <TabPanel value="tab-production-stops">
              <ProductionStopsReport
                assetId={Number(assetId)}
                date={date}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </DefaultPageLayout>
    </>
  );
}
