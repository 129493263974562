import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ApplicationState } from '../../store';

export interface PrivateRouteProps {
  children: React.ReactChild | React.ReactChild[];
}

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const isAuthenticated = useSelector<ApplicationState, boolean>((state) => state.authentication.isAuthenticated);

  return <>{!isAuthenticated ? <Navigate to="/login" /> : props.children}</>;
};

export { PrivateRoute };
