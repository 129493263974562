import * as React from 'react';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import InputAdornment from '@mui/material/InputAdornment';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  NativeSelect,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { defineMessages, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { range } from 'd3';
import * as Api from '../api';
import { WorkShift } from '../types';
import { commonMessages } from '../../../../../../constants/commonMessages';
import useApiErrorHandler from '../../../../../../utils/ApiErrorHandler';

const m = defineMessages({
  startTimeRequired: {
    id: 'WorkShiftForm.startTimeRequired',
    defaultMessage: 'Start time is required.'
  },
  endTimeRequired: {
    id: 'WorkShiftForm.startTimeRequired',
    defaultMessage: 'End time is required.'
  },
  shiftStart: {
    id: 'WorkShiftForm.shiftStart',
    defaultMessage: 'Shift start'
  },
  shiftEnd: {
    id: 'WorkShiftForm.shiftEnd',
    defaultMessage: 'Shift end'
  },
  dayOfWeek: {
    id: 'WorkShiftForm.dayOfWeek',
    defaultMessage: 'Day of week'
  },
  minutesOfBreakTimeLabel: {
    id: 'WorkShiftForm.minutesOfBreakTimeLabel',
    defaultMessage: 'Break time'
  },
  minutesLabel: {
    id: 'WorkShiftForm.minutesLabel',
    defaultMessage: 'minutes'
  },
  isOptionalLabel: {
    id: 'WorkShiftForm.isOptionalLabel',
    defaultMessage: 'Optional Shift'
  },
  isOptionalHelperText: {
    id: 'WorkShiftForm.isOptionalHelperText',
    defaultMessage:
      'An optional work shift will only contribute to planned production time when production is detected during this shift.'
  },
  createShiftTitle: {
    id: 'WorkShiftForm.createShiftTitle',
    defaultMessage: 'Add Shift'
  },
  editShiftTitle: {
    id: 'WorkShiftForm.editShiftTitle',
    defaultMessage: 'Edit Shift'
  }
});

export interface WorkShiftFormData {
  dayOfWeek: number;
  startTime: Dayjs;
  endTime: Dayjs;
  isOptional: boolean;
  minutesOfBreakTime: number;
}

export interface WorkShiftProps {
  assetId: number;
  workShift: WorkShift | undefined;
  onSaveSuccess: () => void | undefined;
  defaultStartTime: Dayjs | undefined;
  defaultEndTime: Dayjs| undefined;
  defaultDayOfWeek: number | undefined;
}

export function WorkShiftForm(props: WorkShiftProps) {
  const { formatMessage } = useIntl();
  const { control, handleSubmit, setValue } = useForm<WorkShiftFormData>({
    defaultValues: {
      startTime: props.workShift?.startTime ?? props.defaultStartTime ?? dayjs(),
      endTime: props.workShift?.endTime ?? props.defaultEndTime ?? dayjs()
    }
  });

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [dayOfWeek, setDayOfWeek] = useState(
    props.workShift ? dayjs(props.workShift.startTime).day() : props.defaultDayOfWeek
  );

  const [isSaving, setIsSaving] = useState(false);
  const apiErrorHandler = useApiErrorHandler();

  async function saveWorkShift(data: WorkShiftFormData) {
    try {
      console.log(JSON.stringify(data));
      setIsSaving(true);

      const convertedData = {
        ...data,
        startTime: dayjs(data.startTime).set('day', data.dayOfWeek),
        endTime:
          data.endTime > data.startTime
            ? dayjs(data.endTime).set('day', data.dayOfWeek)
            : dayjs(data.endTime)
              .set('day', data.dayOfWeek)
              .add(1, 'day')
      };

      if (props.workShift != null) {
        await Api.updateWorkShift(props.assetId, props.workShift.id, convertedData);
      } else {
        await Api.creatWorkShift(props.assetId, convertedData);
      }

      if (props.onSaveSuccess) {
        props.onSaveSuccess();
      }
    } catch (error: unknown) {
      apiErrorHandler(error);
    } finally {
      setIsSaving(false);
    }
  }

  async function deleteWorkShift() {
    try {
      setIsSaving(true);

      if (props.workShift) {
        await Api.deleteWorkShift(props.workShift.id);
      }
      if (props.onSaveSuccess) {
        props.onSaveSuccess();
      }
    } catch (error: unknown) {
      apiErrorHandler(error);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Box>
      <Container>
        <Typography variant="h5">
          {props.workShift ? formatMessage(m.editShiftTitle) : formatMessage(m.createShiftTitle)}
        </Typography>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' }
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={2} marginTop="20px">
            <FormControl>
              <InputLabel variant="standard" htmlFor="dayOfWeekInput">
                {formatMessage(m.shiftStart)}
              </InputLabel>
              <Controller
                name="dayOfWeek"
                defaultValue={dayOfWeek}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage(m.startTimeRequired)
                  },
                  min: 0,
                  max: 7
                }}
                render={({ field }) => (
                  <NativeSelect
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    inputProps={{
                      name: 'dayOfWeek',
                      id: 'dayOfWeekInput'
                    }}
                    size="small"
                    // label={formatMessage(m.shiftStart)}
                    onChange={(event) => {
                      setValue('dayOfWeek', Number(event.target.value));
                    }}
                  >
                    {range(0, 7)
                      .map((day) => dayjs().subtract(dayjs().day(), 'day').add(day, 'day'))
                      .map((day) => (
                        <option key={day.day()} value={day.day()}>
                          {day.format('dddd')}
                        </option>
                      ))}
                  </NativeSelect>
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage(m.startTimeRequired)
                  }
                }}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label={formatMessage(m.shiftStart)}
                      minutesStep={15}
                       // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      renderInput={(params: any) => <TextField {...params} error={fieldState.error} />}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage(m.endTimeRequired)
                  }
                }}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      minutesStep={15}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      renderInput={(params: any) => <TextField {...params} error={fieldState.error} />}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                name="minutesOfBreakTime"
                control={control}
                defaultValue={props.workShift?.minutesOfBreakTime ?? 60}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage(m.endTimeRequired)
                  }
                }}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    required
                    type="number"
                    defaultValue={field.value}
                    label={formatMessage(m.minutesOfBreakTimeLabel)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formatMessage(m.minutesLabel)}
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                name="isOptional"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ alignSelf: 'start' }}
                    label={formatMessage(m.isOptionalLabel)}
                    control={(
                      <Switch
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        defaultChecked={props.workShift?.isOptional ?? false}
                        color="primary"
                      />
                    )}
                  />
                )}
              />
              <Box maxWidth="25ch">
                <Alert severity="info">{formatMessage(m.isOptionalHelperText)}</Alert>
              </Box>
            </FormControl>

            <LoadingButton
              variant="contained"
              loading={isSaving}
              disabled={isSaving}
              onClick={handleSubmit(saveWorkShift)}
            >
              {formatMessage(commonMessages.save)}
            </LoadingButton>
            {props.workShift && (
              <>
                <LoadingButton
                  color="error"
                  type="button"
                  variant="outlined"
                  loading={isSaving}
                  disabled={isSaving}
                  onClick={() => setIsConfirmDialogOpen(true)}
                >
                  {formatMessage(commonMessages.delete)}
                </LoadingButton>
                <Dialog
                  open={isConfirmDialogOpen}
                  onClose={() => setIsConfirmDialogOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {formatMessage(commonMessages.confirmDeleteTitle)}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {formatMessage(commonMessages.confirmDeleteDescription)}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setIsConfirmDialogOpen(false)}>
                      {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button
                      onClick={() => {
                        setIsConfirmDialogOpen(false);
                        deleteWorkShift();
                      }}
                      autoFocus
                    >
                      {formatMessage(commonMessages.yes)}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
