import {
  all, fork, put, takeLatest
} from 'redux-saga/effects';
import * as actions from './actions';

function* handleLocationChange() {
  try {
    yield put(actions.hideNavigationMenu());
  } catch (e) {
    console.error(e);
  }
}

function* watchLocationChange() {
  yield takeLatest('@@router/LOCATION_CHANGE', handleLocationChange);
}

function* layoutSagas() {
  yield all([fork(watchLocationChange)]);
}

export { layoutSagas };
