import moment from 'moment';
import React, {
  SyntheticEvent, useCallback, useEffect, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import {
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { useResponsiveQueries } from 'utils';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import BarChartIcon from '@mui/icons-material/BarChart';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import style from './UtilizationReport.module.css';
import { UtilizationChart } from './UtilizationChart';
import { AssetProductionMonitoringStatus, UtilizationReportSettings } from '../../..';
import { overviewPageToggleUtilizationChartStatus } from '../../../actions';
import { ApplicationState } from '../../../../../store';
import { UtilizationHeatMap } from '.';

export type UtilizationReportProps = {
  assetId: number;
  date: string | undefined;
};

const m = defineMessages({
  runningTime: { id: 'ViewMachineOverviewPage.runningTime', defaultMessage: "Today's Run Time" },
  statusNotAvailable: { id: 'AssetTileOverview.statusNotAvailable', defaultMessage: 'Unknown' },
  statusStopped: { id: 'AssetTileOverview.statusOffline', defaultMessage: 'Stopped' },
  statusIdle: { id: 'AssetTileOverview.statusIdle', defaultMessage: 'Idle' },
  statusRunning: { id: 'AssetTileOverview.statusRunning', defaultMessage: 'Running' },
  dailyUtilization: { id: 'AssetTileOverview.dailyUtilization', defaultMessage: 'Utilization' }
});

export function UtilizationReport(props: UtilizationReportProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dateFilters, setDateFilters] = useState<{ startDate: Date; endDate: Date }>();
  const [activeChart, setActiveChart] = useState<'BarChart' | 'Heatmap'>('Heatmap');
  const [utilizationReportSettings, setUtilizationReportSettings] = useState([
    'running',
    'idle',
    'off'
  ]);

  const { isMobile, isPortrait } = useResponsiveQueries();

  useEffect(() => {
    let endDate = moment(props.date);
    if (endDate.isAfter(moment())) {
      endDate = moment();
    }

    const startDate = endDate.clone().subtract(6, 'days').toDate();
    setDateFilters({ startDate, endDate: endDate.toDate() });
  }, [props.date]);

  const onDateChange = useCallback(
    (date: Dayjs | null) => {
      if (date?.isValid()) {
        navigate(`/machines/view/${props.assetId}/tab-utilization/${date.format('YYYY-MM-DD')}`);
      }
    },
    [props.assetId, navigate]
  );

  return (
    <>
      <div className={style.utilizationChartPicker}>
        <div style={{ marginBottom: '8px' }}>
          <ToggleButtonGroup
            exclusive
            value={activeChart}
            onChange={(e, chartType) => setActiveChart(chartType)}
          >
            <ToggleButton aria-label="heatmap" value="Heatmap">
              <ViewComfyIcon />
            </ToggleButton>
            <ToggleButton aria-label="barchart" value="BarChart">
              <BarChartIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker<Dayjs>
            value={dayjs(props.date)}
            onChange={(value) => onDateChange(dayjs(value))}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      {activeChart === 'BarChart' && (
        <ToggleButtonGroup
          value={utilizationReportSettings}
          onChange={(e, settings) => setUtilizationReportSettings(settings)}
        >
          <ToggleButton size="small" value="running">
            {intl.formatMessage(m.statusRunning)}
          </ToggleButton>
          <ToggleButton size="small" value="off">
            {intl.formatMessage(m.statusStopped)}
          </ToggleButton>
          <ToggleButton size="small" value="idle">
            {intl.formatMessage(m.statusIdle)}
          </ToggleButton>
          <ToggleButton size="small" value="unavailable">
            {intl.formatMessage(m.statusNotAvailable)}
          </ToggleButton>
        </ToggleButtonGroup>
      )}

      {dateFilters && activeChart === 'BarChart' && (
        <UtilizationChart
          assetId={props.assetId}
          startDate={dateFilters.startDate}
          endDate={dateFilters.endDate}
          minWidth={900}
          showOffTime={utilizationReportSettings.indexOf('off') >= 0}
          showRunningTime={utilizationReportSettings.indexOf('running') >= 0}
          showUnavailableTime={utilizationReportSettings.indexOf('unavailable') >= 0}
          showIdleTime={utilizationReportSettings.indexOf('idle') >= 0}
        />
      )}

      {dateFilters && activeChart === 'Heatmap' && (
        <div style={{ marginTop: '10px' }}>
          <UtilizationHeatMap
            assetId={props.assetId}
            startDate={dateFilters.startDate}
            endDate={dateFilters.endDate}
            minWidth={900}
          />
        </div>
      )}
    </>
  );
}
