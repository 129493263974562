import _ from 'lodash';
import { ApplicationState } from '../../store';
import { Asset } from './types';

export const getAssetById = (state: ApplicationState, assetId: number): Asset => state.assets.byId[assetId];
export const getAllAssets = (state: ApplicationState): Asset[] => Object.values(state.assets.byId);
export const getAllTags = (state: ApplicationState): string[] => _.uniq(Object.values(state.assets.byId).flatMap((p) => p.tags));

export function getAssetCoordinates(
  state: ApplicationState,
  assetId: number
): google.maps.LatLngLiteral | undefined {
  const asset = state.assets.byId[assetId];

  if (asset.nearestGatewayId) {
    const nearbyGateway = state.devices.gatewaysById[asset.nearestGatewayId];

    if (nearbyGateway) {
      if (nearbyGateway.fixedInstallationCoordinate) {
        return nearbyGateway.fixedInstallationCoordinate;
      }
      if (nearbyGateway.installedOnAssetId) {
        const nearbyAsset = state.assets.byId[nearbyGateway.installedOnAssetId];
        if (nearbyAsset && nearbyAsset.coordinates) {
          return nearbyAsset.coordinates;
        }
      }
    }
  }

  return asset.coordinates;
}

export function getTagDropdownOptions(
  state: ApplicationState
): { key: string; text: string; value: string }[] {
  return getAllTags(state).map((tag) => ({
    key: tag,
    text: tag,
    value: tag
  }));
}

export function getDefaultTimezoneId(state: ApplicationState): string | undefined {
  return Object.values(state.assets.byId).length === 0
    ? undefined
    : _.last(Object.values(state.assets.byId))!.timeZoneId;
}
