import * as React from 'react';
import {
  defineMessages, WrappedComponentProps, injectIntl, useIntl
} from 'react-intl';
import { Menu, Sidebar, SidebarProps } from 'semantic-ui-react';

interface NavigationSidebarOwnProps {
  isVisible: boolean;
  children: React.ReactChild | React.ReactChild[];
}

export type NavigationSidebarProps = NavigationSidebarOwnProps &
  SidebarProps &
  WrappedComponentProps;

const m = defineMessages({});

const NavigationSidebar = (props: NavigationSidebarProps) => {
  const { formatMessage } = useIntl();
  const { isVisible, ...sidebarProps } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Sidebar as={Menu} vertical borderless size="huge" {...sidebarProps}>
      {props.children}
    </Sidebar>
  );
};

const intlComponent = injectIntl(NavigationSidebar);
export { intlComponent as NavigationSidebar };
