import * as React from 'react';
import { SemanticICONS, Icon } from 'semantic-ui-react';
import styles from './MarketingTextWithIcon.module.css';

export interface IMarketingTextWithIconProps {
  icon: SemanticICONS;
  text: string;
  size: 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive' | undefined;
}

export function MarketingTextWithIcon(props: IMarketingTextWithIconProps) {
  return (
    <div className={styles.container}>
      <Icon name={props.icon} size={props.size} color="grey" />
      <span className={styles.text}>{props.text}</span>
    </div>
  );
}
