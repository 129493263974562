export const SHOW_NAVIGATION_MENU = 'LAYOUT_SHOW_NAVIGATION_MENU';
export const HIDE_NAVIGATION_MENU = 'LAYOUT_HIDE_NAVIGATION_MENU';

interface ShowNavigationMenuAction {
  type: typeof SHOW_NAVIGATION_MENU;
}

interface HideNavigationMenuAction {
  type: typeof HIDE_NAVIGATION_MENU;
}

export type LayoutActionTypes = ShowNavigationMenuAction | HideNavigationMenuAction;

export interface LayoutState {
  isNavigationMenuVisible: boolean;
}
