import {
  Box,
  makeStyles,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { visuallyHidden } from '@mui/utils';
import { ProductionStop } from '../../..';

const m = defineMessages({
  columnStartTime: { id: 'ProductionStopsTable.columnStartTime', defaultMessage: 'Start Time' },
  columnEndTime: { id: 'ProductionStopsTable.columnEndTime', defaultMessage: 'End Time' },
  columnDuration: { id: 'ProductionStopsTable.columnDuration', defaultMessage: 'Duration' },
  columnReason: { id: 'ProductionStopsTable.columnReason', defaultMessage: 'Reason' },
  notSetProductionStopReason: {
    id: 'ProductionStopsTable.notSetProductionStopReason',
    defaultMessage: 'Not specified'
  }
});

interface IProductionStopsTableProps {
  productionStops: ProductionStop[];
}

function descendingComparator(a: ProductionStop, b: ProductionStop, orderBy: string): number {
  if (orderBy === 'StartTime') {
    return moment(a.startTime).isBefore(b.startTime) ? -1 : 1;
  }

  if (orderBy === 'EndTime') {
    return moment(a.endTime).isBefore(b.endTime) ? -1 : 1;
  }

  if (orderBy === 'Duration') {
    return moment(a.endTime).diff(moment(a.startTime), 'seconds')
      > moment(b.endTime).diff(moment(b.startTime), 'seconds')
      ? 1
      : -1;
  }

  if (orderBy === 'Reason') {
    return (a.reason ?? '') < (b.reason ?? '') ? -1 : 1;
  }

  return 0;
}

function getComparator(order: SortDirection, orderBy: string) {
  return order === 'desc'
    ? (a: ProductionStop, b: ProductionStop) => descendingComparator(a, b, orderBy)
    : (a: ProductionStop, b: ProductionStop) => -descendingComparator(a, b, orderBy);
}

const ProductionStopsTable: React.FunctionComponent<IProductionStopsTableProps> = (props) => {
  const { formatMessage } = useIntl();
  const [productionStopsTableData, setProductionStopsTableData] = useState<ProductionStop[]>(
    props.productionStops
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState<SortDirection>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('StartTime');

  const getHeaderCells = useCallback(
    () => [
      {
        id: 'StartTime',
        numeric: false,
        disablePadding: false,
        label: formatMessage(m.columnStartTime)
      },
      {
        id: 'EndTime',
        numeric: false,
        disablePadding: false,
        label: formatMessage(m.columnEndTime)
      },
      {
        id: 'Duration',
        numeric: false,
        disablePadding: false,
        label: formatMessage(m.columnDuration)
      },
      {
        id: 'Reason', numeric: false, disablePadding: false, label: formatMessage(m.columnReason)
      }
    ],
    [formatMessage]
  );

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ maxWidth: 800 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {getHeaderCells().map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order || undefined : 'asc'}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productionStopsTableData
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((p) => (
                  <TableRow key={p.startTime}>
                    <TableCell>{moment(p.startTime).format('LLL')}</TableCell>
                    <TableCell>{moment(p.endTime).format('LLL')}</TableCell>
                    <TableCell>
                      {moment
                        .duration(moment(p.endTime).diff(moment(p.startTime), 'seconds'), 'seconds')
                        .humanize({ ss: 1 })}
                    </TableCell>
                    <TableCell>{p.reason ?? formatMessage(m.notSetProductionStopReason)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={productionStopsTableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ProductionStopsTable;
