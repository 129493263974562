import * as React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { getStatusTextMessageId, getStatusColor } from '../../helper-functions';
import { AssetProductionMonitoringStatus, UnknownMachineStateReason } from '../..';

export interface IMachineStatusLabelProps {
  machineStatus: AssetProductionMonitoringStatus;
  unknownStatusReason: UnknownMachineStateReason | undefined;
}

export function MachineStatusLabel(props: IMachineStatusLabelProps) {
  const { formatMessage } = useIntl();
  return (
    <Typography variant="h4" sx={{ color: getStatusColor(props.machineStatus) }}>
      {formatMessage(getStatusTextMessageId(props.machineStatus, props.unknownStatusReason))}
    </Typography>
  );
}
