import { BadRequestError } from '../../utils/BadRequestError';
import { ModelState } from '../../utils';

export const LOGIN = 'AUTH_LOGIN';
export const LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const LOGOUT = 'AUTH_LOGOUT';
export const RESET_REGISTRATION_STATE = 'RESET_REGISRATION_STATE';
export const REGISTER = 'AUTH_REGISTER';
export const REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'AUTH_REGISTER_FAILURE';
export const CONFIRM_EMAIL = 'AUTH_CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = 'AUTH_CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'AUTH_CONFIRM_EMAIL_FAILURE';
export const GET_SESSION = 'GET_SESSION';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';

export interface LoginAction {
  type: typeof LOGIN;
  payload: {
    email: string;
    password: string;
  };
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: {
    email: string;
  };
}

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  payload: {
    error: unknown;
  };
}

export interface LogoutAction {
  type: typeof LOGOUT;
  payload: Record<string, never>;
}

export interface ResetRegistrationStateAction {
  type: typeof RESET_REGISTRATION_STATE;
  payload: Record<string, never>;
}

export interface RegisterAction {
  type: typeof REGISTER;
  payload: {
    email: string;
    password: string;
  };
}

export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS;
  payload: {
    email: string;
  };
}

export interface RegisterFailureAction {
  type: typeof REGISTER_FAILURE;
  payload: {
    error: unknown | BadRequestError;
  };
}

export interface ConfirmEmailAction {
  type: typeof CONFIRM_EMAIL;
  payload: {
    uid: string;
    token: string;
  };
}

export interface ConfirmEmailSuccessAction {
  type: typeof CONFIRM_EMAIL_SUCCESS;
  payload: Record<string, never>;
}

export interface ConfirmEmailFailureAction {
  type: typeof CONFIRM_EMAIL_FAILURE;
  payload: {
    error: unknown | BadRequestError;
  };
}

export interface GetSessionAction {
  type: typeof GET_SESSION;
  payload: Record<string, never>;
}

export interface GetSessionSuccessAction {
  type: typeof GET_SESSION_SUCCESS;
  payload: {
    user: string;
    tenant: string;
  };
}

export interface GetSessionFailureAction {
  type: typeof GET_SESSION_FAILURE;
  payload: {
    error: unknown | BadRequestError;
  };
}

export type AuthenticationActionTypes =
  | LoginAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction
  | ResetRegistrationStateAction
  | RegisterAction
  | RegisterSuccessAction
  | RegisterFailureAction
  | ConfirmEmailAction
  | ConfirmEmailSuccessAction
  | ConfirmEmailFailureAction
  | GetSessionAction
  | GetSessionSuccessAction
  | GetSessionFailureAction;

export interface AuthenticationState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isRegistering: boolean;
  isRegistrationComplete: boolean;
  isConfirmingEmail: boolean;
  isEmailConfirmed: boolean;
  isAppSuccessfullyLoaded: boolean;
  isAppLoading: boolean;
  authenticatedUserEmail: string | undefined;
  authenticatedUserTenant: string | undefined;
  authenticationErrors: ModelState | undefined;
}
