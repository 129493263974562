import Clear from '@mui/icons-material/Clear';
import {
  Divider, Drawer, IconButton, styled, Toolbar
} from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as LayoutActions from '../../modules/layout/actions';

export type FixedSidebarProps = {
  onClose?: () => void;
  children?: React.ReactChild | React.ReactChild[];
};

const drawerWidth = '250px';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

// eslint-disable-next-line react/prefer-stateless-function
const FixedSidebar = (props: FixedSidebarProps) => {
  const dispatch = useDispatch();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <DrawerHeader>
        <IconButton onClick={() => dispatch(LayoutActions.showNavigationMenu())} />
        {props.onClose && <Clear onClick={props.onClose} />}
      </DrawerHeader>
      <Divider />
      {props.children}
    </Drawer>
  );
};

FixedSidebar.defaultProps = {
  onClose: () => undefined,
  children: undefined
};

export { FixedSidebar };
