import _ from 'lodash';
import { Reducer } from 'redux';

import {
  DevicesActionTypes,
  DevicesState,
  LOAD_DEVICES_SUCCESS,
  INSTALL_GATEWAY_SUCCESS,
  UNINSTALL_GATEWAY_SUCCESS,
  Beacon,
  Gateway,
  UPDATE_GATEWAY_DISPLAY_NAME,
  UPDATE_GATEWAY_DISPLAY_NAME_SUCCESS,
  UPDATE_GATEWAY_DISPLAY_NAME_FAILURE,
  GATEWAY_CONNECTION_STATE_CHANGED,
  BEACONS_UPDATED
} from './types';

const initialState: DevicesState = {
  gatewaysById: {},
  beaconsById: {}
};

const devicesReducer: Reducer<DevicesState, DevicesActionTypes> = (
  state = initialState,
  action
): DevicesState => {
  switch (action.type) {
    case LOAD_DEVICES_SUCCESS: {
      const beacons = Object.assign(
        {},
        ...action.payload.beacons.map((x: Beacon) => ({ [x.id]: x }))
      );
      const gateways = Object.assign(
        {},
        ...action.payload.gateways.map((x: Gateway) => ({ [x.id]: x }))
      );

      return {
        ...state,
        gatewaysById: _.merge({}, state.gatewaysById, gateways),
        beaconsById: _.merge({}, state.beaconsById, beacons)
      };
    }

    case INSTALL_GATEWAY_SUCCESS: {
      return {
        ...state,
        gatewaysById: {
          ...state.gatewaysById,
          [action.payload.gateway.id]: action.payload.gateway
        }
      };
    }

    case UNINSTALL_GATEWAY_SUCCESS: {
      return {
        ...state,
        gatewaysById: {
          ...state.gatewaysById,
          [action.payload.gateway.id]: action.payload.gateway
        }
      };
    }

    case UPDATE_GATEWAY_DISPLAY_NAME: {
      return {
        ...state,
        gatewaysById: {
          ...state.gatewaysById,
          [action.payload.gatewayId]: {
            ...state.gatewaysById[action.payload.gatewayId],
            isLoading: true
          }
        }
      };
    }

    case UPDATE_GATEWAY_DISPLAY_NAME_SUCCESS: {
      return {
        ...state,
        gatewaysById: {
          ...state.gatewaysById,
          [action.payload.gatewayId]: {
            ...state.gatewaysById[action.payload.gatewayId],
            isLoading: false,
            displayName: action.payload.displayName
          }
        }
      };
    }

    case UPDATE_GATEWAY_DISPLAY_NAME_FAILURE: {
      return {
        ...state,
        gatewaysById: {
          ...state.gatewaysById,
          [action.payload.gatewayId]: {
            ...state.gatewaysById[action.payload.gatewayId],
            isLoading: false
          }
        }
      };
    }

    case GATEWAY_CONNECTION_STATE_CHANGED: {
      return {
        ...state,
        gatewaysById: {
          ...state.gatewaysById,
          [action.payload.gatewayId]: {
            ...state.gatewaysById[action.payload.gatewayId],
            isConnected: action.payload.isConnected
          }
        }
      };
    }

    case BEACONS_UPDATED: {
      const beaconsUpdates = Object.assign(
        {},
        ...action.payload.beacons
          .filter((x) => x.id && state.beaconsById[x.id] !== undefined)
          .map((x) => ({ [x.id!]: x }))
      );

      return {
        ...state,
        beaconsById: _.merge({}, state.beaconsById, beaconsUpdates)
      };
    }

    default:
      return state;
  }
};

export { devicesReducer };
