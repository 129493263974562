import { StringHelper } from '.';

// eslint-disable-next-line max-len
export const EmailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const isEmail = (value: string | undefined): boolean => {
  if (value && StringHelper.hasValue(value)) {
    return EmailRegex.test(value);
  }

  return false;
};
