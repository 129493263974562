import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Container, Typography } from '@mui/material';
import * as AuthenticationActions from '../../modules/authentication/actions';

interface ChatraAccessor {
  Chatra: (method: string, params: boolean) => void;
}

const m = defineMessages({
  title: {
    id: 'ErrorPage.title',
    defaultMessage: 'An error occurred while loading application data'
  },
  messagePart1: { id: 'ErrorPage.messagePart1', defaultMessage: 'You may try reloading the page.' },
  messagePart2: { id: 'ErrorPage.messagePart2', defaultMessage: 'If problem persists please ' },
  messagePart3Link: { id: 'ErrorPage.messagePart3Link', defaultMessage: 'contact us' },
  reload: { id: 'ErrorPage.goHome', defaultMessage: 'Reload page' }
});

export const AppLoadingErrorPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  function handleReloadClicked() {
    dispatch(AuthenticationActions.getSession());
    navigate(-1);
  }

  return (
    <Container sx={{ height: '100vh' }}>
      <Container maxWidth="md">
        <Typography variant="h1" color="red" textAlign="center">
          {intl.formatMessage(m.title)}
        </Typography>
        <Container>
          <p>
            {intl.formatMessage(m.messagePart1)}
            <br />
            {intl.formatMessage(m.messagePart2)}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href={undefined}
              style={{ cursor: 'pointer' }}
              onClick={() => (window as unknown as ChatraAccessor).Chatra('openChat', true)}
            >
              {intl.formatMessage(m.messagePart3Link)}
              .
            </a>
          </p>
          <div>
            <Button variant="contained" onClick={() => handleReloadClicked()}>
              {intl.formatMessage(m.reload)}
            </Button>
          </div>
        </Container>
      </Container>
    </Container>
  );
};
