import _, { omit } from 'lodash';
import { Reducer } from 'redux';

import {
  Asset,
  AssetsActionTypes,
  AssetsState,
  CREATE_ASSET,
  CREATE_ASSET_FAILURE,
  CREATE_ASSET_SUCCESS,
  DELETE_ASSET,
  DELETE_ASSET_FAILURE,
  DELETE_ASSET_SUCCESS,
  LOAD_ASSETS_SUCCESS,
  UPDATE_ASSET,
  UPDATE_ASSET_FAILURE,
  UPDATE_ASSET_SUCCESS,
  MachineStateCalibrationStep,
  MachineCalibrationState
} from './types';
import { BadRequestError } from '../../utils/BadRequestError';

const initialState: AssetsState = {
  byId: {},
  productionMonitoringCalibrationState: {
    assetId: undefined,
    beaconId: undefined,
    sensorDataPoints: [],
    errorCode: undefined,
    errorMessage: undefined
  }
};

const assetsReducer: Reducer<AssetsState, AssetsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ASSETS_SUCCESS: {
      const assets = Object.assign({}, ...action.payload.map((x: Asset) => ({ [x.id]: x })));

      return {
        ...state,
        byId: _.merge({}, state.byId, assets)
      };
    }

    case CREATE_ASSET_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        },
        isSavingAsset: false
      };
    }

    case UPDATE_ASSET_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        },
        isSavingAsset: false
      };
    }

    case UPDATE_ASSET_FAILURE: {
      return {
        ...state,
        isSavingAsset: false
      };
    }

    case DELETE_ASSET: {
      return {
        ...state,
        isDeletingAsset: true
      };
    }

    case DELETE_ASSET_SUCCESS: {
      return {
        ...state,
        byId: omit(state.byId, action.payload.assetId),
        isDeletingAsset: false
      };
    }

    case DELETE_ASSET_FAILURE: {
      return {
        ...state,
        isDeletingAsset: false
      };
    }

    default:
      return state;
  }
};

export { assetsReducer };
