import * as React from 'react';

// eslint-disable-next-line react/prefer-stateless-function
class SidebarAppLogo extends React.Component {
  public render() {
    return (
      <div style={{ margin: '16px 16px 8px 16px', textAlign: 'right', display: 'inline-block' }}>
        <div
          className="color-secondary"
          style={{
            fontFamily: 'Open Sans',
            fontSize: '32px',
            fontWeight: 500,
            lineHeight: '1em',
            letterSpacing: '5px'
          }}
        >
          journey
        </div>
        <div
          style={{
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontWeight: 800,
            letterSpacing: '1px',
            margin: '1em 0.5em 0 0',
            color: 'var(--color-secondary)'
          }}
        >
          by ARIV
        </div>
      </div>
    );
  }
}

export { SidebarAppLogo };
