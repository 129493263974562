/* eslint-disable jsx-a11y/alt-text */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Skeleton, Card } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';

interface ImageProps {
  url: string | undefined;
}

const m = defineMessages({
  noImageSet: { id: 'ImageLoader.noImageSet', defaultMessage: 'No image set.' },
  invalidImage: { id: 'ImageLoader.invalidImage', defaultMessage: 'Could not load image.' }
});

const ImageLoader: FunctionComponent<ImageProps> = (props: ImageProps) => {
  const [loaderState, setLoaderState] = useState<'loading' | 'done' | 'error'>('loading');

  const intl = useIntl();

  useEffect(() => {
    setLoaderState('loading');
  }, [props.url]);

  return (
    <>
      {props.url && (
        <img
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            display: loaderState === 'loading' ? 'none' : 'block'
          }}
          src={props.url}
          onLoad={() => setTimeout(() => setLoaderState('done'), 50)}
          onError={() => setLoaderState('error')}
        />
      )}

      {props.url && loaderState === 'loading' && (
        <Card>
          <Skeleton variant="rectangular" width={290} height={165} />
        </Card>
      )}

      {!props.url && (
        <Card>
          <div
            style={{
              width: '290px',
              height: '165px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <h4>{intl.formatMessage(m.noImageSet)}</h4>
          </div>
        </Card>
      )}

      {loaderState === 'error' && (
        <Card>
          <div
            style={{
              width: '290px',
              height: '165px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <h4>{intl.formatMessage(m.invalidImage)}</h4>
          </div>
        </Card>
      )}
    </>
  );
};

export default ImageLoader;
