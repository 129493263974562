import React, {
  useState, useCallback, SyntheticEvent, useEffect
} from 'react';
import { DropdownProps, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { ApplicationState } from '../../../../store';
import { getTagDropdownOptions } from '../../selectors';

const m = defineMessages({
  placeholder: { id: 'AssetTagsSelector.placeholder', defaultMessage: 'Add new or existing tags.' }
});

interface OwnProps {
  defaultSelectedTags: string[];
  onChange: (tags: string[]) => void;
}

interface StoreProps {
  allTags: { key: string; text: string; value: string }[];
}

export type AssetTagsSelectorProps = OwnProps & StoreProps;

const AssetTagsSelector: React.FunctionComponent<AssetTagsSelectorProps> = (
  props: AssetTagsSelectorProps
) => {
  const [options, setOptions] = useState<{ key?: string; text: string; value: string }[]>(
    props.allTags
  );
  const [currentValues, setCurrentValues] = useState<string[]>([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setCurrentValues(props.defaultSelectedTags ? props.defaultSelectedTags : []);
  }, [props.defaultSelectedTags]);

  const addOption = useCallback(
    (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
      setOptions([{ text: data.value as string, value: data.value as string }, ...options]);
    },
    [options]
  );

  const handleChange = useCallback(
    (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      setCurrentValues(data.value as string[]);
      if (props.onChange) {
        props.onChange(data.value as string[]);
      }
    },
    [props]
  );

  return (
    <Dropdown
      search
      selection
      multiple
      allowAdditions
      options={options}
      width={16}
      fluid
      value={currentValues}
      onAddItem={addOption}
      onChange={handleChange}
      placeholder={formatMessage(m.placeholder)}
    />
  );
};

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  allTags: getTagDropdownOptions(state)
});

const connectedComponent = connect(mapStateToProps)(AssetTagsSelector);
export { connectedComponent as AssetTagsSelector };
