import * as React from 'react';
import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  Box,
  Chip,
  Container,
  Drawer,
  Grid,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableHead,
  useTheme,
  withStyles
} from '@mui/material';
import { Tab, TableCell, TableRow } from 'semantic-ui-react';
import { range } from 'd3';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PauseIcon from '@mui/icons-material/Pause';
import * as Api from './api';
import { WorkShift } from './types';
import { WorkShiftForm } from './Components/WorkShiftForm';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { formatDuration } from '../../../../../utils';

dayjs.extend(localizedFormat);

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid',
  minWidth: '100px',
  borderColor: theme.palette.grey[300],
  zIndex: 100
}));

const TableHeadCell = styled(TableCell)(({ theme }) => ({
  borderLeft: '1px solid',
  borderBottom: '1px solid',
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.grey[300],
  textAlign: 'left',
  padding: '2px',
  fontSize: '12px',
  color: theme.palette.grey[500],
  zIndex: theme.zIndex.appBar + 2
}));

const StickyTableCell = styled(StyledTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  textAlign: 'right',
  left: 0,
  fontSize: '12px',
  position: 'sticky',
  borderLeft: '0',
  width: '100px',
  padding: '4px',
  zIndex: theme.zIndex.appBar + 1
}));

export interface FormDrawerState {
  isOpen: boolean;
  workShift: WorkShift | undefined;
  selectedHour: number | undefined;
  selectedDayOfWeek: number | undefined;
}

export function WorkShiftsCalendar(props: { assetId: number }) {
  const [hoveredShiftId, setHoveredShiftId] = useState<number | undefined>();
  const [drawerState, setDrawerState] = useState<FormDrawerState>({
    isOpen: false,
    workShift: undefined,
    selectedHour: undefined,
    selectedDayOfWeek: undefined
  });

  const [isLoading, setIsLoading] = useState(false);
  const [shifts, setShifts] = useState<WorkShift[]>([]);
  const apiErrorHandler = useApiErrorHandler();
  const theme = useTheme();

  function openFormDrawer(
    workShift: WorkShift | undefined,
    defaultStartHour?: number | undefined,
    defaultDayOfWeek?: number | undefined
  ) {
    setDrawerState({
      isOpen: true,
      workShift,
      selectedHour: defaultStartHour,
      selectedDayOfWeek: defaultDayOfWeek
    });
  }

  async function closeDrawer() {
    setDrawerState({
      isOpen: false,
      workShift: undefined,
      selectedHour: undefined,
      selectedDayOfWeek: undefined
    });
  }

  const refreshShifts = useCallback(async () => {
    try {
      setIsLoading(true);
      const items = await Api.loadAllWorkShifts(props.assetId);
      setShifts(items);
    } catch (error: unknown) {
      apiErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiErrorHandler, props.assetId]);

  React.useEffect(() => {
    async function loadShifts() {
      await refreshShifts();
    }

    loadShifts();
  }, [props.assetId, refreshShifts]);

  const getTotalPlannedProductionTime = useCallback(() => shifts
    .filter((p) => !p.isOptional)
    .map((p) => dayjs(p.endTime).diff(dayjs(p.startTime), 'seconds') - p.minutesOfBreakTime * 60)
    .reduce((prev, curr) => prev + curr, 0), [shifts]);

  const getTotalPlannedOptionalProductionTime = useCallback(() => shifts
    .filter((p) => p.isOptional)
    .map((p) => dayjs(p.endTime).diff(dayjs(p.startTime), 'seconds') - p.minutesOfBreakTime * 60)
    .reduce((prev, curr) => prev + curr, 0), [shifts]);

  const getTotalBreakTimes = useCallback(() => shifts.map((p) => p.minutesOfBreakTime * 60).reduce((prev, curr) => prev + curr, 0), [shifts]);

  const createShiftCell = (dayOfWeek: number, hour: number) => {
    const shift = shifts.find((p) => {
      const startTime = dayjs(p.startTime);
      const endTime = dayjs(p.endTime);

      return (
        ((startTime.day() === dayOfWeek && startTime.hour() <= hour)
          || startTime.day() < dayOfWeek)
        && ((endTime.day() === dayOfWeek && endTime.hour() * 100 + endTime.minute() > hour * 100)
          || endTime.day() > dayOfWeek)
      );
    });

    if (shift === undefined) {
      return (
        <StyledTableCell
          key={`${dayOfWeek}_${hour}`}
          onClick={() => openFormDrawer(undefined, hour, dayOfWeek)}
          sx={{
            ':hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.info.light
            }
          }}
        />
      );
    }

    const startTime = dayjs(shift.startTime);
    const endTime = dayjs(shift.endTime);

    const isStartOfShiftCell = startTime.day() === dayOfWeek && startTime.hour() === hour;
    const isEndOfShiftCell = endTime.day() === dayOfWeek && endTime.hour() === (endTime.minute() > 0 ? hour : hour + 1);

    let percentage = 100;
    let borderRadius = '0';

    if (isStartOfShiftCell && isEndOfShiftCell) {
      percentage = ((60 - startTime.minute()) / 60) * 100;
      borderRadius = '10px 10px 10px 10px';
    } else if (isStartOfShiftCell) {
      percentage = ((60 - startTime.minute()) / 60) * 100;
      borderRadius = '10px 0 0 10px';
    } else if (isEndOfShiftCell) {
      percentage = (endTime.minute() === 0 ? 1 : endTime.minute() / 60) * 100;
      borderRadius = '0 10px 10px 0';
    }

    return (
      <StyledTableCell>
        <Box
          onClick={() => openFormDrawer(shift)}
          marginLeft={isStartOfShiftCell ? 'auto' : '-2px'}
          marginRight={isEndOfShiftCell ? 'auto' : '-2px'}
          zIndex="110"
          width={`calc(${percentage}% + ${isStartOfShiftCell ? '0px' : '2px'} + ${
            isEndOfShiftCell ? '0px' : '2px'
          })`}
          height="20px"
          marginY="auto"
          borderRadius={borderRadius}
          bgcolor={
            // eslint-disable-next-line no-nested-ternary
            hoveredShiftId === shift.id
              ? theme.palette.grey[300]
              : shift.isOptional
                ? theme.palette.info.light
                : theme.palette.info.main
          }
          sx={{ cursor: 'pointer', opacity: shift.isOptional ? 0.5 : 1 }}
          onMouseEnter={() => setHoveredShiftId(shift.id)}
          onMouseLeave={() => setHoveredShiftId(undefined)}
        />
      </StyledTableCell>
    );
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} marginBottom="20px">
        <Chip
          icon={<ScheduleIcon />}
          label={`Planned: ${formatDuration(getTotalPlannedProductionTime())}`}
        />
        <Chip
          icon={<ScheduleIcon />}
          label={`Planned (optional): ${formatDuration(getTotalPlannedOptionalProductionTime())}`}
        />
        <Chip icon={<PauseIcon />} label={`Breaks: ${formatDuration(getTotalBreakTimes())}`} />
      </Stack>
      <Box sx={{ position: 'relative', maxWidth: '90vw', overflowX: 'auto' }}>
        <Table stickyHeader sx={{ position: 'relative' }}>
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ borderLeft: 'none' }} />
              {range(0, 24)
                .map((hour) => dayjs().startOf('day').add(hour, 'hour').format('LT'))
                .map((rowHeader, index) => (
                  <TableHeadCell key={rowHeader}>{rowHeader}</TableHeadCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {range(0, 7).map((dayOfWeek) => (
              <TableRow key={dayOfWeek}>
                <StickyTableCell>{daysOfWeek[dayOfWeek]}</StickyTableCell>
                {range(0, 24).map((hour) => createShiftCell(dayOfWeek, hour))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Drawer anchor="right" open={drawerState.isOpen} onClose={() => closeDrawer()}>
          {drawerState.isOpen && (
            <WorkShiftForm
              workShift={drawerState.workShift}
              onSaveSuccess={() => {
                closeDrawer();
                refreshShifts();
              }}
              defaultStartTime={
                drawerState.selectedHour
                  ? dayjs().startOf('day').add(drawerState.selectedHour, 'hour')
                  : undefined
              }
              defaultEndTime={
                drawerState.selectedHour
                  ? dayjs()
                    .startOf('day')
                    .add(drawerState.selectedHour + 8, 'hour')
                  : undefined
              }
              defaultDayOfWeek={drawerState.selectedDayOfWeek}
              assetId={props.assetId}
            />
          )}
        </Drawer>
      </Box>
    </Box>
  );
}
