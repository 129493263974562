import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { DefaultPageLayout } from '../../layout/views';
import { getBeaconsOrdereredByIsInstalled } from '../selectors';
import { Beacon, Gateway } from '../types';
import { GatewayTableRow } from './components';
import { BeaconTableRow } from './components/BeaconTableRow';

const m = defineMessages({
  title: { id: 'ListAssetsPage.title', defaultMessage: 'Monitoring Equipment' },
  gatewaysTableTitle: { id: 'ListAssetsPage.gatewaysTableTitle', defaultMessage: 'Gateways' },
  columnDeviceIdentifier: {
    id: 'ListAssetsPage.columnDeviceIdentifier',
    defaultMessage: 'Device identifier'
  },
  columnDeviceDisplayName: {
    id: 'ListAssetsPage.columnDeviceDisplayName',
    defaultMessage: 'Friendly name'
  },
  column: { id: 'ListAssetsPage.columnDeviceIdentifier', defaultMessage: 'Device identifier' },
  columnMachine: { id: 'ListAssetsPage.columnMachine', defaultMessage: 'Machine' },
  columnBeaconId: { id: 'ListAssetsPage.columnBeaconId', defaultMessage: 'Beacon Id' },
  columnConnectionStatus: {
    id: 'ListAssetsPage.columnConnectionStatus',
    defaultMessage: 'Connection status'
  },
  columnAssociatedGateway: {
    id: 'ListAssetsPage.columnAssociatedGateway',
    defaultMessage: 'Associated gateway'
  },
  columnInstallationStatus: {
    id: 'ListAssetsPage.columnInstallationStatus',
    defaultMessage: 'Signal quality'
  },
  beaconsTableTitle: { id: 'ListDevicesPage.beaconsTableTitle', defaultMessage: 'Beacons' },
  columnBattery: { id: 'ListAssetsPage.columnInstallationStatus', defaultMessage: 'Battery' }
});

export function ListDevicesPage() {
  const { formatMessage } = useIntl();

  const gateways = useSelector<ApplicationState, Gateway[]>((state) => Object.values(state.devices.gatewaysById));
  const beacons = useSelector<ApplicationState, Beacon[]>((state) => getBeaconsOrdereredByIsInstalled(state));

  return (
    <DefaultPageLayout>
      <Typography variant="h2">{formatMessage(m.title)}</Typography>

      <Typography variant="h3" sx={{ marginTop: '40px' }}>
        {formatMessage(m.gatewaysTableTitle)}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={4} sx={{ fontWeight: 'bold' }}>
                {formatMessage(m.columnDeviceIdentifier)}
              </TableCell>
              <TableCell width={4} sx={{ fontWeight: 'bold' }}>
                {formatMessage(m.columnDeviceDisplayName)}
              </TableCell>
              <TableCell width={4} sx={{ fontWeight: 'bold' }}>
                {formatMessage(m.columnConnectionStatus)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gateways.map((gateway) => (
              <GatewayTableRow key={gateway.id} gateway={gateway} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {beacons.find((p) => p.installedOnAssetId != null) != null && (
        <>
          <Typography variant="h3" sx={{ marginTop: '40px' }}>
            {formatMessage(m.beaconsTableTitle)}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }} width={4}>
                    {formatMessage(m.columnMachine)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} width={4}>
                    {formatMessage(m.columnDeviceIdentifier)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} width={3}>
                    {formatMessage(m.columnConnectionStatus)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} width={3}>
                    {formatMessage(m.columnAssociatedGateway)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} width={3}>
                    {formatMessage(m.columnBattery)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {beacons.map((beacon) => (
                  <BeaconTableRow key={beacon.id} beacon={beacon} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </DefaultPageLayout>
  );
}
