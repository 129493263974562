import moment from 'moment';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { defaultLocale, I18nMessages, SupportedLocales } from '../../configureI18n';

interface I18nProviderConnectedProps {
  locale: SupportedLocales;
}

// tslint:disable-next-line:interface-name
interface I18nProviderOwnProps {
  children: React.ReactNode;
  messages: I18nMessages;
}

export type I18nProviderProps = I18nProviderConnectedProps & I18nProviderOwnProps;

const I18nProvider = (props: I18nProviderProps) => {
  moment.locale(props.locale);

  // English is our default locale throughout the application, so it is assumed that the "defaultMessage" properties
  // on our components contain the actual English message to display. This is the reason why we don't pass the messages
  // property for this locale.
  const messages = props.locale === defaultLocale ? undefined : props.messages[props.locale];

  return (
    <IntlProvider locale={props.locale} messages={messages} key={props.locale}>
      {React.Children.only(props.children)}
    </IntlProvider>
  );
};

export { I18nProvider };
