import { ControllerModeValueType, ExecutionValueType } from '.';
import { BeaconsBatteryUpdated } from '../devices';

export const LOAD_MACHINE_OVERVIEWS = 'LOAD_MACHINE_OVERVIEWS';
export const LOAD_MACHINE_OVERVIEWS_SUCCESS = 'LOAD_MACHINE_OVERVIEWS_SUCCESS';
export const LOAD_MACHINE_OVERVIEWS_FAILURE = 'LOAD_MACHINE_OVERVIEWS_FAILURE';

export const LOAD_MACHINE_OVERVIEW_SUCCESS = 'LOAD_MACHINE_OVERVIEW_SUCCESS';

export const CONFIGURE_MACHINE_STATE_BEACON = 'CONFIGURE_MACHINE_STATE_BEACON';
export const CONFIGURE_MACHINE_STATE_BEACON_SUCCESS = 'CONFIGURE_MACHINE_STATE_BEACON_SUCCESS';
export const CONFIGURE_MACHINE_STATE_BEACON_FAILURE = 'CONFIGURE_MACHINE_STATE_BEACON_FAILURE';

export const UNINSTALL_MACHINE_STATE_BEACON = 'UNINSTALL_MACHINE_STATE_BEACON';
export const UNINSTALL_MACHINE_STATE_BEACON_SUCCESS = 'UNINSTALL_MACHINE_STATE_BEACON_SUCCESS';
export const UNINSTALL_MACHINE_STATE_BEACON_FAILURE = 'UNINSTALL_MACHINE_STATE_BEACON_FAILURE';

export const OVERVIEW_PAGE_TOGGLE_UTILIZATION_CHART_STATUS = 'OVERVIEW_PAGE_TOGGLE_UTILIZATION_CHART_STATUS';

export interface LoadMachineOverviewsAction {
  type: typeof LOAD_MACHINE_OVERVIEWS;
}

export interface LoadMachineOverviewsSuccessAction {
  type: typeof LOAD_MACHINE_OVERVIEWS_SUCCESS;
  payload: MachineOverview[];
}
export interface LoadMachineOverviewsFailureAction {
  type: typeof LOAD_MACHINE_OVERVIEWS_FAILURE;
  payload: { error: unknown };
}

export interface LoadMachineStateSuccessAction {
  type: typeof LOAD_MACHINE_OVERVIEW_SUCCESS;
  payload: MachineOverview;
}

export interface ConfigureMachineStateBeaconAction {
  type: typeof CONFIGURE_MACHINE_STATE_BEACON;
  payload: { assetId: number; beaconId: number };
}
export interface ConfigureMachineStateBeaconSuccessAction {
  type: typeof CONFIGURE_MACHINE_STATE_BEACON_SUCCESS;
  payload: { assetId: number; beaconId: number };
}
export interface ConfigureMachineStateBeaconFailureAction {
  type: typeof CONFIGURE_MACHINE_STATE_BEACON_FAILURE;
  payload: { error: Error };
}

export interface UninstallProductionMonitoringAction {
  type: typeof UNINSTALL_MACHINE_STATE_BEACON;
  payload: { assetId: number };
}
export interface UninstallProductionMonitoringSuccessAction {
  type: typeof UNINSTALL_MACHINE_STATE_BEACON_SUCCESS;
  payload: { assetId: number };
}
export interface UninstallProductionMonitoringFailureAction {
  type: typeof UNINSTALL_MACHINE_STATE_BEACON_FAILURE;
  payload: { error: Error };
}

export interface OverviewPageToggleUtilizationChartStatusAction {
  type: typeof OVERVIEW_PAGE_TOGGLE_UTILIZATION_CHART_STATUS;
  payload: { status: AssetProductionMonitoringStatus };
}

export type ProductionMonitoringActionTypes =
  | LoadMachineOverviewsAction
  | LoadMachineOverviewsSuccessAction
  | LoadMachineOverviewsFailureAction
  | LoadMachineStateSuccessAction
  | ConfigureMachineStateBeaconAction
  | ConfigureMachineStateBeaconSuccessAction
  | ConfigureMachineStateBeaconFailureAction
  | UninstallProductionMonitoringAction
  | UninstallProductionMonitoringSuccessAction
  | UninstallProductionMonitoringFailureAction
  | OverviewPageToggleUtilizationChartStatusAction;

export const SIGNALR_EVENT_GATEWAY_CONNECTION_STATE_CHANGED = 'GatewayConnectionStateChanged';
export const SIGNALR_CONNECTION_CLOSED = 'ConnectionClosed';
export const SIGNALR_CONNECTION_RECONNECTED = 'ConnectionReconnected';

export const SIGNALR_MACHINE_STATE_CHANGED = 'MachineStateChanged';
export const SIGNALR_MACHINE_UTILIZATION_UPDATED = 'MachineUtilizationUpdated';
export const SIGNALR_BEACONS_BATTERY_UPDATED = 'BeaconsBatteryUpdated';
export const SIGNALR_MT_CONNECT_MACHINE_STATE_CHANGED = 'MTConnectMachineStatusChanged';

export interface GatewayConnectionStateChangedSignalREvent {
  event: typeof SIGNALR_EVENT_GATEWAY_CONNECTION_STATE_CHANGED;
  payload: { gatewayId: number; isConnected: boolean };
}
export interface ConnectionCloseSignalREvent {
  event: typeof SIGNALR_CONNECTION_CLOSED;
}
export interface MachineStateChangedSignalREvent {
  event: typeof SIGNALR_MACHINE_STATE_CHANGED;
  payload: MachineOverview;
}
export interface MachineUtilizationUpdatedSignalREvent {
  event: typeof SIGNALR_MACHINE_UTILIZATION_UPDATED;
  payload: MachineOverview;
}
export interface BeaconsBatteryUpdatedSignalREvent {
  event: typeof SIGNALR_BEACONS_BATTERY_UPDATED;
  payload: BeaconsBatteryUpdated;
}
export interface MTConnectMachineStatusChangedSignalREvent {
  event: typeof SIGNALR_MT_CONNECT_MACHINE_STATE_CHANGED;
  payload: BeaconsBatteryUpdated;
}

export type SignalREvents =
  | GatewayConnectionStateChangedSignalREvent
  | ConnectionCloseSignalREvent
  | MachineStateChangedSignalREvent
  | MachineUtilizationUpdatedSignalREvent
  | BeaconsBatteryUpdatedSignalREvent;

export enum AssetProductionMonitoringStatus {
  off = 0,
  idle = 1,
  running = 2,
  unknown = 3,
}

export interface UtilizationData {
  date: string;
  totalOffSeconds: number;
  totalUnknownSeconds: number;
  totalIdleSeconds: number;
  totalRunningSeconds: number;
}

export interface MachineStateInterval {
  startTime: string;
  endTime: number;
  machineStatus: AssetProductionMonitoringStatus;
  assetId: number;
}

export enum UtilizationGranularity {
  Daily = 0,
  Hourly = 1,
}

export interface MachineOverview {
  assetId: number;
  machineStatus: AssetProductionMonitoringStatus;
  asOfDate: string;
  totalOffSeconds: number;
  totalUnknownSeconds: number;
  totalIdleSeconds: number;
  totalRunningSeconds: number;
  totalPlannedSeconds: number;
  unknownStatusReason: UnknownMachineStateReason | undefined;
  machineStateProvider: MachineStateProvider;
  mtConnectDeviceState: MtConnectDeviceState | undefined;
}

export interface MtConnectDeviceState {
  execution: ExecutionValueType;
  controllerMode: ControllerModeValueType;
}

export interface MTConnectStatusUpdate {
  assetId: number;
  machineStatus: AssetProductionMonitoringStatus;
  asOfDate: string;
  totalOffSeconds: number;
  totalUnknownSeconds: number;
  totalIdleSeconds: number;
  totalRunningSeconds: number;
  unknownStatusReason: UnknownMachineStateReason | undefined;
}

export interface AssetTag {
  id: number;
  text: string;
}

export interface UtilizationReportSettings {
  isRunningTimeToggledOn: boolean;
  isIdleTimeToggledOn: boolean;
  isOffTimeToggledOn: boolean;
  isUnavailableTimeToggledOn: boolean;
}

export interface ProductionMonitoringState {
  readonly overviewByAssetId: { [id: number]: MachineOverview };
  readonly utilizationByAssetId: { [id: number]: { [date: string]: UtilizationData } };
  readonly utilizationReportSettings: UtilizationReportSettings;
}

export enum UnknownMachineStateReason {
  ModuleRestarted = 0,
  NoAccelerometerData = 1,
  InsufficientAccelerometerData = 2,
  NoConnectivity = 3,
}

export enum MachineStateProvider {
  Accelerometer = 0,
  MTConnect = 1,
}

export type ProductionStopSummaryByReason = {
  durationTotalSeconds: number;
  averageDurationTotalSeconds: number;
  numberOfProductionStops: number;
  reason: string | undefined;
  builtInReasonCode: string | undefined;
  downtimeReasonId: number | undefined;
};

export type ProductionStop = {
  startTime: string;
  endTime: string;
  assetId: number;
  reason: string | undefined;
  builtInReasonCode: string | undefined;
  downtimeReasonId: string | undefined;
};
