import { Dayjs } from 'dayjs';
import moment from 'moment';
import { get } from '../../utils/api';
import { localTimeFormat } from '../../utils/types';
import {
  MachineOverview,
  MachineStateInterval,
  ProductionStop,
  ProductionStopSummaryByReason,
  UtilizationData,
  UtilizationGranularity
} from './types';

const machineStateEndpoint = '/api/machineState';
const machineReportingEndpoint = '/api/machineReporting';
const productionStopsEndpoint = '/api/productionStops';

export async function loadAllMachineStates(): Promise<MachineOverview[]> {
  return get<MachineOverview[]>(
    `${machineStateEndpoint}?clientTimeZoneOffsetInMinutes=${-new Date().getTimezoneOffset()}`
  );
}

export async function loadMachineOverview(assetId: number): Promise<MachineOverview> {
  return get<MachineOverview>(
    `${machineStateEndpoint}/${assetId}?clientTimeZoneOffsetInMinutes=${-new Date().getTimezoneOffset()}`
  );
}

export async function loadMachineUtilization(
  assetId: number,
  startDate: Date,
  endDate: Date,
  granularity: UtilizationGranularity
): Promise<UtilizationData[]> {
  return get<UtilizationData[]>(
    `${machineReportingEndpoint}/utilization/${assetId}?granularity=${granularity}&startDate=${moment(
      startDate
    ).format(localTimeFormat)}&endDate=${moment(endDate).format(localTimeFormat)}`
  );
}

export async function loadMachineStateIntervals(
  assetId: number,
  startDate: Date,
  endDate: Date
): Promise<MachineStateInterval[]> {
  return get<MachineStateInterval[]>(
    `${machineReportingEndpoint}/machine-states/${assetId}?startDate=${moment(startDate).format(
      localTimeFormat
    )}&endDate=${moment(endDate).format(localTimeFormat)}`
  );
}

export async function loadProductionStopsSummaries(
  assetId: number,
  startTime: Dayjs,
  endTime: Dayjs
): Promise<ProductionStopSummaryByReason[]> {
  return get<ProductionStopSummaryByReason[]>(
    `${productionStopsEndpoint}/summaryByReason/${assetId}?startTime=${startTime.format('YYYY-MM-DD')}&endTime=${endTime.format('YYYY-MM-DD')}`
  );
}

export async function loadProductionStops(
  assetId: number,
  startTime: Dayjs,
  endTime: Dayjs
): Promise<ProductionStop[]> {
  return get<ProductionStop[]>(
    `${productionStopsEndpoint}?machineId=${assetId}&startTime=${startTime.format('YYYY-MM-DD')}&endTime=${endTime.format('YYYY-MM-DD')}`
  );
}
