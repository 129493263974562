import {
  Box, Button, Container, Stack, TextField, Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AppLogo } from '../../../components/logos';
import { commonMessages } from '../../../constants';
import { ModelState, EmailRegex } from '../../../utils';
import useApiErrorHandler from '../../../utils/ApiErrorHandler';
import * as AuthenticationActions from '../actions';
import * as Selectors from '../selectors';

const m = defineMessages({
  emailPlaceholder: { id: 'LoginPage.emailPlaceholder', defaultMessage: 'Email' },
  passwordPlaceholder: { id: 'LoginPage.passwordPlaceholder', defaultMessage: 'Password' },
  title: { id: 'LoginPage.title', defaultMessage: 'Welcome!' },
  instructions: { id: 'LoginPage.instructions', defaultMessage: 'Enter your login information.' },
  haveAnInvitation: {
    id: 'LoginPage.haveAnInvitation',
    defaultMessage: 'Have an invitation? <hyperlink>Register here</hyperlink>.'
  },
  forgotPassword: {
    id: 'LoginPage.forgotPassword',
    defaultMessage: '<hyperlink>I forgot my password</hyperlink>.'
  },
  loginButton: { id: 'LoginPage.loginButton', defaultMessage: 'Login' },
  pageFooter: {
    id: 'LoginPage.pageFooter',
    defaultMessage:
      'ARIV provides simple solutions to organizations facing the Industry 4.0 transformation.'
  },
  emailInvalid: {
    id: 'LoginPage.emailInvalid',
    defaultMessage: 'The email provided is not a valid email address.'
  }
});

export interface LoginForm {
  email: string;
  password: string;
}

export function LoginPage(): JSX.Element {
  const [validationResult, setValidationResult] = useState(new ModelState());

  const {
    handleSubmit, control, formState, reset
  } = useForm<LoginForm>({
    mode: 'onBlur'
  });

  const isAuthenticated = useSelector(Selectors.isAuthenticated);
  const isAuthenticating = useSelector(Selectors.isAuthenticating);
  const authenticationErrors = useSelector(Selectors.authenticationErrors);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { from } = (location.state as any) || { from: { pathname: '/' } };
  const { formatMessage } = useIntl();
  const errorHandler = useApiErrorHandler();

  const handleAuthentication = (data: LoginForm) => {
    if (validationResult.isValid) {
      try {
        dispatch(AuthenticationActions.login(data.email, data.password));
      } catch (e) {
        const modelStateErrors = errorHandler(e);
        if (modelStateErrors) {
          setValidationResult(modelStateErrors);
        }
      }
    }
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(from);
    }
  }, [from, isAuthenticated, navigate]);

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1
        }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Stack direction="row" spacing={20}>
                <AppLogo />

                <Box sx={{ padding: '16px' }}>
                  <Typography variant="h3">{formatMessage(m.title)}</Typography>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '25ch' }
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Stack>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: formatMessage(commonMessages.formFieldRequired)
                          },
                          pattern: {
                            value: EmailRegex,
                            message: formatMessage(m.emailInvalid)
                          }
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            label={formatMessage(m.emailPlaceholder)}
                            error={fieldState.error != null}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: formatMessage(commonMessages.formFieldRequired)
                          }
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            type="password"
                            label={formatMessage(m.passwordPlaceholder)}
                            error={fieldState.error != null}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Stack>
                    <Stack sx={{ marginLeft: '8px' }}>
                      <Typography variant="body1">
                        {formatMessage(m.forgotPassword, {
                          hyperlink: (p) => <NavLink to="/requestResetPassword">{p}</NavLink>
                        })}
                      </Typography>
                      <Box marginTop="30px">
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          name="submitButton"
                          loading={isAuthenticating}
                          disabled={isAuthenticating}
                          sx={{ marginBottom: '8px' }}
                          onClick={handleSubmit(handleAuthentication)}
                        >
                          {formatMessage(m.loginButton)}
                        </LoadingButton>
                      </Box>

                      {authenticationErrors && (
                        <Box>
                          <Alert severity="error">
                            {authenticationErrors
                              .localizeErrorMessages(formatMessage)
                              .reduce((a, b) => `${a}\n${b}`)}
                          </Alert>
                        </Box>
                      )}

                      <Typography variant="body1">
                        {formatMessage(m.haveAnInvitation, {
                          hyperlink: (p) => <NavLink to="/register">{p}</NavLink>
                        })}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            bgcolor: 'primary.main',
            flexGrow: 1,
            alignSelf: 'flex-end',
            maxHeight: '250px',
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            marginTop: '16px',
            padding: '8px'
          }}
        >
          <Typography variant="h5" sx={{ textAlign: 'center', margin: '0 auto', color: '#FFF' }}>
            {formatMessage(m.pageFooter)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
