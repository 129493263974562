import { LoadingButton } from '@mui/lab';
import {
  Alert, Box, Button, Container, Stack, TextField, Typography
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  defineMessages, WrappedComponentProps, injectIntl, useIntl
} from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AppLogo } from '../../../components/logos';
import { commonMessages } from '../../../constants/commonMessages';
import { ApplicationState } from '../../../store';
import {
  nameof, ModelState, isEmail, EmailRegex
} from '../../../utils';
import useApiErrorHandler, { ErrorHandlingStrategy } from '../../../utils/ApiErrorHandler';
import * as Api from '../api';

interface RegisterForm {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
}

const m = defineMessages({
  title: { id: 'RegisterPage.title', defaultMessage: 'Register your account' },
  registrationCompleteTitle: {
    id: 'RegisterPage.registrationCompleteTitle',
    defaultMessage: 'Verify your email'
  },
  checkYourEmail: {
    id: 'RegisterPage.checkYourEmail',
    defaultMessage:
      'We sent you an email to confirm your registration. Click the link inside it to activate your account.'
  },
  emailPlaceholder: { id: 'RegisterPage.emailPlaceholder', defaultMessage: 'Enter email' },
  passwordPlaceholder: { id: 'RegisterPage.passwordPlaceholder', defaultMessage: 'Enter password' },
  confirmPasswordPlaceholder: {
    id: 'RegisterPage.confirmPasswordPlaceholder',
    defaultMessage: 'Confirm password'
  },
  register: { id: 'RegisterPage.register', defaultMessage: 'Register' },
  pageFooter: {
    id: 'RegisterPage.pageFooter',
    defaultMessage:
      'ARIV provides simple solutions to organizations facing the Industry 4.0 transformation.'
  },
  emailInvalid: {
    id: 'LoginPage.emailInvalid',
    defaultMessage: 'The email provided is not a valid email address.'
  }
});

const RegisterPage = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const errorHandler = useApiErrorHandler();
  const {
    handleSubmit, control, formState, reset, getValues
  } = useForm<RegisterForm>({
    mode: 'onBlur'
  });

  const { formatMessage } = useIntl();

  const register = async (form: RegisterForm) => {
    try {
      setIsLoading(true);

      await Api.register(form.email, form.newPassword);
      setIsRegistered(true);
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1
      }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Stack direction="row" spacing={20}>
              <AppLogo />

              <Box sx={{ padding: '16px' }}>
                {isRegistered && (
                  <>
                    <Typography variant="h5">
                      {formatMessage(m.registrationCompleteTitle)}
                    </Typography>
                    <Typography variant="body1">{formatMessage(m.checkYourEmail)}</Typography>
                  </>
                )}
                {!isRegistered && (
                  <>
                    <Typography variant="h4">{formatMessage(m.title)}</Typography>
                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Stack>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: formatMessage(commonMessages.formFieldRequired)
                            },
                            pattern: {
                              value: EmailRegex,
                              message: formatMessage(m.emailInvalid)
                            }
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              label={formatMessage(m.emailPlaceholder)}
                              error={fieldState.error != null}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />

                        <Controller
                          name="newPassword"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: formatMessage(commonMessages.formFieldRequired)
                            }
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              type="password"
                              label={formatMessage(m.passwordPlaceholder)}
                              error={fieldState.error != null}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />

                        <Controller
                          name="confirmNewPassword"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: formatMessage(commonMessages.formFieldRequired)
                            },
                            validate: {
                              matchesPassword: () => getValues('newPassword') === getValues('confirmNewPassword')
                            }
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              type="password"
                              label={formatMessage(m.confirmPasswordPlaceholder)}
                              error={fieldState.error != null}
                              helperText={
                                fieldState.error?.type === 'matchesPassword'
                                  ? 'Passwords must match'
                                  : fieldState.error?.message
                              }
                            />
                          )}
                        />
                      </Stack>

                      <LoadingButton
                        variant="contained"
                        type="submit"
                        name="submitButton"
                        loading={isLoading}
                        disabled={isLoading}
                        sx={{ marginBottom: '8px' }}
                        onClick={handleSubmit(register)}
                      >
                        {formatMessage(m.register)}
                      </LoadingButton>
                    </Box>
                  </>
                )}
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
      <div
        className="bg-primary"
        style={{
          minHeight: '250px',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: '16px',
          padding: '8px'
        }}
      >
        <h3 className="color-white" style={{ textAlign: 'center', margin: '0 auto' }}>
          {formatMessage(m.pageFooter)}
        </h3>
      </div>
    </Box>
  );
};

export { RegisterPage };
