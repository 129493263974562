import { BadRequestError } from './BadRequestError';
import { ModelState } from '.';
import { ApiError } from './types';

const baseConfiguration: RequestInit = {
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
  }
};

async function fetchRequestWithoutResponse(
  requestUrl: string,
  requestConfiguration: RequestInit
): Promise<Response> {
  const response = await fetch(requestUrl, requestConfiguration);

  if (response.ok) {
    return response;
  }

  if (response.status === 400) {
    const modelState = await ModelState.FromResponse(response);
    return Promise.reject(
      new BadRequestError(modelState, `${response.status} ${response.statusText}`)
    );
  }

  return Promise.reject(new ApiError(response.status, `${response.status} ${response.statusText}`));
}

async function fetchRequest<TResponse>(
  requestUrl: string,
  requestConfiguration: RequestInit
): Promise<TResponse> {
  const response = await fetchRequestWithoutResponse(requestUrl, requestConfiguration);
  return response.json() as Promise<TResponse>;
}

export async function get<TResponse>(requestUrl: string): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(requestUrl, {
    ...baseConfiguration,
    method: 'GET'
  });

  return result;
}

export async function post<TResponse>(
  requestUrl: string,
  body: any | undefined
): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(requestUrl, {
    ...baseConfiguration,
    method: 'POST',
    body: body != null ? JSON.stringify(body) : undefined
  });

  return result;
}

export async function postWithoutResponse(
  requestUrl: string,
  body: any | undefined
): Promise<void> {
  await fetchRequestWithoutResponse(requestUrl, {
    ...baseConfiguration,
    method: 'POST',
    body: body != null ? JSON.stringify(body) : undefined
  });
}

export async function postFormData<TResponse>(
  requestUrl: string,
  formData: FormData
): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(requestUrl, {
    ...baseConfiguration,
    method: 'POST',
    body: formData,
    headers: undefined
  });

  return result;
}

export async function putFormData<TResponse>(
  requestUrl: string,
  formData: FormData
): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(requestUrl, {
    ...baseConfiguration,
    method: 'PUT',
    body: formData,
    headers: undefined
  });

  return result;
}

export async function put<TResponse>(
  requestUrl: string,
  body: any | undefined
): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(requestUrl, {
    ...baseConfiguration,
    method: 'PUT',
    body: body != null ? JSON.stringify(body) : undefined
  });

  return result;
}

export async function putWithoutResponse(requestUrl: string, body: any | undefined): Promise<void> {
  await fetchRequestWithoutResponse(requestUrl, {
    ...baseConfiguration,
    method: 'PUT',
    body: body != null ? JSON.stringify(body) : undefined
  });
}

export async function del(requestUrl: string): Promise<void> {
  await fetchRequestWithoutResponse(requestUrl, {
    ...baseConfiguration,
    method: 'DELETE'
  });
}

export interface BadRequestErrorModel {
  errors: ModelState;
}
