import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Button, Typography } from '@mui/material';
import BugIcon from '@mui/icons-material/BugReport';
import { useNavigate, useLocation } from 'react-router-dom';

const m = defineMessages({
  title: { id: 'NotFoundPage.title', defaultMessage: 'Error 404 - Page not found' },
  subtitle: {
    id: 'NotFoundPage.subtitle',
    defaultMessage: "The requested page doesn't exist anymore or has been moved."
  },
  goBackButton: { id: 'NotFound.goBackButton', defaultMessage: 'Go back' },
  homepageButton: { id: 'NotFound.homepageButton', defaultMessage: 'Homepage' },
  contactUsButton: { id: 'NotFound.contactUsButton', defaultMessage: 'Contact us' },
  notFoundMessage: {
    id: 'NotFound.notFoundMessage',
    defaultMessage:
      'The page you are looking for could not be found. '
      + 'You can either return to the previous page, go back to the homepage or contact the support team.'
  }
});

const NotFoundPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <BugIcon />
      <Typography variant="h1">{formatMessage(m.title)}</Typography>
      <Typography variant="h2">{formatMessage(m.subtitle)}</Typography>
      <p>{formatMessage(m.notFoundMessage)}</p>
      <Button variant="contained" onClick={() => navigate(-1)}>
        {formatMessage(m.goBackButton)}
      </Button>
      <Button variant="outlined" onClick={() => navigate('/', { replace: true })}>
        {formatMessage(m.homepageButton)}
      </Button>
      <Button variant="outlined" href={`mailto:support@ariv.ca?subject=Erreur 404 (${location.pathname})`}>
        {formatMessage(m.contactUsButton)}
      </Button>
    </>
  );
};

export { NotFoundPage };
