import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  AppLoadingErrorPage, AppLoadingScreen, NotFoundPage, PrivateRoute
} from './components';
import { CreateAssetPage, EditAssetPage, ListAssetsPage } from './modules/assets';
import {
  ConfirmEmailPage,
  LoginPage,
  RegisterPage,
  RequestResetPasswordPage
} from './modules/authentication';
import { ResetPasswordPage } from './modules/authentication/views/ResetPassword';
import { ListDevicesPage } from './modules/devices';
import { ViewMachineOverviewPage } from './modules/production-monitoring/views';
import { UsersManagementPage } from './modules/users-management';
import { ApplicationState } from './store';

interface StoreProps {
  isAuthenticated: boolean;
  isAppLoading: boolean;
}

export type RoutesProps = StoreProps;

interface LocationChangeAction {
  type: typeof LOCATION_CHANGE;
  payload: Location & {
    props?:
      | {
          match: {
            path: string;
            url: string;
            params: any;
            isExact: boolean;
          };
          location: Location;
          history: History;
        }
      | undefined;
  };
}

export type RouterActionTypes = LocationChangeAction;

const RouteConfiguration = () => {
  const { isAppLoading } = useSelector<ApplicationState, StoreProps>((state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    isAppLoading: state.authentication.isAppLoading
  }));
  return (
    <>
      {!isAppLoading ? (
        <Routes>
          {/* <PrivateRoute exact={true} path="/map" component={AssetsMapPage} /> */}
          <Route
            path="/assets"
            element={(
              <PrivateRoute>
                <ListAssetsPage />
              </PrivateRoute>
            )}
          />
          <Route
            path="/assets/create"
            element={(
              <PrivateRoute>
                <CreateAssetPage />
              </PrivateRoute>
            )}
          />
          <Route
            path="/assets/edit/:assetId"
            element={(
              <PrivateRoute>
                <EditAssetPage />
              </PrivateRoute>
            )}
          />
          <Route
            path="/assets/edit/:assetId/:activeTab"
            element={(
              <PrivateRoute>
                <EditAssetPage />
              </PrivateRoute>
            )}
          />
          <Route
            path="/machines/view/:assetId/"
            element={(
              <PrivateRoute>
                <ViewMachineOverviewPage />
              </PrivateRoute>
            )}
          />
          <Route
            path="/machines/view/:assetId/:activeTab"
            element={(
              <PrivateRoute>
                <ViewMachineOverviewPage />
              </PrivateRoute>
            )}
          />
          <Route
            path="/machines/view/:assetId/:activeTab/:date"
            element={(
              <PrivateRoute>
                <ViewMachineOverviewPage />
              </PrivateRoute>
            )}
          />
          <Route
            path="/devices/"
            element={(
              <PrivateRoute>
                <ListDevicesPage />
              </PrivateRoute>
            )}
          />
          <Route
            path="/users/"
            element={(
              <PrivateRoute>
                <UsersManagementPage />
              </PrivateRoute>
            )}
          />
          {/* <PrivateRoute exact={true} path="/locationHistory/:assetId/:date?" component={LocationHistoryPage} /> */}
          {/* <PrivateRoute exact={true} path="/configure/:place?" component={PlacesConfigurationPage} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/confirmEmail" element={<ConfirmEmailPage />} />
          <Route path="/requestResetPassword" element={<RequestResetPasswordPage />} />
          <Route path="/resetPassword" element={<ResetPasswordPage />} />
          <Route path="/errors/load-data-error" element={<AppLoadingErrorPage />} />
          <Route path="/" element={<Navigate to="/assets" />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      ) : (
        <AppLoadingScreen />
      )}
    </>
  );
};

export { RouteConfiguration };
