import _ from 'lodash';
import { ApplicationState } from '../../store';
import { ApplicationUser } from './types';

export const getAllUsers = (state: ApplicationState): ApplicationUser[] => Object.values(state);

export const activeUsers = (state: ApplicationState) => _.sortBy(
  Object.values(state.usersManagement.usersByEmail).filter((p) => !p.isDeactivated),
  (p) => p.email
);
export const inactiveUsers = (state: ApplicationState) => _.sortBy(
  Object.values(state.usersManagement.usersByEmail).filter((p) => p.isDeactivated),
  (p) => p.email
);
