import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { AppLogo } from '../logos';

export const AppLoadingScreen = (): JSX.Element => (
  <>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <AppLogo />
      <div style={{ marginTop: '30px' }}>
        <CircularProgress variant="indeterminate" size={100} />
      </div>
    </div>
  </>
);
