import { Box } from '@mui/material';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { getAllAssets } from '..';
import { ApplicationState } from '../../../store';
import { DefaultPageLayout } from '../../layout/views';
import { MachineOverview } from '../../production-monitoring';
import { Asset } from '../types';
import { AssetTileOverview, CreateFirstAssetCallToAction } from './components';

interface StoreProps {
  assets: Asset[];
}

export type ListAssetsPageProps = StoreProps;

const m = defineMessages({
  title: { id: 'ListAssetsPage.title', defaultMessage: 'Assets' },
  createAsset: { id: 'ListAssetsPage.createAsset', defaultMessage: 'Create asset' },
  configure: { id: 'ListAssetsPage.configure', defaultMessage: 'Configure' },
  locate: { id: 'ListAssetsPage.locate', defaultMessage: 'Locate' },
  columnName: { id: 'ListAssetsPage.columnName', defaultMessage: 'Name' },
  columnDescription: { id: 'ListAssetsPage.columnDescription', defaultMessage: 'Description' },
  columnLocation: { id: 'ListAssetsPage.columnLocation', defaultMessage: 'Location' },
  columnActions: { id: 'ListAssetsPage.columnActions', defaultMessage: 'Actions' },
  machinesStatisticLabel: {
    id: 'ListAssetsPage.machinesStatisticLabel',
    defaultMessage: 'Machines'
  }
});

const ListAssetsPage: FunctionComponent<ListAssetsPageProps> = (props: ListAssetsPageProps) => (
  <DefaultPageLayout>
    {props.assets.length === 0 && <CreateFirstAssetCallToAction />}
    {props.assets.length > 0 && (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {props.assets.map((asset) => (
          <AssetTileOverview asset={asset} key={asset.id} />
        ))}
      </Box>
    </>
    )}
  </DefaultPageLayout>
);

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  assets: getAllAssets(state)
});

const connectedComponent = connect(mapStateToProps)(ListAssetsPage);
export { connectedComponent as ListAssetsPage };
