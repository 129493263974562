import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Divider, DividerProps } from '@mui/material';

export type OrDividerProps = DividerProps & WrappedComponentProps;

const m = defineMessages({
  or: { id: 'OrDivider.or', defaultMessage: 'Or' }
});

// eslint-disable-next-line react/prefer-stateless-function
class OrDivider extends React.Component<OrDividerProps> {
  public render() {
    const { formatMessage } = this.props.intl;

    return (
      <Divider>
        <span style={{ textTransform: 'uppercase' }}>{formatMessage(m.or)}</span>
      </Divider>
    );
  }
}

const intlComponent = injectIntl(OrDivider);
export { intlComponent as OrDivider };
