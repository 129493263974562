import { push } from 'react-router-redux';
import {
  all, fork, put, race, select, take, takeEvery, takeLatest
} from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import * as actions from './actions';
import * as api from './api';
import { getAssetById, getAssetCoordinates } from './selectors';
import {
  Asset,
  DELETE_ASSET,
  DeleteAction,
  LOAD_ASSETS,
  LOAD_ASSETS_FAILURE,
  LOAD_ASSETS_SUCCESS
} from './types';
import { ApplicationState } from '../../store';

function* handleLoadAll() {
  try {
    const result = (yield call(api.loadAll)) as Asset[];
    yield put(actions.loadAllSuccess(result));
  } catch (e) {
    yield put(actions.loadAllFailure(e));
  }
}

function* handleRemove(action: DeleteAction) {
  try {
    yield call(api.remove, action.payload.assetId);
    yield put(actions.removeSuccess(action.payload.assetId));
    yield put(push('/assets'));
  } catch (e) {
    yield put(actions.removeFailure(e));
  }
}

function* watchLoadAll() {
  yield takeLatest(LOAD_ASSETS, handleLoadAll);
}
function* watchRemove() {
  yield takeEvery(DELETE_ASSET, handleRemove);
}

function* assetsSagas() {
  yield all([
    fork(watchLoadAll),
    fork(watchRemove)
  ]);
}

export { assetsSagas };
