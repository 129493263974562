import { over } from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';
import {
  AssetProductionMonitoringStatus,
  commonMessages,
  MachineOverview,
  MachineStateProvider,
  UnknownMachineStateReason
} from '.';

export function getMachineStateProviderMessageId(
  machineStateProvider: MachineStateProvider | undefined
): MessageDescriptor {
  switch (machineStateProvider) {
    case MachineStateProvider.Accelerometer:
      return commonMessages.machineStateProviderAccelerometer;
    case MachineStateProvider.MTConnect:
      return commonMessages.machineStateProviderMTConnect;
    default:
      return commonMessages.machineStateProviderNotConfigured;
  }
}

export function getStatusTextMessageId(
  productionMonitoringState: AssetProductionMonitoringStatus | undefined,
  unknownStatusReason: UnknownMachineStateReason | undefined
): MessageDescriptor {
  switch (productionMonitoringState) {
    case AssetProductionMonitoringStatus.idle:
      return commonMessages.statusIdle;
    case AssetProductionMonitoringStatus.off:
      return commonMessages.statusStopped;
    case AssetProductionMonitoringStatus.running:
      return commonMessages.statusRunning;
    case AssetProductionMonitoringStatus.unknown:
      return unknownStatusReason === UnknownMachineStateReason.NoConnectivity
        ? commonMessages.statusOffline
        : commonMessages.statusNotAvailable;
    default:
      return commonMessages.statusNotAvailable;
  }
}

export const calculateUtilization = (overview: MachineOverview): number => {
  const totalTime = overview.totalIdleSeconds
    + overview.totalOffSeconds
    + overview.totalRunningSeconds
    + overview.totalUnknownSeconds;
  if (totalTime === 0) {
    return 0;
  }

  if (overview.totalPlannedSeconds > overview.totalRunningSeconds) {
    return (overview.totalRunningSeconds / overview.totalPlannedSeconds) * 100;
  }

  return (overview.totalRunningSeconds / totalTime) * 100;
};

export const getStatusColor = (status: AssetProductionMonitoringStatus | undefined): string => {
  switch (status) {
    case AssetProductionMonitoringStatus.unknown:
      return 'text.disabled';
    case AssetProductionMonitoringStatus.off:
      return 'error.light';
    case AssetProductionMonitoringStatus.idle:
      return 'warning.light';
    case AssetProductionMonitoringStatus.running:
      return 'success.light';
    default:
      return 'text.disabled';
  }
};
