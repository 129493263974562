import _ from 'lodash';
import { Reducer } from 'redux';
import { LayoutState, SHOW_NAVIGATION_MENU, HIDE_NAVIGATION_MENU } from './types';

const initialState: LayoutState = {
  isNavigationMenuVisible: false
};

const layoutReducer: Reducer<LayoutState> = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NAVIGATION_MENU: {
      return {
        ...state,
        isNavigationMenuVisible: true
      };
    }

    case HIDE_NAVIGATION_MENU: {
      return {
        ...state,
        isNavigationMenuVisible: false
      };
    }

    default:
      return state;
  }
};

export { layoutReducer };
