import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DefaultPageLayout } from '../../layout/views';
import { ApplicationUserTableRow } from './components/ApplicationUserTableRow';
import { ApplicationUserNewTableRow } from './components/ApplicationUserNewTableRow';
import { ApplicationUser } from '..';
import * as Api from '../api';

import useApiErrorHandler from '../../../utils/ApiErrorHandler';
import * as UsersManagementActions from '../actions';
import * as Selectors from '../selectors';

const m = defineMessages({
  title: { id: 'UsersManagementPage.title', defaultMessage: 'Users management' },
  activeUsersTitle: { id: 'UsersManagementPage.activeUsersTitle', defaultMessage: 'Active' },
  inactiveUsersTitle: { id: 'UsersManagementPage.inactiveUsersTitle', defaultMessage: 'Inactive' },
  emailColumnName: { id: 'UsersManagementPage.emailColumnName', defaultMessage: 'User email' },
  roleColumnName: { id: 'UsersManagementPage.roleColumnName', defaultMessage: 'Role' }
});

export function UsersManagementPage() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const activeUsers = useSelector(Selectors.activeUsers);
  const inactiveUsers = useSelector(Selectors.inactiveUsers);
  const [activeTab, setActiveTab] = useState('tab-active-users');
  const handleError = useApiErrorHandler();

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const users = await Api.getUsers();
        const roles = await Api.getRoles();
        dispatch(UsersManagementActions.loadUsersSuccess(users));
        dispatch(UsersManagementActions.loadRolesSuccess(roles));
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [dispatch, handleError]);

  const renderUsersTable = (users: ApplicationUser[], showCreateUserRow: boolean): JSX.Element => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{formatMessage(m.emailColumnName)}</TableCell>
            <TableCell width={5}>{formatMessage(m.roleColumnName)}</TableCell>
            <TableCell width={3} />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <ApplicationUserTableRow key={user.email} user={user} />
          ))}
          {showCreateUserRow && <ApplicationUserNewTableRow />}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <DefaultPageLayout>
      <Typography variant="h2">{formatMessage(m.title)}</Typography>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(e, newValue) => setActiveTab(newValue)}
              aria-label="basic tabs example"
            >
              <Tab label={formatMessage(m.activeUsersTitle)} value="tab-active-users" />
              <Tab label={formatMessage(m.inactiveUsersTitle)} value="tab-inactive-users" />
            </TabList>
          </Box>
          <TabPanel value="tab-active-users">{renderUsersTable(activeUsers, true)}</TabPanel>
          <TabPanel value="tab-inactive-users">{renderUsersTable(inactiveUsers, false)}</TabPanel>
        </TabContext>
      </Box>
    </DefaultPageLayout>
  );
}
