import { MachineStateProvider } from '../production-monitoring';

export const LOAD_ASSETS = 'LOAD_ASSETS';
export const LOAD_ASSETS_SUCCESS = 'LOAD_ASSETS_SUCCESS';
export const LOAD_ASSETS_FAILURE = 'LOAD_ASSETS_FAILURE';

export const CREATE_ASSET = 'CREATE_ASSET';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_FAILURE = 'CREATE_ASSET_FAILURE';

export const UPDATE_ASSET = 'UPDATE_ASSET';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_FAILURE = 'UPDATE_ASSET_FAILURE';

export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE';

export interface LoadAllAction {
  type: typeof LOAD_ASSETS;
}
export interface LoadAllSuccessAction {
  type: typeof LOAD_ASSETS_SUCCESS;
  payload: Asset[];
}
export interface LoadAllFailureAction {
  type: typeof LOAD_ASSETS_FAILURE;
  payload: { error: unknown };
}

export interface CreateAssetSuccessAction {
  type: typeof CREATE_ASSET_SUCCESS;
  payload: Asset;
}
export interface CreateAssetFailureAction {
  type: typeof CREATE_ASSET_FAILURE;
  payload: { error: unknown };
}

export interface UpdateAssetSuccessAction {
  type: typeof UPDATE_ASSET_SUCCESS;
  payload: Asset;
}
export interface UpdateAssetFailureAction {
  type: typeof UPDATE_ASSET_FAILURE;
  payload: { error: unknown };
}

export interface DeleteAction {
  type: typeof DELETE_ASSET;
  payload: { assetId: number };
}
export interface DeleteSuccessAction {
  type: typeof DELETE_ASSET_SUCCESS;
  payload: { assetId: number };
}
export interface DeleteFailureAction {
  type: typeof DELETE_ASSET_FAILURE;
  payload: { error: unknown };
}

export type AssetsActionTypes =
  | LoadAllAction
  | LoadAllSuccessAction
  | LoadAllFailureAction
  | CreateAssetSuccessAction
  | CreateAssetFailureAction
  | UpdateAssetSuccessAction
  | UpdateAssetFailureAction
  | DeleteAction
  | DeleteSuccessAction
  | DeleteFailureAction;

export interface Asset {
  id: number;
  name: string;
  tags: string[];
  imageFileName: string | undefined;
  coordinates: google.maps.LatLngLiteral | undefined;
  nearestGatewayId: number | undefined;
  distanceFromGateway: number | undefined;
  positionAsOfDate: string | undefined;
  timeZoneId: string;
}

export interface AssetsState {
  readonly byId: { [id: number]: Asset };
  readonly productionMonitoringCalibrationState: ProductionMonitoringCalibrationState;
}

export enum MachineStateCalibrationStep {
  NotCalibrated,
  Requested,
  InProgress,
  Done,
}

export enum MachineCalibrationState {
  NotStarted,
  Started,
  Failed,
  Completed,
}

export interface ProductionMonitoringCalibrationState {
  readonly assetId: number | undefined;
  readonly beaconId: number | undefined;
  readonly errorCode: number | undefined;
  readonly errorMessage: string | undefined;
  readonly sensorDataPoints: number[];
}

export enum LocationSource {
  GPS = 0,
  Bluetooth = 1,
}

export type ProductionMonitoringConfiguration = {
  minimumProductionStopDurationInMinutes: number;
  machineStateProvider: MachineStateProvider | undefined;
  accelerometerConfigurationModel: AccelerometerConfigurationModel | undefined;
  mtConnectProviderConfiguration: MtConnectProviderConfiguration | undefined;
};

export type AccelerometerConfigurationModel = {
  beaconId: number;
};

export type MtConnectProviderConfiguration = {
  gatewayId: number;
  mtConnectIdentifier: string;
};

export type MachineStatisticsModel = {
  assetId: number;
  totalRunTimeSeconds: number | null;
  totalStopTimeSeconds: number | null;
  runTimeTrend: number | null;
  stopTimeTrend: number | null;
  runTimeByHour: TimeSerieData<number>[];
};

export type TimeSerieData<T> = {
  timeStamp: string;
  value: T;
};
