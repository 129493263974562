import { Box, CircularProgress, Typography } from '@mui/material';
import 'chart.js/auto';
import {
  ChartData, ChartOptions, ScatterDataPoint, Chart, Point, ArcElement
} from 'chart.js';
import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { defineMessages, useIntl } from 'react-intl';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { MachineStatisticsModel } from '../../../..';
import { TrendIndicator } from '../../../../../../components';
import { formatDuration } from '../../../../../../utils';
import useApiErrorHandler from '../../../../../../utils/ApiErrorHandler';
import { ProductionStopSummaryByReason } from '../../../../../production-monitoring';
import * as ProductionMonitoringApi from '../../../../../production-monitoring/api';
import * as Api from '../../../../api';

const m = defineMessages({
  runtime: { id: 'UtilizationStatistics.runtime', defaultMessage: 'Run Time' },
  productionStop: {
    id: 'UtilizationStatistics.productionStop',
    defaultMessage: 'Production Stops'
  },
  runTimeLabel: { id: 'UtilizationStatistics.runTimeLabel', defaultMessage: 'Run time' },
  noProductionStops: {
    id: 'UtilizationStatistics.noProductionStops',
    defaultMessage: 'No production stops to report!'
  },
  unspecifiedReason: {
    id: 'UtilizationStatistics.unspecifiedReason',
    defaultMessage: 'Not specified'
  },
  statisticsTrendHelperText: {
    id: 'UtilizationStatistics.statisticsTrendHelperText',
    defaultMessage: 'last 7 days vs previous 7 days'
  }
});

type BarChartDataType = ChartData<'bar', (number | Point | null)[], unknown>;

type PieChartDataType = ChartData<'pie', (number | Point | null)[], unknown>;

const barChartOptions: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    title: { display: false },
    legend: { display: false },
    tooltip: {
      callbacks: {
        label: (context) => {
          const value = context.dataset.data[context.dataIndex];
          return `${context.dataset.label} ${value != null ? formatDuration(value as number * 3600) : ''}`;
        }
      }
    }
  },
  scales: {
    y: {
      max: 1,
      ticks: {
        stepSize: 1
      }
    }
  }
};

const productionStopsChartOptions: ChartOptions<'pie'> = {
  responsive: true,
  layout: {
    padding: {
      x: 20,
      top: -100
    }
  },

  maintainAspectRatio: false,
  plugins: {
    legend: { display: true, position: 'left' },
    tooltip: {
      callbacks: {
        label: (context) => `${context.label} ${formatDuration(context.dataset.data[context.dataIndex])}`
      }
    }
  }
};

export interface UtilizationStatisticsProps {
  assetId: number;
}

export function UtilizationStatistics(props: UtilizationStatisticsProps) {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [statistics, setStatistics] = useState<MachineStatisticsModel | undefined>();
  const [productionStopSummaries, setProductionStopSummaries] = useState<
    ProductionStopSummaryByReason[]
  >([]);

  const [productionStopsChartData, setProductionStopsChartData] = useState<
    PieChartDataType | undefined
  >();

  const [utilizationByHourChartData, setUtilizationByHourChartData] = useState<
    BarChartDataType | undefined
  >();
  const errorHandler = useApiErrorHandler();

  const updateUtilizationChartData = useCallback(
    (stats: MachineStatisticsModel) => {
      setUtilizationByHourChartData({
        labels: [
          '0am',
          '1am',
          '2am',
          '3am',
          '4am',
          '5am',
          '6am',
          '7am',
          '8am',
          '9am',
          '10am',
          '11am',
          '12pm',
          '1pm',
          '2pm',
          '3pm',
          '4pm',
          '5pm',
          '6pm',
          '7pm',
          '8pm',
          '9pm',
          '10pm',
          '11pm'
        ],

        datasets: [
          {
            label: formatMessage(m.runTimeLabel),
            data: stats.runTimeByHour.map((p) => p.value / 3600),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)'
          }
        ]
      });
    },
    [formatMessage]
  );

  const getProductionStopReasonColor = (index: number, total: number) => d3.interpolateSpectral((index + 1) / Math.max(total + 1, 4));

  const updateProductionStopsData = useCallback(
    (productionStops: ProductionStopSummaryByReason[]) => {
      setProductionStopsChartData({
        labels: productionStops.map((p) => p.reason ?? formatMessage(m.unspecifiedReason)),
        datasets: [
          {
            label: formatMessage(m.runTimeLabel),
            data: productionStops.map((p) => p.durationTotalSeconds),
            borderColor: productionStops.map((p, index) => rgbStringToRgba(getProductionStopReasonColor(index, productionStops.length), 1)),
            backgroundColor: productionStops.map((p, index) => rgbStringToRgba(getProductionStopReasonColor(index, productionStops.length), 1))
          }
        ]
      });
    },
    [formatMessage]
  );

  const rgbStringToRgba = (rgbString: string, alpha: number) => rgbString.replace('rgb', 'rgba').replace(')', `, ${alpha})`);

  useEffect(() => {
    const loadStatistics = async () => {
      try {
        setIsLoading(true);
        const loadedStatistics = await Api.getMachineUtilizationStatistics(props.assetId);
        setStatistics(loadedStatistics);
        updateUtilizationChartData(loadedStatistics);

        const summaries = await ProductionMonitoringApi.loadProductionStopsSummaries(
          props.assetId,
          dayjs().startOf('day'),
          dayjs().endOf('day')
        );

        setProductionStopSummaries(summaries);
        updateProductionStopsData(summaries);
      } catch (e) {
        errorHandler(e);
      } finally {
        setIsLoading(false);
      }
    };

    loadStatistics();
  }, [errorHandler, props.assetId, updateProductionStopsData, updateUtilizationChartData]);
  return (
    <Box sx={{ minHeight: '200px' }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flex: 1,
          cursor: 'pointer'
        }}
        onClick={() => navigate(`/machines/view/${props.assetId}/tab-utilization`)}
      >
        {statistics && (
          <Box>
            <TrendIndicator
              title={formatMessage(m.runtime)}
              value={formatDuration(statistics.totalRunTimeSeconds ?? 0)}
              change={statistics.runTimeTrend == null ? null : Math.round(statistics.runTimeTrend)}
              data={null}
              isNegativeKpi={false}
              trendHelperText={formatMessage(m.statisticsTrendHelperText)}
            />
          </Box>
        )}
        {utilizationByHourChartData && (
          <Box sx={{ flexGrow: 1 }}>
            <Bar data={utilizationByHourChartData} options={barChartOptions} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex', flexWrap: 'wrap', flex: 1, cursor: 'pointer'
        }}
        onClick={() => navigate(`/machines/view/${props.assetId}/tab-production-stops`)}
      >
        {statistics && (
          <Box sx={{ marginTop: '20px' }}>
            <TrendIndicator
              title={formatMessage(m.productionStop)}
              value={formatDuration(statistics.totalStopTimeSeconds ?? 0)}
              change={
                statistics.stopTimeTrend == null ? null : Math.round(statistics.stopTimeTrend)
              }
              data={null}
              isNegativeKpi
              trendHelperText={formatMessage(m.statisticsTrendHelperText)}
            />
          </Box>
        )}

        <Box sx={{ flexGrow: 1, height: '200px', width: '100%' }}>
          {!productionStopsChartData || (productionStopSummaries.length === 0 && (
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <ThumbUpIcon
              color="primary"
              sx={{
                width: 'auto',
                height: 100,
                opacity: 0.5,
                zIndex: 0
              }}
            />
            <Typography variant="body1" zIndex={100}>
              {formatMessage(m.noProductionStops)}
            </Typography>
          </Box>
          ))}
          {productionStopsChartData && productionStopSummaries.length > 0 && (
            <Pie data={productionStopsChartData} options={productionStopsChartOptions} />
          )}
        </Box>
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
