import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'unorm';
import App from './App';
import { I18nProvider } from './components';
import { i18nMessages } from './configureI18n';
import configureStore from './configureStore';
import './index.css';
import './react_dates_overrides.css';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();
const store = configureStore(history, {} as any);

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '305212f9-a899-41f5-9f0d-230bf90faa97',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history }
    }
  }
});

appInsights.loadAppInsights();

ReactDOM.render(
  <Provider store={store}>
    <I18nProvider messages={i18nMessages} locale="en">
      <BrowserRouter>{React.createElement(withAITracking(reactPlugin, App))}</BrowserRouter>
    </I18nProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export { appInsights as AppInsights };
