import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { ApplicationState } from '../../store';
import { Beacon, Gateway } from './types';

export const getGatewayById = (
  state: ApplicationState,
  gatewayId: number | undefined
): Gateway | undefined => Object.values(state.devices.gatewaysById).find((p) => p.id === gatewayId);
export const getAllBeacons = (state: ApplicationState): Beacon[] => Object.values(state.devices.beaconsById);
export const getAllGateways = (state: ApplicationState): Gateway[] => Object.values(state.devices.gatewaysById);
export const getBeaconsOrdereredByIsInstalled = (state: ApplicationState): Beacon[] => Object.values(state.devices.beaconsById).sort(
  (a, b) => (a.installedOnAssetId === undefined ? 1 : 0) - (b.installedOnAssetId === undefined ? 1 : 0)
);
export const getUninstalledGateways = (state: ApplicationState): Gateway[] => Object.values(state.devices.gatewaysById).filter((x) => !x.fixedInstallationCoordinate);
export const getFixedInstalledGateways = (state: ApplicationState): Gateway[] => Object.values(state.devices.gatewaysById).filter((x) => x.fixedInstallationCoordinate);
export const getBeaconByAssetId = (state: ApplicationState, assetId: number): Beacon | undefined => Object.values(state.devices.beaconsById).find((p) => p.installedOnAssetId === assetId);

export type SemanticBatteryIcons =
  | 'battery zero'
  | 'battery one'
  | 'battery two'
  | 'battery three'
  | 'battery four';

export const getBatteryStatusIconName = (
  state: ApplicationState,
  batteryPercentage: number
): SemanticBatteryIcons => {
  if (batteryPercentage >= 80) {
    return 'battery four';
  }
  if (batteryPercentage >= 60) {
    return 'battery three';
  }
  if (batteryPercentage >= 40) {
    return 'battery two';
  }
  if (batteryPercentage >= 20) {
    return 'battery one';
  }

  return 'battery zero';
};

export const getBatteryStatusColor = (
  state: ApplicationState,
  batteryPercentage: number
): SemanticCOLORS => {
  if (batteryPercentage >= 60) {
    return 'green';
  }
  if (batteryPercentage >= 40) {
    return 'orange';
  }

  return 'red';
};
