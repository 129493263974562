import { BadRequestErrorModel, ModelState } from '.';

export class BadRequestError extends Error {
  public readonly modelState: ModelState;

  constructor(errors: ModelState, message?: string | undefined) {
    super(message);
    this.modelState = errors;
  }
}
