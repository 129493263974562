import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { useResponsiveQueries } from 'utils';
import {
  Backdrop,
  CircularProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ProductionStop, ProductionStopSummaryByReason } from '../../..';
import * as Api from '../../../api';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { EmptyContent } from '../../../../../components';
import SummaryView from './SummaryView';
import ProductionStopsTable from './ProductionStopsTable';

export type ProductionStopsReportProps = {
  assetId: number;
  date: string | undefined;
};

const m = defineMessages({
  title: { id: 'ProductionStopsReportProps.title', defaultMessage: 'Production Stops' },
  dateFilterToday: {
    id: 'ProductionStopsReportProps.dateFilterToday',
    defaultMessage: 'Today'
  },
  dateFilterLast7Days: {
    id: 'ProductionStopsReportProps.dateFilterLast7Days',
    defaultMessage: 'Last 7 days'
  },
  dateFilterCustom: { id: 'ProductionStopsReportProps.dateFilterCustom', defaultMessage: 'Custom' },
  dateFilterStartDate: {
    id: 'ProductionStopsReportProps.dateFilterStartDate',
    defaultMessage: 'Start Date'
  },
  dateFilterEndDate: {
    id: 'ProductionStopsReportProps.dateFilterEndDate',
    defaultMessage: 'End Date'
  },
  reportGranularityFilterSummary: {
    id: 'ProductionStopsReportProps.reportGranularityFilterSummary',
    defaultMessage: 'Summary View'
  },
  reportGranularityFilterDetails: {
    id: 'ProductionStopsReportProps.reportGranularityFilterDetails',
    defaultMessage: 'Detailed View'
  },
  noDataPlaceholderTitle: {
    id: 'ProductionStopsReportProps.noDataPlaceholderTitle',
    defaultMessage: 'No production stops for the selected time period.'
  }
});

export function ProductionStopsReport(props: ProductionStopsReportProps) {
  const { formatMessage } = useIntl();
  const handleError = useApiErrorHandler();
  const { isMobile, isPortrait } = useResponsiveQueries();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<'Today' | 'Last7Days' | 'Custom'>(
    'Today'
  );
  const [selectedReportGranularity, setSelectedReportGranularity] = useState<
    'Summary' | 'Detailed'
  >('Summary');
  const [dateRangePickerFocusedInput, setDateRangePickerFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >(null);
  const [productionStopSummaries, setProductionStopSummaries] = useState<
    ProductionStopSummaryByReason[]
  >([]);
  const [productionStops, setProductionStops] = useState<ProductionStop[]>([]);

  const [dateFilters, setDateFilters] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day')
  });

  useEffect(() => {
    async function loadReportData() {
      if (dateFilters.startDate == null || dateFilters.endDate == null) {
        return;
      }

      setIsLoading(true);
      await Api.loadProductionStopsSummaries(
        props.assetId,
        dateFilters.startDate,
        dateFilters.endDate
      )
        .then((summaries) => setProductionStopSummaries(summaries))
        .then(() => Api.loadProductionStops(props.assetId, dateFilters.startDate, dateFilters.endDate!))
        .then((stops) => setProductionStops(stops))
        .catch((e) => handleError(e))
        .finally(() => setIsLoading(false));
    }

    loadReportData();
  }, [props.assetId, dateFilters.startDate, dateFilters.endDate, handleError, formatMessage]);

  useEffect(() => {
    switch (selectedTimeFilter) {
      case 'Today':
        setDateFilters({ startDate: dayjs(), endDate: dayjs() });
        break;
      case 'Last7Days':
        setDateFilters({ startDate: dayjs().subtract(6, 'days'), endDate: dayjs() });
        break;
      default:
        break;
    }
  }, [selectedTimeFilter]);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '30px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '15px' }}>
            <ToggleButtonGroup
              exclusive
              value={selectedTimeFilter}
              onChange={(e, newValue) => setSelectedTimeFilter(newValue)}
            >
              <ToggleButton value="Today">{formatMessage(m.dateFilterToday)}</ToggleButton>
              <ToggleButton value="Last7Days">{formatMessage(m.dateFilterLast7Days)}</ToggleButton>
              <ToggleButton value="Custom">{formatMessage(m.dateFilterCustom)}</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div style={{ marginBottom: '15px' }}>
            {selectedTimeFilter === 'Custom' && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  minDate={moment(new Date(2020, 1, 1))}
                  value={dateFilters?.startDate}
                  label={formatMessage(m.dateFilterStartDate)}
                  maxDate={moment()}
                  key="startDate"
                  onChange={(date) => setDateFilters({ startDate: date as Dayjs, endDate: dateFilters.endDate })}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  minDate={moment(new Date(2020, 1, 1))}
                  value={dateFilters?.endDate}
                  key="endDate"
                  label={formatMessage(m.dateFilterEndDate)}
                  maxDate={moment()}
                  onChange={(date) => setDateFilters({ startDate: dateFilters.startDate, endDate: date as Dayjs })}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <ToggleButtonGroup
              exclusive
              value={selectedReportGranularity}
              onChange={(e, newValue) => setSelectedReportGranularity(newValue)}
            >
              <ToggleButton value="Summary">
                {formatMessage(m.reportGranularityFilterSummary)}
              </ToggleButton>
              <ToggleButton value="Detailed">
                {formatMessage(m.reportGranularityFilterDetails)}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
        {productionStopSummaries.length === 0 && !isLoading && (
          <Typography variant="body1">
            {formatMessage(m.noDataPlaceholderTitle)}
            {' '}
          </Typography>
        )}

        {productionStopSummaries.length > 0 && !isLoading && (
          <>
            {selectedReportGranularity === 'Summary' ? (
              <SummaryView productionStopSummaries={productionStopSummaries} />
            ) : (
              <ProductionStopsTable productionStops={productionStops} />
            )}
          </>
        )}
      </div>
    </>
  );
}
