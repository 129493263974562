import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { VictoryPie } from 'victory';
import moment from 'moment';
import _ from 'lodash';
import * as d3 from 'd3';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import {
  ChartData, ChartOptions, Point, ScatterDataPoint
} from 'chart.js';
import { ProductionStopSummaryByReason } from '../../..';

const m = defineMessages({
  title: { id: 'ProductionStopsReportProps.title', defaultMessage: 'Production Stops' },
  columnReason: { id: 'ProductionStopsReportProps.columnReason', defaultMessage: 'Reason' },
  columnTotalDuration: {
    id: 'ProductionStopsReportProps.columnTotalDuration',
    defaultMessage: 'Total duration'
  },
  columnNumberOfIncidents: {
    id: 'ProductionStopsReportProps.columnNumberOfIncidents',
    defaultMessage: 'Number of incidents'
  },
  columnAverageDuration: {
    id: 'ProductionStopsReportProps.columnAverageDuration',
    defaultMessage: 'Average duration'
  },
  chartValueForUnsetProductionStopReason: {
    id: 'ProductionStopsReportProps.chartValueForUnsetProductionStopReason',
    defaultMessage: 'Reason not specified'
  },
  noDataPlaceholderTitle: {
    id: 'ProductionStopsReportProps.noDataPlaceholderTitle',
    defaultMessage: 'No production stops for the selected time period.'
  },
  runTimeLabel: { id: 'ProductionStopsReportProps.runTimeLabel', defaultMessage: 'Run time' },
  unspecifiedReason: {
    id: 'ProductionStopsReportProps.unspecifiedReason',
    defaultMessage: 'Not specified'
  }
});

type PieChartDataType =ChartData<'pie', (number | Point | null)[], unknown>;

interface ISummaryViewProps {
  productionStopSummaries: ProductionStopSummaryByReason[];
}

const productionStopsChartOptions: ChartOptions<'pie'> = {
  responsive: true,
  layout: {
    padding: {
      top: -100,
      bottom: 100
    }
  },
  plugins: {
    legend: { display: false, position: 'left' }
  }
};

const rgbStringToRgba = (rgbString: string, alpha: number) => rgbString.replace('rgb', 'rgba').replace(')', `, ${alpha})`);

const SummaryView: React.FunctionComponent<ISummaryViewProps> = (props) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width:991px)' });
  const [chartData, setChartData] = useState<{ x: any; y: any }[]>([]);
  const [productionStopsChartData, setProductionStopsChartData] = useState<PieChartDataType>();

  useEffect(() => {
    const totalProductionStopsDurationInSeconds = _.sumBy(
      props.productionStopSummaries,
      (p) => p.durationTotalSeconds
    );

    setProductionStopsChartData({
      labels: props.productionStopSummaries.map(
        (p) => p.reason ?? formatMessage(m.unspecifiedReason)
      ),
      datasets: [
        {
          label: formatMessage(m.runTimeLabel),
          data: props.productionStopSummaries.map((p) => p.durationTotalSeconds),
          borderColor: props.productionStopSummaries.map((p, index) => rgbStringToRgba(getProductionStopReasonColor(index), 1)),
          backgroundColor: props.productionStopSummaries.map((p, index) => rgbStringToRgba(getProductionStopReasonColor(index), 1))
        }
      ]
    });

    setChartData(
      props.productionStopSummaries.map((p) => ({
        x: `${((p.durationTotalSeconds / totalProductionStopsDurationInSeconds) * 100).toFixed(
          1
        )}%`,
        y: p.durationTotalSeconds / totalProductionStopsDurationInSeconds
      }))
    );
  }, [formatMessage, props.productionStopSummaries]);

  const getProductionStopReasonColor = (index: number) => d3.interpolatePuBuGn((index + 1) / (props.productionStopSummaries.length + 1));

  return (
    <Box display="flex" height="100%" width="100%" flexDirection="row" flexWrap="wrap">
      <Box margin="10px" maxWidth="800px">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{formatMessage(m.columnReason)}</TableCell>
              <TableCell>{formatMessage(m.columnTotalDuration)}</TableCell>
              <TableCell>{formatMessage(m.columnNumberOfIncidents)}</TableCell>
              <TableCell>{formatMessage(m.columnAverageDuration)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.productionStopSummaries.map((p, index) => (
              <TableRow key={p.reason}>
                <TableCell>
                  <div
                    style={{
                      width: 30,
                      height: 15,
                      margin: 'auto',
                      backgroundColor: getProductionStopReasonColor(index)
                    }}
                  />
                </TableCell>
                <TableCell>
                  {p.reason ?? formatMessage(m.chartValueForUnsetProductionStopReason)}
                </TableCell>
                <TableCell>
                  {moment.duration(p.durationTotalSeconds, 'seconds').humanize({ ss: 1 })}
                </TableCell>
                <TableCell>{p.numberOfProductionStops}</TableCell>
                <TableCell>
                  {moment.duration(p.averageDurationTotalSeconds, 'seconds').humanize({ ss: 1 })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {productionStopsChartData && (
        <Box maxWidth="600px">
          <Pie data={productionStopsChartData} options={productionStopsChartOptions} />
        </Box>
      )}
    </Box>
  );
};

export default SummaryView;
