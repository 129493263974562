import { InstallGatewayModel } from './form-models';

export const LOAD_DEVICES = 'LOAD_DEVICES';
export const LOAD_DEVICES_SUCCESS = 'LOAD_DEVICES_SUCCESS';
export const LOAD_DEVICES_FAILURE = 'LOAD_DEVICES_FAILURE';

export const INSTALL_GATEWAY = 'INSTALL_GATEWAY';
export const INSTALL_GATEWAY_SUCCESS = 'INSTALL_GATEWAY_SUCCESS';
export const INSTALL_GATEWAY_FAILURE = 'INSTALL_GATEWAY_FAILURE';

export const UNINSTALL_GATEWAY = 'UNINSTALL_GATEWAY';
export const UNINSTALL_GATEWAY_SUCCESS = 'UNINSTALL_GATEWAY_SUCCESS';
export const UNINSTALL_GATEWAY_FAILURE = 'UNINSTALL_GATEWAY_FAILURE';

export const UPDATE_GATEWAY_DISPLAY_NAME = 'UPDATE_GATEWAY_DISPLAY_NAME';
export const UPDATE_GATEWAY_DISPLAY_NAME_SUCCESS = 'UPDATE_GATEWAY_DISPLAY_NAME_SUCCESS';
export const UPDATE_GATEWAY_DISPLAY_NAME_FAILURE = 'UPDATE_GATEWAY_DISPLAY_NAME_FAILURE';

export const GATEWAY_CONNECTION_STATE_CHANGED = 'GATEWAY_CONNECTION_STATE_CHANGED';
export const BEACONS_UPDATED = 'BEACONS_UPDATED';

export interface LoadAllAction {
  type: typeof LOAD_DEVICES;
}
export interface LoadAllSuccessAction {
  type: typeof LOAD_DEVICES_SUCCESS;
  payload: { gateways: Gateway[]; beacons: Beacon[] };
}
export interface LoadAllFailureAction {
  type: typeof LOAD_DEVICES_FAILURE;
  payload: { error: unknown };
}

export type InstallGatewayAction = { type: typeof INSTALL_GATEWAY; payload: InstallGatewayModel };
export type InstallGatewaySuccessAction = {
  type: typeof INSTALL_GATEWAY_SUCCESS;
  payload: { gateway: Gateway };
};
export type InstallGatewayFailureAction = {
  type: typeof INSTALL_GATEWAY_FAILURE;
  payload: { error: unknown };
};

export type UninstallGatewayAction = {
  type: typeof UNINSTALL_GATEWAY;
  payload: { deviceId: number };
};
export type UninstallGatewaySuccessAction = {
  type: typeof UNINSTALL_GATEWAY_SUCCESS;
  payload: { gateway: Gateway };
};
export type UninstallGatewayFailureAction = {
  type: typeof UNINSTALL_GATEWAY_FAILURE;
  payload: { error: unknown };
};

export interface UpdateGatewayDisplayNameAction {
  type: typeof UPDATE_GATEWAY_DISPLAY_NAME;
  payload: { gatewayId: number; displayName: string };
}
export interface UpdateGatewayDisplayNameSuccessAction {
  type: typeof UPDATE_GATEWAY_DISPLAY_NAME_SUCCESS;
  payload: { gatewayId: number; displayName: string };
}
export interface UpdateGatewayDisplayNameFailureAction {
  type: typeof UPDATE_GATEWAY_DISPLAY_NAME_FAILURE;
  payload: { gatewayId: number; error: unknown };
}

export interface GatewayConnectionStateChangedAction {
  type: typeof GATEWAY_CONNECTION_STATE_CHANGED;
  payload: { gatewayId: number; isConnected: boolean };
}
export interface BeaconsUpdatedAction {
  type: typeof BEACONS_UPDATED;
  payload: { beacons: Partial<Beacon>[] };
}

export type DevicesActionTypes =
  | LoadAllAction
  | LoadAllSuccessAction
  | LoadAllFailureAction
  | InstallGatewayAction
  | InstallGatewaySuccessAction
  | InstallGatewayFailureAction
  | UninstallGatewayAction
  | UninstallGatewaySuccessAction
  | UninstallGatewayFailureAction
  | UpdateGatewayDisplayNameAction
  | UpdateGatewayDisplayNameSuccessAction
  | UpdateGatewayDisplayNameFailureAction
  | GatewayConnectionStateChangedAction
  | BeaconsUpdatedAction;

export interface Gateway {
  id: number;
  deviceId: string;
  displayName: string;
  fixedInstallationCoordinate: google.maps.LatLngLiteral | undefined;
  installedOn: string | undefined;
  installedOnAssetId: number | undefined;
  isConnected: boolean;
  isLoading: boolean;
}

export interface Beacon {
  id: number;
  uniqueId: string;
  installedOn: string | undefined;
  installedOnAssetId: number | undefined;
  associatedGatewayId: number | undefined;
  batteryPercentage: number | undefined;
  batteryPercentageAsOfDate: string;
  coordinates: google.maps.LatLngLiteral | undefined;
  coordinatesAsOfDate: string | undefined;
  linkedGatewayId: number | undefined;
}

export interface DevicesState {
  readonly gatewaysById: { [id: number]: Gateway };
  readonly beaconsById: { [id: number]: Beacon };
}

export interface BeaconsBatteryUpdated {
  beaconsBatteryUpdates: BeaconBatteryUpdated[];
}

export interface BeaconBatteryUpdated {
  id: number;
  batteryPercentage: number;
  batteryPercentageAsOfDate: string;
}
