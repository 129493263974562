import { get, post, postWithoutResponse } from '../../utils/api';
import { ApplicationUser } from './types';

const endpoint = '/api/users';

export async function getUsers(): Promise<ApplicationUser[]> {
  return get<ApplicationUser[]>(endpoint);
}

export async function getRoles(): Promise<string[]> {
  return get<string[]>(`${endpoint}/roles`);
}

export async function updateUserRole(userEmail: string, role: string): Promise<void> {
  return postWithoutResponse(`${endpoint}/change-role`, { userEmail, role });
}

export async function deactivateUser(email: string): Promise<void> {
  return postWithoutResponse(`${endpoint}/deactivate`, { email });
}

export async function reactivateUser(email: string): Promise<void> {
  return postWithoutResponse(`${endpoint}/reactivate`, { email });
}

export async function createUser(email: string, role: string): Promise<void> {
  return postWithoutResponse(endpoint, { email, role });
}
