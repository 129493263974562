import {
  AuthenticationActionTypes,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_FAILURE,
  CONFIRM_EMAIL_SUCCESS,
  GET_SESSION,
  GET_SESSION_FAILURE,
  GET_SESSION_SUCCESS,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  RESET_REGISTRATION_STATE
} from './types';

export function login(email: string, password: string): AuthenticationActionTypes {
  return {
    type: LOGIN,
    payload: {
      email,
      password
    }
  };
}

export function loginSuccess(email: string): AuthenticationActionTypes {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      email
    }
  };
}

export function loginFailure(error: unknown): AuthenticationActionTypes {
  return {
    type: LOGIN_FAILURE,
    payload: {
      error
    }
  };
}

export function logout(): AuthenticationActionTypes {
  return {
    type: LOGOUT,
    payload: {}
  };
}

export function resetRegistrationState(): AuthenticationActionTypes {
  return {
    type: RESET_REGISTRATION_STATE,
    payload: {}
  };
}

export function register(email: string, password: string): AuthenticationActionTypes {
  return {
    type: REGISTER,
    payload: {
      email,
      password
    }
  };
}

export function registerSuccess(email: string): AuthenticationActionTypes {
  return {
    type: REGISTER_SUCCESS,
    payload: {
      email
    }
  };
}

export function registerFailure(error: unknown): AuthenticationActionTypes {
  return {
    type: REGISTER_FAILURE,
    payload: {
      error
    }
  };
}

export function confirmEmail(uid: string, token: string): AuthenticationActionTypes {
  return {
    type: CONFIRM_EMAIL,
    payload: {
      uid,
      token
    }
  };
}

export function confirmEmailSuccess(): AuthenticationActionTypes {
  return {
    type: CONFIRM_EMAIL_SUCCESS,
    payload: {}
  };
}

export function confirmEmailFailure(error: unknown): AuthenticationActionTypes {
  return {
    type: CONFIRM_EMAIL_FAILURE,
    payload: {
      error
    }
  };
}

export function getSession(): AuthenticationActionTypes {
  return {
    type: GET_SESSION,
    payload: {}
  };
}

export function getSessionSuccess(user: string, tenant: string): AuthenticationActionTypes {
  return {
    type: GET_SESSION_SUCCESS,
    payload: {
      user,
      tenant
    }
  };
}

export function getSessionFailure(error: unknown): AuthenticationActionTypes {
  return {
    type: GET_SESSION_FAILURE,
    payload: {
      error
    }
  };
}
