export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const REACTIVATE_USER_SUCCESS = 'REACTIVATE_USER_SUCCESS';

export interface LoadUsersSuccess {
  type: typeof LOAD_USERS_SUCCESS;
  payload: { users: ApplicationUser[] };
}

export interface LoadRolesSuccess {
  type: typeof LOAD_ROLES_SUCCESS;
  payload: { roles: string[] };
}

export interface CreateUserSuccessAction {
  type: typeof CREATE_USER_SUCCESS;
  payload: { user: ApplicationUser };
}

export interface ChangeUserRoleSuccessAction {
  type: typeof CHANGE_USER_ROLE_SUCCESS;
  payload: { email: string; role: string };
}

export interface DeactivateUserSuccessAction {
  type: typeof DEACTIVATE_USER_SUCCESS;
  payload: { email: string };
}

export interface ReactivateUserSuccessAction {
  type: typeof REACTIVATE_USER_SUCCESS;
  payload: { email: string };
}

export type UsersManagementActionTypes =
  | LoadUsersSuccess
  | LoadRolesSuccess
  | CreateUserSuccessAction
  | ChangeUserRoleSuccessAction
  | DeactivateUserSuccessAction
  | ReactivateUserSuccessAction;

export interface ApplicationUser {
  email: string;
  role: string;
  isDeactivated: boolean;
}

export interface UsersManagementState {
  usersByEmail: { [email: string]: ApplicationUser };
  roles: string[];
}
