import { LayoutActionTypes, HIDE_NAVIGATION_MENU, SHOW_NAVIGATION_MENU } from './types';

export function showNavigationMenu(): LayoutActionTypes {
  return {
    type: SHOW_NAVIGATION_MENU
  };
}

export function hideNavigationMenu(): LayoutActionTypes {
  return {
    type: HIDE_NAVIGATION_MENU
  };
}
