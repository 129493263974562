import {
  all, call, fork, put, take, takeLatest, race
} from 'redux-saga/effects';

import { push } from 'react-router-redux';
import { LOAD_ASSETS_SUCCESS, LOAD_ASSETS_FAILURE } from '../assets';
import * as AssetActions from '../assets/actions';
import { LOAD_DEVICES_SUCCESS, LOAD_DEVICES_FAILURE } from '../devices';
import * as DeviceActions from '../devices/actions';
import * as Actions from './actions';
import * as ProductionMonitoringActions from '../production-monitoring/actions';
import * as api from './api';
import {
  CONFIRM_EMAIL,
  ConfirmEmailAction,
  GET_SESSION,
  GetSessionAction,
  LOGIN,
  LoginAction,
  LOGOUT,
  LogoutAction,
  REGISTER,
  RegisterAction
} from './types';
import { AppInsights } from '../..';
import {
  LOAD_MACHINE_OVERVIEWS_SUCCESS,
  LOAD_MACHINE_OVERVIEWS_FAILURE
} from '../production-monitoring';

function* handleLogin(action: LoginAction) {
  try {
    yield call(api.login, action.payload.email, action.payload.password);
    yield put(Actions.getSession());
    yield put(Actions.loginSuccess(action.payload.email));
  } catch (e) {
    yield put(Actions.loginFailure(e));
  }
}

function* handleLogout(action: LogoutAction) {
  try {
    yield call(api.logout);
  } finally {
    window.location.replace('/');
  }
}

function* handleRegister(action: RegisterAction) {
  try {
    yield call(api.register, action.payload.email, action.payload.password);
    yield put(Actions.registerSuccess(action.payload.email));
  } catch (e) {
    yield put(Actions.registerFailure(e));
  }
}

function* handleConfirmEmail(action: ConfirmEmailAction) {
  try {
    yield call(api.confirmEmail, action.payload.uid, action.payload.token);
    yield put(Actions.confirmEmailSuccess());
  } catch (e) {
    yield put(Actions.confirmEmailFailure(e));
  }
}

function* handleGetSession(action: GetSessionAction) {
  try {
    const response = (yield call(api.getSession)) as api.SessionResponse;
    const sessionResponse = response as api.SessionResponse;

    yield all([
      put(AssetActions.loadAll()),
      put(DeviceActions.loadAll()),
      put(ProductionMonitoringActions.loadAll())
    ]);

    const { success, failure } = yield race({
      success: all([
        take(LOAD_ASSETS_SUCCESS),
        take(LOAD_DEVICES_SUCCESS),
        take(LOAD_MACHINE_OVERVIEWS_SUCCESS)
      ]),

      failure: race([
        take(LOAD_ASSETS_FAILURE),
        take(LOAD_DEVICES_FAILURE),
        take(LOAD_MACHINE_OVERVIEWS_FAILURE)
      ])
    });

    if (failure) {
      yield put(Actions.getSessionFailure(new Error('Failed to load application data.')));
      yield put(push('/errors/load-data-error'));
      return;
    }

    AppInsights.setAuthenticatedUserContext(sessionResponse.email);
    AppInsights.trackPageView();

    (window as any).Chatra('setIntegrationData', {
      /* main properties */
      name: '',
      email: response.email,
      phone: '',
      tenantName: response.tenantName
    });

    yield put(Actions.getSessionSuccess(sessionResponse.email, sessionResponse.tenantName));
  } catch (e) {
    yield put(Actions.getSessionFailure(e));
  }
}

function* watchLogin() {
  yield takeLatest(LOGIN, handleLogin);
}
function* watchLogout() {
  yield takeLatest(LOGOUT, handleLogout);
}
function* watchRegister() {
  yield takeLatest(REGISTER, handleRegister);
}
function* watchConfirmEmail() {
  yield takeLatest(CONFIRM_EMAIL, handleConfirmEmail);
}
function* watchGetSession() {
  yield takeLatest(GET_SESSION, handleGetSession);
}

function* authenticationSagas() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchRegister),
    fork(watchConfirmEmail),
    fork(watchGetSession)
  ]);
}

export { authenticationSagas };
