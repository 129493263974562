import { CssBaseline, ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as authenticationActions from './modules/authentication/actions';
import { journeyLightTheme } from './mui/theme';
import { RouteConfiguration } from './routes';

export const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticationActions.getSession());
  });

  return (
    <>
      <ThemeProvider theme={journeyLightTheme}>
        <CssBaseline enableColorScheme />
        <RouteConfiguration />
        <ToastContainer position="bottom-center" />
      </ThemeProvider>
    </>
  );
};

export default App;
