/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  Box, Divider
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import React, { FunctionComponent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Asset } from '../../..';
import { ApplicationState } from '../../../../../store';
import {
  getStatusColor,
  getStatusTextMessageId,
  MachineOverview
} from '../../../../production-monitoring';
import MachineMenu from './components/MachineMenu';
import { UtilizationStatistics } from './components/UtilizationStatistics';

interface OwnProps {
  asset: Asset;
}

interface StoreProps {
  machineState: MachineOverview | undefined;
}

export type AssetTileOverviewProps = StoreProps & OwnProps;

const m = defineMessages({
  title: { id: 'AssetTileOverview.title', defaultMessage: 'Machine Overview' },
  utilization: { id: 'AssetTileOverview.utilization', defaultMessage: "Today's utilization" },
  trend: { id: 'AssetTileOverview.trend', defaultMessage: 'Trend' },
  startMonitoring: { id: 'AssetTileOverview.configureSensor', defaultMessage: 'Start Monitoring' },
  runTime: { id: 'AssetTileOverview.totalRunningTime', defaultMessage: 'Run Time' },
  menuOptionViewDetails: {
    id: 'AssetTileOverview.menuOptionViewDetails',
    defaultMessage: 'View utilization'
  },
  menuOptionEditAsset: { id: 'AssetTileOverview.menuOptionEditAsset', defaultMessage: 'Configure' },
  connectivityIssue: {
    id: 'AssetTileOverview.connectivityIssue',
    defaultMessage: 'Connectivity Issue'
  },
  unknownStatusContactUsPart1: {
    id: 'ProductionMonitoring.unknownStatusContactUsPart1',
    defaultMessage: 'Contact us '
  },
  unknownStatusContactUsPart2: {
    id: 'ProductionMonitoring.unknownStatusContactUsPart1',
    defaultMessage: 'if problem persists.'
  }
});

const AssetTileOverview: FunctionComponent<AssetTileOverviewProps> = (
  props: AssetTileOverviewProps
) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <Card
        color={getStatusColor(props.machineState?.machineStatus)}
        sx={{
          maxWidth: 345,
          borderTop: 5,
          borderTopColor: getStatusColor(props.machineState?.machineStatus),
          m: 2
        }}
      >
        <CardHeader
          avatar={(
            <Avatar
              src={`/api/files/picture/${props.asset.imageFileName}`}
              onClick={() => navigate(`/machines/view/${props.asset.id}`)}
              sx={{ cursor: 'pointer' }}
              aria-label="avatar"
            >
              {props.asset.name.charAt(0).toUpperCase()}
            </Avatar>
          )}
          action={<MachineMenu assetId={props.asset.id} />}
          title={(
            <Box
              onClick={() => navigate(`/machines/view/${props.asset.id}`)}
              sx={{ cursor: 'pointer' }}
            >
              {props.asset.name}
            </Box>
          )}
          subheader={formatMessage(
            getStatusTextMessageId(
              props.machineState?.machineStatus,
              props.machineState?.unknownStatusReason
            )
          )}
        />
        <Divider />
        <CardContent>
          <UtilizationStatistics assetId={props.asset.id} />
        </CardContent>
        <Divider />
      </Card>
    </>
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StoreProps => ({
  machineState: state.machineStates.overviewByAssetId[ownProps.asset.id]
});

const connectedComponent = connect(mapStateToProps)(AssetTileOverview);

export { connectedComponent as AssetTileOverview };
