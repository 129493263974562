import { History } from 'history';
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

import { assetsReducer, assetsSagas, AssetsState } from './modules/assets';
import { authenticationSagas, AuthenticationState } from './modules/authentication';
import { authenticationReducer } from './modules/authentication/reducer';
import { devicesReducer, devicesSagas, DevicesState } from './modules/devices';
import { layoutReducer, layoutSagas, LayoutState } from './modules/layout';

import { UsersManagementState, usersManagementReducer } from './modules/users-management';
import {
  machineStateReducer,
  ProductionMonitoringState,
  productionMonitoringSagas
} from './modules/production-monitoring';

export interface ApplicationState {
  assets: AssetsState;
  authentication: AuthenticationState;
  machineStates: ProductionMonitoringState;
  devices: DevicesState;
  layout: LayoutState;

  usersManagement: UsersManagementState;
}

export const createRootReducer = (history: History) => combineReducers({
  assets: assetsReducer,
  authentication: authenticationReducer,
  machineStates: machineStateReducer,
  devices: devicesReducer,
  layout: layoutReducer,
  usersManagement: usersManagementReducer
});

export function* rootSaga() {
  yield all([
    fork(assetsSagas),
    fork(authenticationSagas),
    fork(devicesSagas),
    fork(layoutSagas),
    fork(productionMonitoringSagas)
  ]);
}
