import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import {
  Button, Input, MenuItem, Select, TableCell, TableRow, TextField
} from '@mui/material';
import * as Api from '../../api';
import useApiErrorHandler from '../../../../utils/ApiErrorHandler';
import { ApplicationState } from '../../../../store';
import * as UsersManagementActions from '../../actions';
import { ApplicationUser } from '../..';

const m = defineMessages({
  addUserButton: { id: 'ApplicationUserNewTableRow.saveButton', defaultMessage: 'Add User' },
  enterEmail: {
    id: 'ApplicationUserNewTableRow.enterEmail',
    defaultMessage: 'Enter new user email'
  },
  emailRequired: {
    id: 'ApplicationUserNewTableRow.emailRequired',
    defaultMessage: 'Enter a valid email address.'
  }
});

export function ApplicationUserNewTableRow() {
  const roles = useSelector<ApplicationState, string[]>((state) => state.usersManagement.roles);

  const handleError = useApiErrorHandler();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const {
    handleSubmit, control, setValue, reset, formState
  } = useForm<ApplicationUser>({
    mode: 'onBlur'
  });

  const createUser = async (data: ApplicationUser) => {
    try {
      await Api.createUser(data.email, data.role);
      dispatch(UsersManagementActions.createUserSuccess(data));
      reset();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <TableRow>
      <TableCell width={5}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: { value: true, message: formatMessage(m.emailRequired) },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: formatMessage(m.emailRequired)
            }
          }}
          render={(props) => (
            <TextField
              error={formState.errors.email?.message != null}
              helperText={formState.errors.email?.message}
              placeholder={formatMessage(m.enterEmail)}
              defaultValue={props.field.value}
              onBlur={(e) => {
                setValue('email', e.target.value);
                props.field.onBlur();
              }}
            />
          )}
        />
      </TableCell>
      <TableCell width={5}>
        <Controller
          name="role"
          control={control}
          defaultValue={roles[0]}
          render={(props) => (
            <Select
              defaultValue={props.field.value}
              disabled={formState.isSubmitting}
              onChange={(e) => setValue('role', e.target.value as string)}
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </TableCell>
      <TableCell width={3}>
        <div style={{ width: 130 }}>
          <Button onClick={handleSubmit(createUser)}>{formatMessage(m.addUserButton)}</Button>
        </div>
      </TableCell>
    </TableRow>
  );
}
